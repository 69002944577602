import {
    GET_NOTIFICATION,
    GET_NOTIFICATION_ERROR,
    GET_NOTIFICATION_SUCCESS,
    NotificationReduceType,
    POST_NOTIFICATION,
    POST_NOTIFICATION_ERROR,
} from '@components/notifications/redux/type/notification-type';

const initialState: NotificationReduceType = {
    notifications: [],
    loading: false,
    error: false,
};

const notificationReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_NOTIFICATION:
            return {
                ...state,
                loading: true,
            };
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notifications: action.value.data ? action.value.data : [],

                loading: false,
            };
        case GET_NOTIFICATION_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };

        case POST_NOTIFICATION:
            return {
                ...state,
                loading: false,
            };
        case POST_NOTIFICATION_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default notificationReducers;
