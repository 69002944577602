export const GET_BINNACLES = 'GET_BINNACLES';
export const GET_BINNACLES_SUCCESS = 'GET_BINNACLES_SUCCESS';
export const GET_BINNACLES_ERROR = 'GET_BINNACLES_ERROR';
export const GET_BINNACLES_BYID = 'GET_BINNACLES_BYID';
export const GET_BINNACLES_BYID_SUCCESS = 'GET_BINNACLES_BYID_SUCCESS';
export const GET_BINNACLES_BYID_ERROR = 'GET_BINNACLES_BYID_ERROR';
export const GET_BINNACLES_DETAILS = 'GET_BINNACLES_DETAILS';
export const GET_DOWNLOAD_BINNACLES = 'GET_DOWNLOAD_BINNACLES';
export const GET_DOWNLOAD_BINNACLES_SUCCESS = 'GET_DOWNLOAD_BINNACLES_SUCCESS';
export const GET_DOWNLOAD_BINNACLES_ERROR = 'GET_DOWNLOAD_BINNACLES_ERROR';
export const GET_DOWNLOAD_BINNACLES_BYID = 'GET_DOWNLOAD_BINNACLES_BYID';
export const GET_DOWNLOAD_BINNACLES_BYID_SUCCESS = 'GET_DOWNLOAD_BINNACLES_BYID_SUCCESS';
export const GET_DOWNLOAD_BINNACLES_BYID_ERROR = 'GET_DOWNLOAD_BINNACLES_BYID_ERROR';
