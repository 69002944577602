/** */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, Controller} from 'react-hook-form';
import PropTypes from 'prop-types';
import IconLoading from '@components/UI/atoms/icons/IconLoading.svg';
import {Box, Typography, IconButton, useTheme} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import {ErrorType} from '@components/modelorama/redux/actions/ModeloramaActions';
import {
    fetchPostZipMassive,
    setMassiveSuccess,
} from '@components/orderSimply/redux/actions/OrdersSimplyActions';
import {MAX_FILE_SIZE_MB} from '@/components/orderSimply/utils/fileUploadConfig';
import {useTranslation} from 'react-i18next';
import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';
import FileValidationsMassive from '@/components/orderSimply/components/FileValidationsMassive';
import FileValidationsMassiveDomain from '@/components/orderSimply/components/FileValidationsMassiveDomain';
import StatusMessage from '@/components/orderSimply/components/StatusMessage';
import {
    Nav,
    StyledModal,
    ButtonCancel,
    SaveButton,
    TypographyStyled400,
    TypographyStyled600,
    TypographyStyled500,
} from '@/components/faqs/StyledFAQ';
import {
    FileUploadContainer,
    StyledFileLabelButton,
    StyledInput,
    StyledLabelFileUpload,
} from '@/components/legalAgreements/StyledLegalAgreements';

const ModalFileUploadMassive = ({isModalOpen, closeModal, closeAndReload, loading = false}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [value, setValue] = useState('1');
    const [errorFile, setErrorFile] = useState('');
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState([]);
    const [showValidations, setShowValidations] = useState(false);
    const [validationErrorsDomain, setValidationErrorsDomain] = useState([]);
    const [ranValidations, setRanValidations] = useState(false);
    const [validationOk, setValidationOk] = useState(false);

    const token = useSelector((state) => state.logins.accessToken);
    const errors = useSelector((state) => state.orderssimply.errors);
    const domainErrors = useSelector((state) => state.orderssimply.domainErrors);
    const loadMassiveSucces = useSelector((state) => state.orderssimply.loadMassiveSucces);
    /**
     * @type {ErrorType}
     */
    const error = useSelector((state) => state.modelorama.error);

    const VALIDATION_NO_ERRORS = error === ErrorType.NONE;
    const VALIDATION_SUCCESS = ranValidations && VALIDATION_NO_ERRORS;
    const VALIDATION_SUCCESS_WITH_SAP_ERROR =
        ranValidations &&
        error === ErrorType.RULE &&
        Array.isArray(domainErrors) &&
        domainErrors.some((error) => error === 'sap.confirm_error');

    useEffect(() => {
        if (!loading && Array.isArray(domainErrors) && domainErrors.length > 0) {
            setValidationErrorsDomain(domainErrors);
            setShowValidations(true);
            setValue('2');
        } else {
            setValidationErrorsDomain([]);
        }
        setValidationOk(VALIDATION_SUCCESS || VALIDATION_SUCCESS_WITH_SAP_ERROR);
    }, [domainErrors, loading, VALIDATION_SUCCESS, VALIDATION_SUCCESS_WITH_SAP_ERROR]);

    useEffect(() => {
        if (file) setFileName(file.name);
    }, [file]);

    const schemaFormModal = yup.object({
        file: yup.mixed().required(t('modal_load_file.required_file')),
    });

    const {handleSubmit, control, reset} = useForm({
        resolver: yupResolver(schemaFormModal),
    });

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCloseAndReload = () => {
        resetData();
        closeAndReload();
    };

    const handleFileChange = (event, type) => {
        event.stopPropagation();
        const file = type === 'drop' ? event.dataTransfer.files[0] : event.target.files?.[0];
        if (!file) {
            return;
        }

        const zipFiles = [
            'application/zip',
            'application/octet-stream',
            'application/x-zip-compressed',
            'multipart/x-zip',
        ];
        if (!zipFiles.includes(file.type)) {
            setErrorFile('Archivo invalido porfavor sube un archivo Zip');
            return;
        }
        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            setErrorFile(
                `${t('modal_load_file.file_size_exceeds')} ${MAX_FILE_SIZE_MB} MB. ${t(
                    'modal_load_file.smaller_file'
                )}`
            );
            return;
        }
        setErrorFile('');

        setFile(file);
        setFileName(file.name);
    };

    const validateFile = async () => {
        try {
            dispatch(fetchPostZipMassive(token, file));

            setShowValidations(true);
            setRanValidations(true);
            setValue('2');
        } catch (error) {
            setValidationOk(false);
            setRanValidations(false);
        }
    };

    const onSubmit = async () => {
        //
    };

    const handleChangeDelete = () => {
        reset({file: null});
        setFileName('');
        setFile(null);
        setValidationErrorsDomain([]);
        setErrorFile('');
        setRanValidations(false);
        setValidationOk(false);
        setShowValidations(false);
        handleTabChange(null, '1');
    };

    const handleCancelButton = () => {
        if (value === '1') {
            handleCloseModal();
        } else if (value === '2') {
            setValidationErrorsDomain([]);
            handleTabChange(null, '1');
        }
    };

    const handleCloseModal = () => {
        resetData();
        closeModal();
    };

    const cleanup = () => {
        reset({file: null});
        setShowValidations(false);
        setValidationErrorsDomain([]);
        setValue('1');
    };

    const resetData = () => {
        cleanup();
        setErrorFile('');
        setFile(null);
        setFileName('');
        setRanValidations(false);
        setValidationOk(false);
        setShowValidations(false);
        dispatch(setMassiveSuccess(false));
    };

    const hasPositions = (err) => {
        return err.some((item) => item.hasPositions);
    };

    return (
        <StyledModal
            open={isModalOpen}
            onClose={() => closeModal}
            disableEscapeKeyDown={true}
            disableEnforceFocus={true}
        >
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: '865px',
                    height: '600px',
                    zIndex: '100',
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            {t('load_massive_invoices')}
                        </Typography>
                    </Nav>
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleTabChange}>
                                <Tab
                                    disabled={validationOk}
                                    label={t('upload_files')}
                                    value="1"
                                    sx={{textTransform: 'none'}}
                                />
                                <Tab
                                    disabled={VALIDATION_SUCCESS || fileName}
                                    label={t('validation')}
                                    value="2"
                                    sx={{textTransform: 'none'}}
                                />
                            </TabList>
                        </Box>
                        <TabPanel value="1" disabled={VALIDATION_SUCCESS}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Controller
                                    name="file"
                                    control={control}
                                    defaultValue={null}
                                    render={({field, fieldState}) => (
                                        <FileUploadContainer
                                            sx={{
                                                minHeight: '330px',
                                                margin: fieldState.error ? '0' : '10px 0',
                                                borderColor: fieldState.error ? 'red' : 'none',
                                            }}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                const file = e.dataTransfer.files[0];
                                                if (file) field.onChange(file);
                                                handleFileChange(e, 'drop');
                                            }}
                                            onClick={() =>
                                                document.getElementById('fileInput')?.click()
                                            }
                                        >
                                            <StyledInput
                                                id="fileInput"
                                                type="file"
                                                onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    if (file) field.onChange(file);
                                                    handleFileChange(e, 'change');
                                                }}
                                                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                                            />
                                            {field.value && !errorFile ? (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Typography variant="subtitle2">
                                                        {t('modal_load_file.last_file')}:{' '}
                                                        {field.value.name}
                                                    </Typography>
                                                    {!loading && (
                                                        <IconButton
                                                            disabled={validationOk}
                                                            aria-label="Eliminar"
                                                            onClick={(e) => {
                                                                field.onChange(null);
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            ) : (
                                                <Box>
                                                    <StyledFileLabelButton
                                                        variant="outlined"
                                                        as="span"
                                                    >
                                                        <img src={CloudIcon} />
                                                        <StyledLabelFileUpload>
                                                            Arrastra o sube el archivo zip aquí
                                                        </StyledLabelFileUpload>
                                                    </StyledFileLabelButton>
                                                    <Typography variant="caption" color="error">
                                                        {errorFile}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </FileUploadContainer>
                                    )}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            {!loadMassiveSucces ? (
                                <>
                                    <TypographyStyled400 sx={{fontSize: '16px'}}>
                                        Has cargado el siguiente archivo zip
                                    </TypographyStyled400>
                                    <FileUploadContainer
                                        sx={{
                                            height: '50px',
                                            cursor: 'default',
                                            marginTop: '15px',
                                        }}
                                    >
                                        {fileName && (
                                            <>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <FolderZipIcon style={{color: 'red'}} />
                                                    <Typography
                                                        sx={{
                                                            color: 'rgba(0, 0, 0, 0.38)',
                                                            fontSize: '16px',
                                                            marginLeft: '5px',
                                                        }}
                                                    >
                                                        {fileName}
                                                    </Typography>
                                                    {!validationOk && (
                                                        <IconButton
                                                            aria-label="Eliminar"
                                                            onClick={() =>
                                                                handleChangeDelete(fileName)
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </>
                                        )}
                                    </FileUploadContainer>
                                    {!loading && (
                                        <TypographyStyled600 sx={{marginTop: '20px'}}>
                                            {t('modal_load_file.sumary')}
                                        </TypographyStyled600>
                                    )}
                                    {loading ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: '240px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: '20px',
                                                gap: '20px',
                                            }}
                                        >
                                            <StatusMessage
                                                message="Cargando..."
                                                iconSrc={IconLoading}
                                                color="#3276c3"
                                            />
                                        </Box>
                                    ) : (
                                        <>
                                            {validationErrorsDomain.length > 0 && (
                                                <>
                                                    <FileUploadContainer
                                                        sx={{
                                                            justifyContent: 'flex-start',
                                                            height: '40px',
                                                            marginTop: '10px',
                                                            borderBottomStyle: 'none',
                                                            borderBottomLeftRadius: '0',
                                                            borderBottomRightRadius: '0',
                                                        }}
                                                    >
                                                        <TypographyStyled500
                                                            sx={{marginLeft: '30px'}}
                                                        >
                                                            {t('modal_load_file.element')}
                                                        </TypographyStyled500>
                                                        <TypographyStyled500
                                                            sx={{marginLeft: '110px'}}
                                                        >
                                                            {t('state')}
                                                        </TypographyStyled500>
                                                        <TypographyStyled500
                                                            sx={{marginLeft: '50px'}}
                                                        >
                                                            {t('modal_load_file.comments')}
                                                        </TypographyStyled500>
                                                    </FileUploadContainer>
                                                    <FileUploadContainer
                                                        sx={{
                                                            borderTopLeftRadius: '0',
                                                            borderTopRightRadius: '0',
                                                            display: 'block',
                                                            height: '170px',
                                                            justifyContent: 'flex-start',
                                                            overflow: 'hidden',
                                                            overflowY: 'scroll',
                                                        }}
                                                    >
                                                        <FileValidationsMassiveDomain
                                                            errors={validationErrorsDomain}
                                                            loading={loading}
                                                            showValidations={showValidations}
                                                        />
                                                    </FileUploadContainer>
                                                </>
                                            )}
                                            {errors && errors.length > 0 && (
                                                <>
                                                    <FileUploadContainer
                                                        sx={{
                                                            justifyContent: 'flex-start',
                                                            height: '40px',
                                                            marginTop: '10px',
                                                            borderBottomStyle: 'none',
                                                            borderBottomLeftRadius: '0',
                                                            borderBottomRightRadius: '0',
                                                        }}
                                                    >
                                                        <TypographyStyled500
                                                            sx={{
                                                                marginLeft: '10px',
                                                                minWidth: '180px',
                                                            }}
                                                        >
                                                            Orden de compra
                                                        </TypographyStyled500>
                                                        <TypographyStyled500
                                                            sx={{
                                                                marginLeft: '10px',
                                                                minWidth: '180px',
                                                            }}
                                                        >
                                                            {hasPositions(errors)
                                                                ? 'Posiciones'
                                                                : 'Hojas de entrada'}
                                                        </TypographyStyled500>
                                                        <TypographyStyled500
                                                            sx={{
                                                                marginLeft: '10px',
                                                                minWidth: '180px',
                                                            }}
                                                        >
                                                            Comentarios
                                                        </TypographyStyled500>
                                                    </FileUploadContainer>
                                                    <FileUploadContainer
                                                        sx={{
                                                            borderTopLeftRadius: '0',
                                                            borderTopRightRadius: '0',
                                                            display: 'block',
                                                            height: '180px',
                                                            justifyContent: 'flex-start',
                                                            overflow: 'hidden',
                                                            overflowY: 'scroll',
                                                        }}
                                                    >
                                                        <FileValidationsMassive
                                                            errors={errors}
                                                            loading={loading}
                                                            showValidations={showValidations}
                                                        />
                                                    </FileUploadContainer>
                                                </>
                                            )}{' '}
                                        </>
                                    )}
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '340px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '20px',
                                        gap: '20px',
                                    }}
                                >
                                    <svg
                                        width="60"
                                        height="60"
                                        viewBox="0 0 120 120"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx="57" cy="57" r="57" fill="#4BBC68" />
                                        <path
                                            d="M26 57L44.8675 75.8675C45.493 76.493 46.507 76.493 47.1325 75.8675L87 36"
                                            stroke="white"
                                            strokeWidth="5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <span> La información se proceso con exito!</span>
                                </Box>
                            )}
                        </TabPanel>
                    </TabContext>
                    <Box
                        sx={{
                            paddingX: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{display: 'flex', justifySelf: 'flex-start'}}>
                            {ranValidations &&
                                validationErrorsDomain.length > 1 &&
                                value === '2' && (
                                    <StatusMessage
                                        message={`${t('modal_load_file.total_errors')}: ${
                                            validationErrorsDomain.length
                                        }`}
                                        color="#f50057"
                                    />
                                )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                height: '60px',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '60px',
                                    alignItems: 'center',
                                    justifySelf: 'flex-start',
                                }}
                            >
                                <a href="./assets/docs/3WM.xlsx">Plantilla para carga masiva 3WM</a>
                                <a href="./assets/docs/2WM.xlsx">
                                    Plantilla para carga masiva 2WM{' '}
                                </a>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {!loadMassiveSucces && (
                                    <ButtonCancel
                                        sx={{
                                            color: validationOk ? '#D3D3D3' : null,
                                            border: `1.5px solid ${
                                                validationOk ? '#D3D3D3' : null
                                            }`,
                                        }}
                                        disabled={validationOk}
                                        onClick={() => handleCancelButton()}
                                    >
                                        {value === '1'
                                            ? t('cancel').toUpperCase()
                                            : t('back').toUpperCase()}
                                    </ButtonCancel>
                                )}

                                {validationOk ||
                                    (loadMassiveSucces && (
                                        <SaveButton
                                            sx={{padding: '10px'}}
                                            onClick={() => handleCloseAndReload()}
                                        >
                                            {t('close').toUpperCase()}
                                        </SaveButton>
                                    ))}
                                {!loadMassiveSucces && (
                                    <SaveButton
                                        sx={{
                                            padding: '10px',
                                            display: `${validationOk ? 'none' : 'block'}`,
                                        }}
                                        disabled={!file || loading}
                                        onClick={() => validateFile()}
                                    >
                                        {t('send').toUpperCase()}
                                    </SaveButton>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </form>
            </Box>
        </StyledModal>
    );
};

ModalFileUploadMassive.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    closeAndReload: PropTypes.func,
    loading: PropTypes.bool,
    type: PropTypes.string,
    setLoadOrder: PropTypes.func,
};

export default React.memo(ModalFileUploadMassive);
