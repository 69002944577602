import React from 'react';
import {Box} from '@mui/system';
import StatusMessage from '@/components/orderSimply/components/StatusMessage';
import ValidationError from '@/components/orderSimply/components/ValidationError';
import {getCfdiValidation} from '@/components/orderSimply/utils/cfdiValidations';
import IconEstatusOk from '@components/UI/atoms/icons/IconEstatusOk.svg';
import IconEstatusProblem from '@components/UI/atoms/icons/IconEstatusProblem.svg';
import IconLoading from '@components/UI/atoms/icons/IconLoading.svg';
import IconAlertSlow from '@components/UI/atoms/icons/IconAlertSlow.svg';

interface FileValidationsProps {
    errors: string[];
    loading: boolean;
    showValidations: boolean;
    successMessage: string;
    sx?: object;
}

const FileValidations: React.FC<FileValidationsProps> = ({
    errors,
    loading,
    showValidations,
    successMessage,
    sx,
}) => {
    return (
        <Box
            sx={{
                padding: errors.length > 1 ? 0 : '10px',
                paddingTop: errors.length > 0 ? '10px' : '20px',
                ...sx,
            }}
        >
            {loading ? (
                <StatusMessage message="Cargando..." iconSrc={IconLoading} color="#3276c3" />
            ) : (
                <>
                    {showValidations && errors.length === 0 && (
                        <StatusMessage
                            message={successMessage}
                            iconSrc={IconEstatusOk}
                            color="#18BA81"
                        />
                    )}
                    {showValidations &&
                        errors?.length > 0 &&
                        errors?.map((error) => {
                            const errorInfo = getCfdiValidation(error);
                            return (
                                <React.Fragment key={errorInfo.name}>
                                    {errors.length === 1 && !errorInfo.showInList ? (
                                        <StatusMessage
                                            message="Error al procesar la petición, intenta nuevamente"
                                            iconSrc={IconEstatusProblem}
                                            color="#000000"
                                        />
                                    ) : null}

                                    {errors.length === 1 && errorInfo.name === 'PROCESS_PENDING' ? (
                                        <StatusMessage
                                            message={errorInfo.description}
                                            iconSrc={IconAlertSlow}
                                            color="#000000"
                                        />
                                    ) : (
                                        <ValidationError errorInfo={errorInfo} />
                                    )}
                                </React.Fragment>
                            );
                        })}
                </>
            )}
        </Box>
    );
};

export default FileValidations;
