export const routers = {
    login: '/login',
    home: '/home',
    forgotpassword: '/forgotpassword',
    resetpassword: '/resetpassword/:token',
    enterprise: '/enterprise',
    urls: '/urls',
    manuals: '/manuals',
    faqs: '/faqs',
    binnacles: '/binnacles',
    legalagreements: '/legalagreements',
    legalagreementsAdmin: '/legalagreementsAdmin',
    taxrequirements: '/taxrequirements',
    csf: '/csf',
    modelorama: '/modelorama',
    candidates: '/candidates',
};
