import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import logoAbi from '@login/images/logoAbi.svg';
import logoYaydoo from '@login/images/logoYaydoo.svg';
import background from '@login/images/laptop_abi.svg';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useSearchParams} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import {Controller} from 'react-hook-form';
import {ColorButton} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import {fetchPutUpdatePassword} from '@login/redux/actions/LoginActions';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@config/store';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const theme = useTheme();
    const token = searchParams.get('token');
    const dispatch: AppDispatch = useDispatch();
    const atLeastOneNum = /[0-9]/;
    const atLeastOneSpecial = /[\W|_]/;
    const atLeastOneLowLetter = /[a-z]/;
    const atLeastOneCapLetter = /[A-Z]/;

    interface IFormRecoveryPassword {
        password: string;
        passwordConfirmation: string;
    }
    const schemaFormModal = yup.object({
        password: yup
            .string()
            .min(8, 'Longitud mínima de 8 caracteres')
            .max(30, 'Longitud máxima de 30 caracteres')
            .required('Contraseña requerida')
            .matches(atLeastOneNum, 'Incluye al menos un número')
            .matches(atLeastOneLowLetter, 'Incluye al menos una minúscula')
            .matches(atLeastOneCapLetter, 'Incluye al menos una mayúscula')
            .matches(atLeastOneSpecial, 'Incluye al menos un caracter especial'),
        passwordConfirmation: yup
            .string()
            .oneOf([yup.ref('password'), undefined], 'Las contraseñas deben ser iguales')
            .required('Confirma tu contraseña'),
    });

    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<IFormRecoveryPassword>({resolver: yupResolver(schemaFormModal)});
    const onSubmit = (data: IFormRecoveryPassword) => {
        const json = {
            password: data.password,
        };
        dispatch(fetchPutUpdatePassword(json, token));
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <Box
                sx={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    height: 'calc(100vh - 70px)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '51vw',
                        background: theme.palette.primary.main,
                        paddingTop: '80px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: '#FFFFFF',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '23px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 500,
                        }}
                    >
                        Bienvenido al
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '40px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                        }}
                    >
                        Portal de proveedores
                    </Typography>
                    <img src={background} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20vh 0',
                        width: '49vw',
                    }}
                >
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <img width="184px" src={logoAbi} />
                        <Typography
                            sx={{
                                fontSize: '23px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                margin: '48px 0 10px 0',
                            }}
                        >
                            Nueva contraseña{' '}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 400,
                                marginBottom: ' 29px',
                            }}
                        >
                            Ingresa una nueva contraseña.{' '}
                        </Typography>
                        <Controller
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label="Ingresa contraseña"
                                    size="small"
                                    type="password"
                                />
                            )}
                            name="password"
                            control={control}
                        />
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 300,
                                color: 'red',
                                marginBottom: '14px',
                            }}
                        >
                            {errors?.password?.message}
                        </Typography>
                        <Controller
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label="Confirma contraseña"
                                    size="small"
                                    type="password"
                                />
                            )}
                            name="passwordConfirmation"
                            control={control}
                        />
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 300,
                                color: 'red',
                                marginBottom: '14px',
                            }}
                        >
                            {errors?.passwordConfirmation?.message}
                        </Typography>
                        <ColorButton>Guardar</ColorButton>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    minHeight: '70px',
                    background: '#000000',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontFamily: 'Helvetica',
                        fontWeight: 700,
                        color: '#FFF',
                        marginRight: '15px',
                    }}
                >
                    powered by{' '}
                </Typography>
                <img src={logoYaydoo} />
            </Box>
        </Box>
    );
};

export default ResetPassword;
