import {
    ColorButtonMobile,
    ColorButtonModal,
    OrderButton,
} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import {FeatureCodeEnum, MerchandiseReceptionEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import {ACLObj} from '@components/auth/guard/AclGuard';
import {
    ButtonAlternativeMobile,
    ButtonAlternativeModal,
} from '@components/UI/atoms/buttons/ButtonAlternative';
import Loader from '@components/UI/molecules/loading/Loading';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {useFormatNumber} from '@hooks/useFormatNumber';
import {useIsMobile} from '@hooks/useIsMobile';
import {Box, InputAdornment, Modal, styled, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import iconAlertWarning from '@order/images/iconAlertWarning.svg';
import iconEdit from '@order/images/iconEdit.svg';
import iconExcedent from '@order/images/iconExcedent.svg';
import iconOrderRadio from '@order/images/iconOrderRadio.svg';
import iconOrderSuccess from '@order/images/iconOrderSuccess.svg';
import iconOrderWarning from '@order/images/iconOrderWarning.svg';
import logoAbi from '@order/images/logoAbi.svg';
import NotFound from '@order/NotFound';
import OrderDelivered from '@order/orderDelivered';
import {
    fetchGetOrder,
    fetchPostOrder,
    getOrderIncompleteSuccess,
} from '@order/redux/actions/OrderActions';
import {es, enUS} from 'date-fns/locale';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import * as yup from 'yup';
import {DateTime} from 'luxon';
const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 60px); /* Ancho de la pantalla menos 30px a cada lado */
    height: 100vh; /* Alto de la pantalla */
    margin-left: 30px; /* Margen izquierdo de 30px */
    margin-right: 30px; /* Margen derecho de 30px */
`;
const StyledController = styled(Controller)`
    minwidth: 70%;
`;

const StyledLocalizationProvider = styled(LocalizationProvider)`
    width: 60%;
`;

const StyledControllerWrapper = styled('div')`
    height: 60px;
    width: 60%;
`;
const StyledLocalizationProviders = styled(LocalizationProvider)`
    width: 38%;
`;

const StyledControllerWrappers = styled('div')`
    height: 60px;
    width: 38%;
`;

const Order: React.FC & {acl?: ACLObj} = () => {
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const {orderId} = useParams();
    const [modalOpen, setmodalOpen] = useState<boolean>(false);
    const [method, setMethod] = useState<string>('');
    const isMobile = useIsMobile();
    const formatNumber = useFormatNumber();
    const dispatch: AppDispatch = useDispatch();
    const {notAllowed} = useCheckAbilities();
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const closeModal = () => {
        setmodalOpen(false);
    };
    const marketplaceBatchSchema = yup
        .array()
        .min(1, 'Debe haber al menos un lote')
        .of(
            yup.object().shape({
                batch: yup.string().when('received', (received, schema) => {
                    if (received[0] > 0) {
                        return schema
                            .required('Lote requerido')
                            .min(4, 'Longitud mínima de 4 caracteres')
                            .max(10, 'Longitud máxima de 10 caracteres')
                            .test(
                                'is-number',
                                'Por favor, introduce solo números en el campo',
                                (value) => {
                                    if (value === undefined) return false; // Rechaza si batch es undefined
                                    const parsedValue = parseFloat(value);
                                    return !isNaN(parsedValue); // Retorna true si el valor es un número
                                }
                            );
                    } else {
                        return schema;
                    }
                }),
                dateExpire: yup.date().when('received', (received, schema) => {
                    if (parseFloat(received[0]) > 0) {
                        return schema.required('Fecha requerida');
                    } else {
                        return schema
                            .nullable()
                            .transform((curr, orig) => (orig === '' ? null : curr));
                    }
                }),
                manufacturingDate: yup.date().when('received', (received, schema) => {
                    if (parseFloat(received[0]) > 0) {
                        return schema.required('Fecha requerida');
                    } else {
                        return schema
                            .nullable()
                            .transform((curr, orig) => (orig === '' ? null : curr));
                    }
                }),

                received: yup
                    .string()
                    .nullable()
                    .required('Total/Producto requerido')
                    .matches(/^\d+(\.\d{1,2})?$/, 'Por favor, introduce solo números en el campo')
                    .test('is-number', 'Por favor, introduce solo números en el campo', (value) => {
                        if (value === undefined) return false; // Rechaza si received es undefined
                        const parsedValue = parseFloat(value);
                        return !isNaN(parsedValue); // Retorna true si el valor es un número
                    }),
            })
        )
        .required('Debe haber al menos un lote');

    const coreReceivedSchema = yup
        .string()
        .nullable()
        .required('Total requerido')
        .matches(/^\d+(\.\d{1,2})?$/, 'Por favor, introduce solo número en el campo')
        .test('is-number', 'Por favor, introduce solo números en el campo', (value) => {
            if (value === undefined) return false; // Rechaza si received es undefined
            const parsedValue = parseFloat(value);
            return !isNaN(parsedValue); // Retorna true si el valor es un número
        });

    // Define el esquema principal
    const schema = yup.object().shape({
        lineItems: yup.array().of(
            yup.object().shape({
                batches: yup.lazy((value) =>
                    order.buyer.company.companyGroup === 'MARKETPLACE'
                        ? marketplaceBatchSchema
                        : yup.array().nullable()
                ),
                received: yup.lazy((value) =>
                    order.buyer.company.companyGroup === 'CORE'
                        ? coreReceivedSchema
                        : yup.string().nullable()
                ),
            })
        ),
        billNumber: yup
            .string()
            .required('Factura requerida')
            .max(12, 'Longitud máxima de 12 caracteres')
            .matches(/^\d+$/, 'Por favor, introduce solo números en el campo')
            .test('is-number', 'Por favor, introduce solo números en el campo factura', (value) => {
                if (!value) return true; // Si el campo está vacío, la validación pasa
                const parsedValue = parseFloat(value);
                return !isNaN(parsedValue); // Retorna true si el valor es un número
            }),
    });

    const {
        control,
        watch,
        handleSubmit,
        setValue,
        unregister,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange', // Validar cuando el campo pierde el foco
    });
    const [newInputs, setNewInputs] = useState<number[]>([]);
    const handleAddInputs = (lineItemId: number) => {
        const newBatchIndex = newInputs[lineItemId] ? newInputs[lineItemId] + 1 : 1;

        const updatedInputs = [...newInputs];

        if (updatedInputs[lineItemId]) {
            updatedInputs[lineItemId] = newBatchIndex;
        } else {
            updatedInputs[lineItemId] = 1;
        }

        setNewInputs(updatedInputs);
    };

    const handleCancelInputs = (lineItemId: number, batchIndex: number) => {
        const updatedInputs = [...newInputs];

        updatedInputs[lineItemId] -= 1;

        if (updatedInputs[lineItemId] <= 0) {
            updatedInputs.splice(lineItemId, 1);
        }

        setNewInputs(updatedInputs);

        unregister(`lineItems[${lineItemId}].batches[${batchIndex}]`);
    };

    const handleAddFirstInputBlock = (item: any, itemId: number) => {
        if (watchForm.lineItems[itemId].isOpenBatch === false) {
            setValue(`lineItems[${itemId}].isOpenBatch`, true);
            if (typeof newInputs[itemId] === 'undefined') {
                const updatedInputs = [...newInputs];
                updatedInputs[itemId] = item.batches && item.batches.length > 0 ? 0 : 1;
                setNewInputs(updatedInputs);
            }
        } else {
            setValue(`lineItems[${itemId}].isOpenBatch`, false);
        }
    };

    const renderInputBlocks = (item: any, lineItemId: number, inputCount: number) => {
        const inputBlocks = [];

        const batchLength = item.batches && item.batches.length > 0 ? item.batches.length : 0;

        for (let i = 0; i < inputCount + batchLength; i++) {
            const inputKey = `${lineItemId}-${i}`;
            inputBlocks.push(
                <Box
                    key={inputKey}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderTop: i > 0 ? '2px solid #E5B611' : 'none',
                        paddingTop: i > 0 ? '20px' : '0',
                        marginTop: i > 0 ? '20px' : '0',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {/* Lote */}
                        <StyledController
                            name={`lineItems[${lineItemId}].batches[${i}].batch`}
                            control={control}
                            defaultValue={item.batches[i]?.batch ? item.batches[i]?.batch : ''}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id={`batch-${lineItemId}-${i}`}
                                    label="Lote"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{
                                        width: '60%',
                                        '& .MuiInputBase-root': {
                                            height: '56px',
                                            fontSize: isMobile ? '28px' : '19px',
                                        },
                                    }}
                                />
                            )}
                        />
                        <StyledLocalizationProviders dateAdapter={AdapterDateFns} locale={lang}>
                            <StyledControllerWrappers>
                                <Controller
                                    name={`lineItems[${lineItemId}].batches[${i}].dateExpire`}
                                    control={control}
                                    defaultValue={
                                        item.batches[i]?.dateExpire
                                            ? item.batches[i]?.dateExpire
                                            : ''
                                    }
                                    render={({field: {onChange, value}}) => (
                                        <DatePicker
                                            label="Fecha caducidad"
                                            value={value}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: '56px',
                                                            fontSize: isMobile ? '28px' : '19px',
                                                        },
                                                    }}
                                                />
                                            )}
                                            minDate={new Date()}
                                        />
                                    )}
                                />
                            </StyledControllerWrappers>
                        </StyledLocalizationProviders>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingTop: '10px',
                        }}
                    >
                        <StyledLocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <StyledControllerWrapper>
                                <Controller
                                    name={`lineItems[${lineItemId}].batches[${i}].manufacturingDate`}
                                    control={control}
                                    defaultValue={
                                        item.batches[i]?.manufacturingDate
                                            ? item.batches[i]?.manufacturingDate
                                            : ''
                                    }
                                    render={({field: {onChange, value}}) => (
                                        <DatePicker
                                            label="Fecha fabricación "
                                            value={value}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: '56px',
                                                            fontSize: isMobile ? '28px' : '19px',
                                                        },
                                                    }}
                                                />
                                            )}
                                            maxDate={new Date()}
                                        />
                                    )}
                                />
                            </StyledControllerWrapper>
                        </StyledLocalizationProvider>
                        {/* Total/lote */}
                        <Controller
                            name={`lineItems[${lineItemId}].batches[${i}].received`}
                            control={control}
                            defaultValue={item.batches[i]?.received ? item.batches[i]?.received : 0}
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id={`received-${lineItemId}-${i}`}
                                    label="Total/producto"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    InputProps={{
                                        endAdornment: <span style={{marginLeft: '4px'}}>{}</span>,
                                    }}
                                    sx={{
                                        width: '38%',
                                        '& .MuiInputBase-root': {
                                            height: '56px',
                                            fontSize: isMobile ? '28px' : '19px',
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>
                    {i + 1 === inputCount + batchLength && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Typography
                                onClick={() => handleCancelInputs(lineItemId, i)}
                                sx={{
                                    fontSize: isMobile ? '36px' : '20px',
                                    fontFamily: 'Poppins',
                                    fontWeight: isMobile ? 500 : 400,
                                    color: 'red',
                                    cursor: 'pointer',
                                    width: 'fit-content',
                                }}
                            >
                                X Eliminar
                            </Typography>
                        </Box>
                    )}
                </Box>
            );
        }

        return inputBlocks;
    };

    const watchForm = watch();

    const order = useSelector((state: RootState) => state.order.order);
    const error = useSelector((state: RootState) => state.order.error);
    const loading = useSelector((state: RootState) => state.order.loading);
    const loadingpost = useSelector((state: RootState) => state.order.loadingpost);
    const [json, setJson] = useState<any>(null);
    const [hasMismatchedSum, setHasMismatchedSum] = useState<boolean>(false);
    const [orderData, setOrderData] = useState<any>(null);

    const calculateReceivedSum = (lineItemId: number) => {
        const typeSum = order.buyer.company.companyGroup;
        if (typeSum === 'MARKETPLACE') {
            const lineItemBatches = watch(`lineItems[${lineItemId}].batches`);
            if (!lineItemBatches || lineItemBatches.length === 0) return '';
            const sumLineItemBatches = lineItemBatches
                .filter((item: any) => item !== undefined && item.received !== undefined)
                .reduce((acc: number, batch: {received?: string}) => {
                    const received = batch.received === undefined ? '' : batch.received; // Usa cadena vacía si received es undefined
                    const receivedNumber = received === '0' ? 0 : parseFloat(received); // Trata '0' como el número 0
                    return acc + receivedNumber;
                }, 0);
            if (isNaN(sumLineItemBatches)) {
                return '';
            } else {
                return sumLineItemBatches;
            }
        } else {
            const sumLineItemBatches = watch(`lineItems[${lineItemId}].received`);
            return sumLineItemBatches === undefined || sumLineItemBatches === ''
                ? ''
                : Number(sumLineItemBatches); // Usa '0' si el valor es undefined
        }
    };
    // Función para manejar el envío del formulario
    const onSubmit = async (data: any) => {
        let method = 'TOTAL'; // Método predeterminado es TOTAL
        let hasMismatchedSum = false; // Flag para rastrear si hay discrepancias en la suma

        // Construir el objeto json
        const jsonData = {
            billNumber: watchForm?.billNumber,
            lineItems: watchForm.lineItems.map((item: any, index: number) => {
                // Verificar si hay una discrepancia en la suma
                const sum =
                    order.buyer.company.companyGroup === 'MARKETPLACE'
                        ? item.batches
                              .filter((item: any) => item !== undefined)
                              .reduce((acc: number, batch: {received?: number}) => {
                                  return acc + (Number(batch.received) ?? 0);
                              }, 0)
                        : Number(item.received);
                setValue(`lineItems[${index}].diff`, sum - item.remaining);
                if (sum !== Number(item.remaining)) {
                    hasMismatchedSum = true;
                    method = 'PARCIAL'; // Si hay discrepancia, cambiamos el método a PARCIAL
                }

                let cont;

                if (order.buyer.company.companyGroup === 'MARKETPLACE') {
                    cont = {
                        itemId: item.id,
                        batches: item.batches.map((items: any) => {
                            const conts = {
                                batch: items.batch,
                                received: Number(items.received),
                                dateExpire: items.dateExpire,
                                manufacturingDate: items.manufacturingDate,
                            };
                            return conts;
                        }),
                    };
                } else {
                    cont = {
                        itemId: item.id,
                        batches: [{received: Number(item.received)}],
                    };
                }
                return cont;
            }),
        };

        // Esperar a que se construya el objeto json
        await Promise.all(
            watchForm.lineItems.map(async (item: any) => {
                // Operaciones asíncronas aquí si es necesario
            })
        );

        // Actualizar el estado con los valores calculados
        setJson(jsonData);
        setmodalOpen(true);
        setMethod(method); // Establecer el método calculado
        setHasMismatchedSum(hasMismatchedSum); // Establecer el estado de discrepancia en la suma
    };
    const onSubmitOrder = async (data: any) => {
        const jsonData = {
            billNumber: watchForm?.billNumber,
            lineItems: watchForm.lineItems.map((item: any, index: number) => {
                // Construir el objeto de batches
                let cont;

                if (order.buyer.company.companyGroup === 'MARKETPLACE') {
                    cont = {
                        itemId: item.id,
                        batches: item.batches.map((items: any) => {
                            const conts = {
                                batch: items.batch === '' ? '00.00.0000' : items.batch,
                                received: Number(items.received),
                                dateExpire: items.dateExpire === '' ? new Date() : items.dateExpire,
                                manufacturingDate:
                                    items.manufacturingDate === ''
                                        ? new Date()
                                        : items.manufacturingDate,
                            };
                            return conts;
                        }),
                    };
                } else {
                    cont = {
                        itemId: item.id,
                    };
                }
                return cont;
            }),
        };
        await Promise.all(watchForm.lineItems.map(async (item: any) => {}));
        await setJson(jsonData);
        if (!hasMismatchedSum) {
            dispatch(fetchPostOrder(orderId, json, token));
        } else {
            dispatch(getOrderIncompleteSuccess(watchForm, orderId, order.referenceId));
            window.location.href = `/orderincomplete/${orderId}`;
        }
    };
    useEffect(() => {
        if (token) dispatch(fetchGetOrder(orderId, token));
    }, [token, dispatch]);

    useEffect(() => {
        if (order !== null) {
            setOrderData(order);
        }
    }, [order]);

    if (
        notAllowed(
            FeatureCodeEnum.MERCHANDISE_RECEIPT_QR,
            MerchandiseReceptionEnum.INPUT_VALIDATION_QR
        )
    )
        navigate('/home');

    const getFirtBatchData = (item: any, index: number) => {
        if (item.batches[0]?.batch) {
            return {
                batch: item.batches[0]?.batch,
                date: DateTime.fromISO(item.batches[0]?.dateExpire).toFormat('dd/MM/yyyy'),
            };
        } else {
            if (watchForm.lineItems && watchForm.lineItems.length > 0) {
                return {
                    batch:
                        watchForm.lineItems[index].batches &&
                        watchForm.lineItems[index].batches.length > 0
                            ? watchForm.lineItems[index].batches[0].batch
                            : '000000',
                    date:
                        watchForm.lineItems[index].batches &&
                        watchForm.lineItems[index].batches.length > 0 &&
                        watchForm.lineItems[index].batches[0].dateExpire
                            ? DateTime.fromISO(
                                  watchForm.lineItems[index].batches[0].dateExpire.toISOString()
                              ).toFormat('dd/MM/yyyy')
                            : '00/00/00',
                };
            }
        }
    };
    return (
        <>
            {order &&
                loadingpost !== true &&
                (order.status !== 'DELIVERED' ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                background: '#F1F1F1',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: '100px',
                                    background: '#000',
                                    boxSizing: 'border-box',
                                    paddingLeft: '60px',
                                    alignItems: 'center',
                                    justifyContent: isMobile ? 'flex-start' : 'center',
                                }}
                            >
                                <img src={logoAbi} />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 'calc(100% - 70px)',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        background: '#fff',
                                        padding: isMobile ? '60px 60px 52px 60px' : '30px 20%',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? '45px' : '22.4px',
                                                fontFamily: 'Poppins',
                                                fontWeight: 600,
                                                color: '#011B34',
                                            }}
                                        >
                                            Orden de compra
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? '45px' : '22.4px',
                                                fontFamily: 'Poppins',
                                                fontWeight: 600,
                                                color: '#D1A33C',
                                                marginLeft: '15px',
                                            }}
                                        >
                                            #{order.referenceId}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: isMobile ? '33.6px' : '22.4px',
                                            fontFamily: 'Poppins',
                                            fontWeight: 400,
                                            color: '#011B34',
                                        }}
                                    >
                                        En este apartado encontrarás los detalles de tu orden, así
                                        cómo su estatus.
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: isMobile ? '33.6px' : '22.4px',
                                            fontFamily: 'Poppins',
                                            fontWeight: 600,
                                            color: '#011B34',
                                            borderTop: '1px solid #000000',
                                            marginTop: '20px',
                                            paddingTop: '15px',
                                        }}
                                    >
                                        Detalles del Centro
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? '33.6px' : '22.4px',
                                                fontFamily: 'Poppins',
                                                fontWeight: 400,
                                                color: '#011B34',
                                                marginTop: '5px',
                                            }}
                                        >
                                            Nombre del Centro:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? '33.6px' : '22.4px',
                                                fontFamily: 'Poppins',
                                                fontWeight: isMobile ? 500 : 400,
                                                color: '#D1A33C',
                                                marginLeft: '10px',
                                            }}
                                        >
                                            {order.deliveryCenter.code} {' - '}
                                            {order.deliveryCenter.name}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: isMobile ? '33.6px' : '22.4px',
                                            fontFamily: 'Poppins',
                                            fontWeight: 600,
                                            color: '#011B34',
                                            borderTop: '1px solid #000000',
                                            marginTop: '20px',
                                            paddingTop: '15px',
                                        }}
                                    >
                                        Detalles de proveedor
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? '33.6px' : '22.4px',
                                                fontFamily: 'Poppins',
                                                fontWeight: 400,
                                                color: '#011B34',
                                                marginTop: '5px',
                                            }}
                                        >
                                            Razón social:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? '33.6px' : '22.4px',
                                                fontFamily: 'Poppins',
                                                fontWeight: isMobile ? 500 : 400,
                                                color: '#011B34',
                                                marginLeft: '10px',
                                            }}
                                        >
                                            {order.vendor.name}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? '33.6px' : '22.4px',
                                                fontFamily: 'Poppins',
                                                fontWeight: 400,
                                                color: '#011B34',
                                                marginRight: '20px',
                                            }}
                                        >
                                            No. de Factura
                                        </Typography>
                                        <Controller
                                            name={`billNumber`}
                                            control={control}
                                            defaultValue=""
                                            render={({field, fieldState}) => (
                                                <TextField
                                                    color="success"
                                                    {...field}
                                                    id={`billNumber`}
                                                    label=""
                                                    error={!!fieldState.error}
                                                    helperText={
                                                        fieldState.error
                                                            ? fieldState.error.message
                                                            : ''
                                                    }
                                                    sx={{
                                                        width: '70%',
                                                        '& input': {
                                                            fontSize: isMobile ? '31px' : '22px',
                                                        },
                                                    }}
                                                    placeholder="00000000"
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        padding: isMobile ? '60px' : '25px 20%',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: isMobile ? '38px' : '25px',
                                            fontFamily: 'Poppins',
                                            fontWeight: 600,
                                            color: '#011B34',
                                        }}
                                    >
                                        Resumen de orden
                                    </Typography>

                                    {order.lineItems.map((item: any, index: number) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                minHeight: '200px',
                                                borderRadius: '8px',
                                                padding: isMobile ? '20px' : '10px',
                                                border: '1px solid #C7C7C7',
                                                borderLeft:
                                                    Object.keys(watchForm || {}).length > 0 &&
                                                    Array.isArray(watchForm?.lineItems)
                                                        ? calculateReceivedSum(Number(index)) ===
                                                          Number(item.remaining)
                                                            ? '12px solid #4BBC68'
                                                            : calculateReceivedSum(Number(index)) >
                                                              Number(item.remaining)
                                                            ? '12px solid #325A6D'
                                                            : calculateReceivedSum(Number(index)) <
                                                                  Number(item.remaining) &&
                                                              calculateReceivedSum(
                                                                  Number(index)
                                                              ) !== ''
                                                            ? '12px solid #E5B611'
                                                            : '12px solid #C7C7C7'
                                                        : '12px solid #C7C7C7',
                                                marginTop: isMobile ? '36px' : '24px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {errors.lineItems &&
                                                Array.isArray(errors.lineItems) &&
                                                errors.lineItems[index]?.batches?.message && (
                                                    <Typography
                                                        sx={{
                                                            fontSize: isMobile ? '28px' : '19px',
                                                            fontFamily: 'Poppins',
                                                            fontWeight: 400,
                                                            color: 'red',
                                                            margin: isMobile ? '0 30px' : '0 15px',
                                                        }}
                                                    >
                                                        {errors.lineItems[index].batches.message}
                                                    </Typography>
                                                )}
                                            {order.buyer.company.companyGroup === 'MARKETPLACE' && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        padding: isMobile ? '0 30px' : '0 15px',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '33.6px'
                                                                    : '22.4px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 400,
                                                                color: '#000000',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            Lote:{' '}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '33.6px'
                                                                    : '22.4px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 600,
                                                                color: '#D1A33C',
                                                                marginLeft: '10px',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            {getFirtBatchData(item, index)?.batch}
                                                        </Typography>{' '}
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '33.6px'
                                                                    : '22.4px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 400,
                                                                color: '#000000',
                                                                marginLeft: '25px',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            Cad:
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '33.6px'
                                                                    : '22.4px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 400,
                                                                color: '#011B34',
                                                                marginLeft: '10px',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            {getFirtBatchData(item, index)?.date}
                                                        </Typography>{' '}
                                                    </Box>
                                                    <img
                                                        src={iconEdit}
                                                        onClick={() =>
                                                            handleAddFirstInputBlock(item, index)
                                                        }
                                                        width="40px"
                                                    />
                                                </Box>
                                            )}
                                            {isMobile ? (
                                                <>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            padding: isMobile
                                                                ? '20px 30px 25px 30px'
                                                                : '10px 15px 12px 15px',
                                                        }}
                                                    >
                                                        {Array.isArray(watchForm.lineItems) &&
                                                        Object.keys(watchForm).length > 0 &&
                                                        calculateReceivedSum(Number(index)) !==
                                                            null ? (
                                                            calculateReceivedSum(Number(index)) ===
                                                            Number(item.remaining) ? (
                                                                <img
                                                                    src={iconOrderSuccess}
                                                                    width="50"
                                                                    height="50"
                                                                />
                                                            ) : calculateReceivedSum(
                                                                  Number(index)
                                                              ) > Number(item.remaining) ? (
                                                                <img
                                                                    src={iconExcedent}
                                                                    width="50"
                                                                    height="50"
                                                                />
                                                            ) : calculateReceivedSum(
                                                                  Number(index)
                                                              ) < Number(item.remaining) &&
                                                              calculateReceivedSum(
                                                                  Number(index)
                                                              ) !== '' ? (
                                                                <img
                                                                    src={iconOrderWarning}
                                                                    width="50"
                                                                    height="50"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={iconOrderRadio}
                                                                    width="50"
                                                                    height="50"
                                                                />
                                                            )
                                                        ) : (
                                                            <img
                                                                src={iconOrderRadio}
                                                                width="50"
                                                                height="50"
                                                            />
                                                        )}
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                paddingLeft: '20px',
                                                                width: '80%',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: isMobile
                                                                        ? '38px'
                                                                        : '25px',
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: 600,
                                                                    color: '#011B34',
                                                                }}
                                                            >
                                                                {item.productDetails[0].productName}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: isMobile
                                                                            ? '33.6px'
                                                                            : '22.4px',
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: 400,
                                                                        color: '#00000',
                                                                    }}
                                                                >
                                                                    Total{' '}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: isMobile
                                                                            ? '33.6px'
                                                                            : '22.4px',
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: 500,
                                                                        color: '#00ACFF',
                                                                        marginLeft: '10px',
                                                                    }}
                                                                >
                                                                    {formatNumber(
                                                                        item.remaining *
                                                                            item.productDetails[0]
                                                                                .realPrice
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '48px'
                                                                    : '32px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 500,
                                                                color: '#C7C7C7',
                                                            }}
                                                        >
                                                            X{item.remaining}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            padding: isMobile
                                                                ? '30px 30px 20px 20px'
                                                                : '15px 15px 10px 10px',
                                                        }}
                                                    >
                                                        {' '}
                                                        {Array.isArray(watchForm.lineItems) &&
                                                            watchForm.lineItems[index]
                                                                .isOpenBatch &&
                                                            typeof newInputs[index] !==
                                                                'undefined' && (
                                                                <div
                                                                    key={index}
                                                                    style={{width: '100%'}}
                                                                >
                                                                    {renderInputBlocks(
                                                                        item,
                                                                        index,
                                                                        newInputs[index]
                                                                    )}
                                                                    {newInputs[index] > 0 && (
                                                                        <Box mt={2}>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize:
                                                                                        isMobile
                                                                                            ? '36px'
                                                                                            : '20px',
                                                                                    fontFamily:
                                                                                        'Poppins',
                                                                                    fontWeight:
                                                                                        isMobile
                                                                                            ? 500
                                                                                            : 400,
                                                                                    color: '#00ACFF',
                                                                                    cursor: 'pointer',
                                                                                    width: 'fit-content',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleAddInputs(
                                                                                        Number(
                                                                                            index
                                                                                        )
                                                                                    )
                                                                                }
                                                                            >
                                                                                {' '}
                                                                                + Agregar
                                                                            </Typography>
                                                                        </Box>
                                                                    )}
                                                                </div>
                                                            )}
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            padding: '0px 30px 20px 30px',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '33.6px'
                                                                    : '22.4px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 400,
                                                                color: '#000000',
                                                            }}
                                                        >
                                                            Total recibido:{' '}
                                                        </Typography>
                                                        <Controller
                                                            key={index}
                                                            name={`lineItems[${index}].received`}
                                                            control={control}
                                                            render={({field, fieldState}) => (
                                                                <TextField
                                                                    {...field}
                                                                    id={`totalReceived-${index}`}
                                                                    error={!!fieldState.error}
                                                                    helperText={
                                                                        fieldState.error
                                                                            ? fieldState.error
                                                                                  .message
                                                                            : ''
                                                                    }
                                                                    value={
                                                                        order.buyer.company
                                                                            .companyGroup === 'CORE'
                                                                            ? field.value
                                                                            : calculateReceivedSum(
                                                                                  Number(index)
                                                                              )
                                                                    }
                                                                    InputProps={{
                                                                        readOnly:
                                                                            order.buyer.company
                                                                                .companyGroup ===
                                                                            'CORE'
                                                                                ? false
                                                                                : true,
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <Box
                                                                                    sx={{
                                                                                        color: '#000000',
                                                                                        fontWeight: 400,
                                                                                        fontFamily:
                                                                                            'Poppins',
                                                                                        borderRadius:
                                                                                            '4px', // Asegúrate de incluir el radio de borde para que se vea bien
                                                                                        paddingLeft:
                                                                                            '10px', // Espacio para que el contenido no esté pegado al borde
                                                                                        paddingRight:
                                                                                            '10px', // Espacio para que el contenido no esté pegado al borde
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item
                                                                                            .productDetails[0]
                                                                                            .unit
                                                                                    }
                                                                                </Box>
                                                                            </InputAdornment>
                                                                        ),
                                                                        sx: {
                                                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                                                                {
                                                                                    borderColor:
                                                                                        'transparent', // Establecer el borde del focus a transparente
                                                                                },
                                                                            '& input': {
                                                                                fontSize: isMobile
                                                                                    ? '28px'
                                                                                    : '19px',
                                                                                textAlign: 'end',
                                                                                color:
                                                                                    Object.keys(
                                                                                        watchForm
                                                                                    ).length > 0 &&
                                                                                    Array.isArray(
                                                                                        watchForm.lineItems
                                                                                    ) &&
                                                                                    calculateReceivedSum(
                                                                                        Number(
                                                                                            index
                                                                                        )
                                                                                    ) !== null
                                                                                        ? calculateReceivedSum(
                                                                                              Number(
                                                                                                  index
                                                                                              )
                                                                                          ) ===
                                                                                          Number(
                                                                                              item.remaining
                                                                                          )
                                                                                            ? '#4BBC68'
                                                                                            : calculateReceivedSum(
                                                                                                  Number(
                                                                                                      index
                                                                                                  )
                                                                                              ) >
                                                                                              Number(
                                                                                                  item.remaining
                                                                                              )
                                                                                            ? '#325A6D'
                                                                                            : calculateReceivedSum(
                                                                                                  Number(
                                                                                                      index
                                                                                                  )
                                                                                              ) <
                                                                                                  Number(
                                                                                                      item.remaining
                                                                                                  ) &&
                                                                                              calculateReceivedSum(
                                                                                                  Number(
                                                                                                      index
                                                                                                  )
                                                                                              ) !==
                                                                                                  ''
                                                                                            ? '#E5B611'
                                                                                            : '#C7C7C7'
                                                                                        : '#C7C7C7', // El tamaño de la fuente en px o cualquier otra unidad de medida que desees
                                                                            },
                                                                        },
                                                                    }}
                                                                    sx={{
                                                                        width: '60%',
                                                                        borderRadius: '5px',
                                                                        border:
                                                                            Object.keys(watchForm)
                                                                                .length > 0 &&
                                                                            Array.isArray(
                                                                                watchForm.lineItems
                                                                            ) &&
                                                                            calculateReceivedSum(
                                                                                Number(index)
                                                                            ) !== null
                                                                                ? calculateReceivedSum(
                                                                                      Number(index)
                                                                                  ) ===
                                                                                  Number(
                                                                                      item.remaining
                                                                                  )
                                                                                    ? '1.5px solid #4BBC68'
                                                                                    : calculateReceivedSum(
                                                                                          Number(
                                                                                              index
                                                                                          )
                                                                                      ) >
                                                                                      Number(
                                                                                          item.remaining
                                                                                      )
                                                                                    ? '1.5px solid #325A6D'
                                                                                    : calculateReceivedSum(
                                                                                          Number(
                                                                                              index
                                                                                          )
                                                                                      ) <
                                                                                          Number(
                                                                                              item.remaining
                                                                                          ) &&
                                                                                      calculateReceivedSum(
                                                                                          Number(
                                                                                              index
                                                                                          )
                                                                                      ) !== ''
                                                                                    ? '1.5px solid #E5B611'
                                                                                    : '1.5px solid #C7C7C7'
                                                                                : '1.5px solid #C7C7C7',
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        <Controller
                                                            key={index}
                                                            name={`lineItems[${index}].id`}
                                                            control={control}
                                                            defaultValue={item.id}
                                                            render={({field, fieldState}) => <></>}
                                                        />
                                                        <Controller
                                                            key={index}
                                                            name={`typeorder`}
                                                            control={control}
                                                            defaultValue={
                                                                order.buyer.company.companyGroup
                                                            }
                                                            render={({field, fieldState}) => <></>}
                                                        />
                                                        <Controller
                                                            key={index}
                                                            name={`lineItems[${index}].isOpenBatch`}
                                                            control={control}
                                                            defaultValue={false}
                                                            render={({field, fieldState}) => <></>}
                                                        />
                                                        <Controller
                                                            key={index}
                                                            name={`lineItems[${index}].isOpenBatchDetails`}
                                                            control={control}
                                                            defaultValue={false}
                                                            render={({field, fieldState}) => <></>}
                                                        />
                                                        <Controller
                                                            key={index}
                                                            name={`lineItems[${index}].unit`}
                                                            control={control}
                                                            defaultValue={
                                                                item.productDetails[0].unit
                                                            }
                                                            render={({field, fieldState}) => <></>}
                                                        />
                                                        <Controller
                                                            key={index}
                                                            name={`lineItems[${index}].productName`}
                                                            control={control}
                                                            defaultValue={
                                                                item.productDetails[0].productName
                                                            }
                                                            render={({field, fieldState}) => <></>}
                                                        />
                                                        <Controller
                                                            key={index}
                                                            name={`lineItems[${index}].price`}
                                                            control={control}
                                                            defaultValue={
                                                                item.productDetails[0].price
                                                            }
                                                            render={({field, fieldState}) => <></>}
                                                        />
                                                        <Controller
                                                            key={index}
                                                            name={`lineItems[${index}].remaining`}
                                                            control={control}
                                                            defaultValue={item.remaining}
                                                            render={({field, fieldState}) => <></>}
                                                        />
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            padding: isMobile
                                                                ? '20px 30px 25px 30px'
                                                                : '10px 15px 12px 15px',
                                                        }}
                                                    >
                                                        {Array.isArray(watchForm.lineItems) &&
                                                        Object.keys(watchForm).length > 0 &&
                                                        calculateReceivedSum(Number(index)) !==
                                                            null ? (
                                                            calculateReceivedSum(Number(index)) ===
                                                            Number(item.remaining) ? (
                                                                <img
                                                                    src={iconOrderSuccess}
                                                                    width="50"
                                                                    height="50"
                                                                />
                                                            ) : calculateReceivedSum(
                                                                  Number(index)
                                                              ) > Number(item.remaining) ? (
                                                                <img
                                                                    src={iconExcedent}
                                                                    width="50"
                                                                    height="50"
                                                                />
                                                            ) : calculateReceivedSum(
                                                                  Number(index)
                                                              ) < Number(item.remaining) &&
                                                              calculateReceivedSum(
                                                                  Number(index)
                                                              ) !== '' ? (
                                                                <img
                                                                    src={iconOrderWarning}
                                                                    width="50"
                                                                    height="50"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={iconOrderRadio}
                                                                    width="50"
                                                                    height="50"
                                                                />
                                                            )
                                                        ) : (
                                                            <img
                                                                src={iconOrderRadio}
                                                                width="50"
                                                                height="50"
                                                            />
                                                        )}
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                width: '50%',
                                                                paddingLeft: '20px',
                                                                justifyContent: 'flex-start',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: isMobile
                                                                        ? '38px'
                                                                        : '25px',
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: 600,
                                                                    color: '#011B34',
                                                                }}
                                                            >
                                                                {item.productDetails[0].productName}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: isMobile
                                                                            ? '33.6px'
                                                                            : '22.4px',
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: 400,
                                                                        color: '#00000',
                                                                    }}
                                                                >
                                                                    Total{' '}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: isMobile
                                                                            ? '33.6px'
                                                                            : '22.4px',
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: 500,
                                                                        color: '#00ACFF',
                                                                        marginLeft: '10px',
                                                                    }}
                                                                >
                                                                    {formatNumber(
                                                                        item.remaining *
                                                                            item.productDetails[0]
                                                                                .realPrice
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '48px'
                                                                    : '32px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 500,
                                                                color: '#C7C7C7',
                                                            }}
                                                        >
                                                            X{item.remaining}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyItems: 'flex-end',
                                                                justifyContent: 'flex-end',
                                                                width: '25%',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: isMobile
                                                                        ? '33.6px'
                                                                        : '22.4px',
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: 400,
                                                                    color: '#000000',
                                                                    textAlign: 'end',
                                                                }}
                                                            >
                                                                Total recibido:{' '}
                                                            </Typography>
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].received`}
                                                                control={control}
                                                                render={({field, fieldState}) => (
                                                                    <TextField
                                                                        {...field}
                                                                        id={`totalReceived-${index}`}
                                                                        value={
                                                                            order.buyer.company
                                                                                .companyGroup ===
                                                                            'CORE'
                                                                                ? field.value
                                                                                : calculateReceivedSum(
                                                                                      Number(index)
                                                                                  )
                                                                        }
                                                                        size="small"
                                                                        InputProps={{
                                                                            readOnly:
                                                                                order.buyer.company
                                                                                    .companyGroup ===
                                                                                'CORE'
                                                                                    ? false
                                                                                    : true,
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <Box
                                                                                        sx={{
                                                                                            color: '#000000',
                                                                                            fontWeight: 400,
                                                                                            fontFamily:
                                                                                                'Poppins',
                                                                                            borderRadius:
                                                                                                '4px', // Asegúrate de incluir el radio de borde para que se vea bien
                                                                                            paddingLeft:
                                                                                                '10px', // Espacio para que el contenido no esté pegado al borde
                                                                                            paddingRight:
                                                                                                '10px', // Espacio para que el contenido no esté pegado al borde
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item
                                                                                                .productDetails[0]
                                                                                                .unit
                                                                                        }
                                                                                    </Box>
                                                                                </InputAdornment>
                                                                            ),
                                                                            sx: {
                                                                                '& input': {
                                                                                    fontSize:
                                                                                        isMobile
                                                                                            ? '28px'
                                                                                            : '19px',
                                                                                    textAlign:
                                                                                        'end',
                                                                                    color:
                                                                                        Object.keys(
                                                                                            watchForm
                                                                                        ).length >
                                                                                            0 &&
                                                                                        Array.isArray(
                                                                                            watchForm.lineItems
                                                                                        ) &&
                                                                                        calculateReceivedSum(
                                                                                            Number(
                                                                                                index
                                                                                            )
                                                                                        ) !== null
                                                                                            ? calculateReceivedSum(
                                                                                                  Number(
                                                                                                      index
                                                                                                  )
                                                                                              ) ===
                                                                                              Number(
                                                                                                  item.remaining
                                                                                              )
                                                                                                ? '#4BBC68'
                                                                                                : calculateReceivedSum(
                                                                                                      Number(
                                                                                                          index
                                                                                                      )
                                                                                                  ) >
                                                                                                  Number(
                                                                                                      item.remaining
                                                                                                  )
                                                                                                ? '#325A6D'
                                                                                                : calculateReceivedSum(
                                                                                                      Number(
                                                                                                          index
                                                                                                      )
                                                                                                  ) <
                                                                                                      Number(
                                                                                                          item.remaining
                                                                                                      ) &&
                                                                                                  calculateReceivedSum(
                                                                                                      Number(
                                                                                                          index
                                                                                                      )
                                                                                                  ) !==
                                                                                                      ''
                                                                                                ? '#E5B611'
                                                                                                : '#C7C7C7'
                                                                                            : '#C7C7C7', // El tamaño de la fuente en px o cualquier otra unidad de medida que desees
                                                                                },
                                                                            },
                                                                        }}
                                                                        error={!!fieldState.error}
                                                                        helperText={
                                                                            fieldState.error
                                                                                ? fieldState.error
                                                                                      .message
                                                                                : ''
                                                                        }
                                                                        sx={{
                                                                            height: '45px',
                                                                            width: '100%',
                                                                            borderRadius: '5px',
                                                                            border:
                                                                                Object.keys(
                                                                                    watchForm
                                                                                ).length > 0 &&
                                                                                Array.isArray(
                                                                                    watchForm.lineItems
                                                                                ) &&
                                                                                calculateReceivedSum(
                                                                                    Number(index)
                                                                                ) !== null
                                                                                    ? calculateReceivedSum(
                                                                                          Number(
                                                                                              index
                                                                                          )
                                                                                      ) ===
                                                                                      item.remaining
                                                                                        ? '1.5px solid #4BBC68'
                                                                                        : calculateReceivedSum(
                                                                                              Number(
                                                                                                  index
                                                                                              )
                                                                                          ) >
                                                                                          item.remaining
                                                                                        ? '1.5px solid #325A6D'
                                                                                        : calculateReceivedSum(
                                                                                              Number(
                                                                                                  index
                                                                                              )
                                                                                          ) <
                                                                                              item.remaining &&
                                                                                          calculateReceivedSum(
                                                                                              Number(
                                                                                                  index
                                                                                              )
                                                                                          ) !== ''
                                                                                        ? '1.5px solid #E5B611'
                                                                                        : '1.5px solid #C7C7C7'
                                                                                    : '1.5px solid #C7C7C7',
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].id`}
                                                                control={control}
                                                                defaultValue={item.id}
                                                                render={({field, fieldState}) => (
                                                                    <></>
                                                                )}
                                                            />
                                                            <Controller
                                                                key={index}
                                                                name={`typeorder`}
                                                                control={control}
                                                                defaultValue={
                                                                    order.buyer.company.companyGroup
                                                                }
                                                                render={({field, fieldState}) => (
                                                                    <></>
                                                                )}
                                                            />
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].isOpenBatch`}
                                                                control={control}
                                                                defaultValue={false}
                                                                render={({field, fieldState}) => (
                                                                    <></>
                                                                )}
                                                            />
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].isOpenBatchDetails`}
                                                                control={control}
                                                                defaultValue={false}
                                                                render={({field, fieldState}) => (
                                                                    <></>
                                                                )}
                                                            />
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].unit`}
                                                                control={control}
                                                                defaultValue={
                                                                    item.productDetails[0].unit
                                                                }
                                                                render={({field, fieldState}) => (
                                                                    <></>
                                                                )}
                                                            />
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].productName`}
                                                                control={control}
                                                                defaultValue={
                                                                    item.productDetails[0]
                                                                        .productName
                                                                }
                                                                render={({field, fieldState}) => (
                                                                    <></>
                                                                )}
                                                            />
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].price`}
                                                                control={control}
                                                                defaultValue={
                                                                    item.productDetails[0].price
                                                                }
                                                                render={({field, fieldState}) => (
                                                                    <></>
                                                                )}
                                                            />
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].remaining`}
                                                                control={control}
                                                                defaultValue={item.remaining}
                                                                render={({field, fieldState}) => (
                                                                    <></>
                                                                )}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            padding: isMobile
                                                                ? '30px 30px 20px 20px'
                                                                : '15px 15px 10px 10px',
                                                        }}
                                                    >
                                                        {''}
                                                        {Array.isArray(watchForm.lineItems) &&
                                                            watchForm.lineItems[index]
                                                                .isOpenBatch &&
                                                            typeof newInputs[index] !==
                                                                'undefined' && (
                                                                <div
                                                                    key={index}
                                                                    style={{width: '100%'}}
                                                                >
                                                                    {renderInputBlocks(
                                                                        item,
                                                                        index,
                                                                        newInputs[index]
                                                                    )}
                                                                    {
                                                                        <Box mt={2}>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize:
                                                                                        isMobile
                                                                                            ? '36px'
                                                                                            : '20px',
                                                                                    fontFamily:
                                                                                        'Poppins',
                                                                                    fontWeight:
                                                                                        isMobile
                                                                                            ? 500
                                                                                            : 400,
                                                                                    color: '#00ACFF',
                                                                                    cursor: 'pointer',
                                                                                    width: 'fit-content',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleAddInputs(
                                                                                        Number(
                                                                                            index
                                                                                        )
                                                                                    )
                                                                                }
                                                                            >
                                                                                {' '}
                                                                                + Agregar
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                </div>
                                                            )}
                                                    </Box>
                                                </>
                                            )}
                                            {item.requiresValidation && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '0px 30px 0px 30px',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: isMobile
                                                                ? '33.6px'
                                                                : '22.4px',
                                                            fontFamily: 'Poppins',
                                                            fontWeight: 600,
                                                            color: '#011B34',
                                                        }}
                                                    >
                                                        {' '}
                                                        Este producto está imputado{' '}
                                                    </Typography>
                                                </Box>
                                            )}
                                            {Array.isArray(watchForm.lineItems) &&
                                            Object.keys(watchForm).length > 0 &&
                                            calculateReceivedSum(Number(index)) !== null ? (
                                                calculateReceivedSum(Number(index)) ===
                                                item.remaining ? (
                                                    <> </>
                                                ) : calculateReceivedSum(Number(index)) >
                                                  item.remaining ? (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            height: isMobile ? '100px' : '50px',
                                                            paddingLeft: isMobile ? '50px' : '25px',
                                                            alignItems: 'center',
                                                            background: '#325A6D',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '33.6px'
                                                                    : '22.4px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 600,
                                                                color: '#FFFFFF',
                                                            }}
                                                        >
                                                            {' '}
                                                            La cantidad es mayor a la solicitada
                                                        </Typography>
                                                    </Box>
                                                ) : calculateReceivedSum(Number(index)) <
                                                      item.remaining &&
                                                  calculateReceivedSum(Number(index)) !== '' ? (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            height: isMobile ? '100px' : '50px',
                                                            paddingLeft: isMobile ? '50px' : '25px',
                                                            alignItems: 'center',
                                                            background: '#E5B611',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '33.6px'
                                                                    : '22.4px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 600,
                                                                color: '#FFFFFF',
                                                            }}
                                                        >
                                                            {' '}
                                                            Entrega parcial detectada
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <> </>
                                                )
                                            ) : (
                                                <> </>
                                            )}
                                        </Box>
                                    ))}
                                    <OrderButton disabled={loading}>
                                        {loading ? 'CARGANDO...' : 'CONTINUAR'}
                                    </OrderButton>
                                </Box>
                            </Box>
                        </Box>
                        <StyledModal open={modalOpen} onClose={closeModal}>
                            <Box
                                sx={{
                                    background: '#FFF',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '10px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: isMobile ? '60px' : '30px',
                                    }}
                                >
                                    <img src={iconAlertWarning} width="85px" />
                                    <Typography
                                        sx={{
                                            fontSize: isMobile ? '38px' : '25px',
                                            fontFamily: 'Poppins',
                                            fontWeight: 600,
                                            color: '#011B34',
                                            marginTop: '40px',
                                        }}
                                    >
                                        Tu orden de compra es detectada como{' '}
                                        <Typography
                                            component="span"
                                            sx={{
                                                fontSize: isMobile ? '38px' : '25px',
                                                fontFamily: 'Poppins',
                                                fontWeight: 600,
                                                color: '#D1A33C',
                                            }}
                                        >
                                            {method}
                                        </Typography>
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: isMobile ? '36px' : '24px',
                                            fontFamily: 'Poppins',
                                            fontWeight: 400,
                                            color: '#011B34',
                                            marginTop: isMobile ? '40px' : '20px',
                                        }}
                                    >
                                        {method === 'TOTAL'
                                            ? 'Tu orden esta completa sin ninguna irregularidad'
                                            : 'Algunos de tus productos de tu orden no llegaron completos ¿Deseas continuar?'}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {isMobile ? (
                                            <>
                                                <ColorButtonMobile onClick={onSubmitOrder}>
                                                    Continuar
                                                </ColorButtonMobile>
                                                <ButtonAlternativeMobile onClick={closeModal}>
                                                    Volver
                                                </ButtonAlternativeMobile>
                                            </>
                                        ) : (
                                            <>
                                                <ColorButtonModal onClick={onSubmitOrder}>
                                                    Continuar
                                                </ColorButtonModal>
                                                <ButtonAlternativeModal onClick={closeModal}>
                                                    Volver
                                                </ButtonAlternativeModal>
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </StyledModal>
                    </form>
                ) : (
                    <OrderDelivered />
                ))}

            {!order && error === true && <NotFound orderData={orderData} />}
            {loading === true && <Loader text="Cargando la PO" />}
            {loadingpost === true && <Loader text="Generando Hoja de entrada, espere un momento" />}
        </>
    );
};

Order.acl = {
    subject: FeatureCodeEnum.MERCHANDISE_RECEIPT_QR,
    action: MerchandiseReceptionEnum.INPUT_VALIDATION_QR,
};
export default Order;
