/* eslint-disable no-unused-vars */
export enum Action {
    INVOICE_STATUS = 'Estatus de factura',
    PAYMENT_DATE = 'Fecha de pago',
    PAYMENT_CONDITION = 'Aclaraciones condición de pago',
    REQUIREMENT_AUDITOR = 'Requerimiento de auditoría',
    PORTAL_PROVIDER = 'Dudas del Portal de Proveedores',
    DETAIL_RETENTION = 'Detalle de retenciones',
    BANK_RECEIPT_PAYMENT = 'Comprobante bancario de pago',
}

export const getActionKey = (valueOrigin: string): string => {
    const value = valueOrigin === 'Estatus de facturas' ? 'Estatus de factura' : valueOrigin;

    let actionKey = value;
    if (Action[valueOrigin as keyof typeof Action]) {
        actionKey = Action[valueOrigin as keyof typeof Action];
    }

    return (
        Object.keys(Action).find((key) => Action[key as keyof typeof Action] === actionKey) || ''
    );
};

// COMPLETED: 'Completo',s

export const performActionValues: Record<string, string> = {
    INVOICE_STATUS: 'INVOICE_STATUS',
    PAYMENT_DATE: 'PAYMENT_DATE',
    PAYMENT_CONDITION: 'PAYMENT_CONDITION',
    REQUIREMENT_AUDITOR: 'REQUIREMENT_AUDITOR',
    PORTAL_PROVIDER: 'PORTAL_PROVIDER',
    DETAIL_RETENTION: 'DETAIL_RETENTION',
    BANK_RECEIPT_PAYMENT: 'BANK_RECEIPT_PAYMENT',
};

export const statusTextValues: Record<string, string> = {
    RECEIVED: 'RECEIVED',
    IN_PROGRESS: 'IN_PROGRESS',
    ASSIGNED: 'ASSIGNED',
    CREATED: 'CREATED',
    COMPLETE: 'COMPLETE',
};
