import {RootState} from '@/config/store';
import {Box, Button, Grid, TextField, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
const Security: React.FC = () => {
    const {t} = useTranslation();
    const user = useSelector((state: RootState) => state.logins.user);
    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 3,
            }}
            noValidate
            autoComplete="off"
        >
            <Typography variant="h6" gutterBottom>
                Seguridad
            </Typography>
            <Grid
                container
                spacing={2}
                sx={{
                    maxWidth: '600px',
                    mt: '10px',
                    backgroundColor: '#FFFFFF',
                    p: 3,
                    mr: 4,
                }}
            >
                <Grid item xs={12} sx={{mr: 2}}>
                    <TextField
                        label={t('account.security.company')}
                        defaultValue={user.company.name}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sx={{mr: 2}}>
                    <TextField
                        label={t('account.security.email')}
                        defaultValue={user.email}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sx={{mr: 2}}>
                    <TextField
                        label={t('account.security.enterprise')}
                        defaultValue={user.enterprise.name}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={12} sx={{mr: 2}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const url = 'https://mdwebqa.ab-inbev.com/login';
                            window.open(url, '_blank');
                        }}
                        fullWidth
                        sx={{color: '#FFFFFF'}}
                    >
                        {t('account.security.password')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
export default Security;
