import {NotificationItemType} from '@components/notifications/redux/type/notification-type';
import {DateTime} from 'luxon';

export const mockData: NotificationItemType[] = [];

export const mockNotificationFetch = (url: string) => {
    if (url.includes('/api/v1/notifications')) {
        const data = {
            data: mockData,
        };

        return Promise.resolve({
            ok: true,
            status: 200,
            json: () => Promise.resolve({data}),
        });
    } else {
        return Promise.reject(new Error('Not Found'));
    }
};

export const mockPatchNotificationFetch = (url: string) => {
    if (url.includes('/api/v1/notifications/read')) {
        const data = {
            data: mockData.map((item) => ({...item, isRead: true})),
        };

        return Promise.resolve({
            ok: true,
            status: 200,
            json: () => Promise.resolve({data}),
        });
    } else {
        return Promise.reject(new Error('Not Found'));
    }
};
export const mockPostNotificationFetch = (body: NotificationItemType) => {
    const newNotification = body;
    mockData.push({
        ...newNotification,
        notification: {
            ...newNotification.notification,
            date: DateTime.fromJSDate(new Date(newNotification.notification.date)).toFormat(
                'dd-MMM-yyyy'
            ),
        },
    });
    const data = {data: mockData};

    return Promise.resolve({
        ok: true,
        status: 200,
        json: () => Promise.resolve({data}),
    });
};
