import {Box} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';

import {customScrollbar} from '@/components/orderSimply/constants/custom-scrollbar';
import ConfirmActionDefault from '@/components/orderSimply/components/ConfirmActionDefault';
import Positions, {Position} from '@/components/orderSimply/components/Positions';

interface ConfirmActionWithDetailsProps {
    addressCode: string;
    purchaseOrderId: string;
    positions: Position[];
    isDisableAction: boolean;
    setIsRejectModalOpen: (value: boolean) => void;
}

const ConfirmActionWithDetails: FunctionComponent<ConfirmActionWithDetailsProps> = ({
    addressCode,
    purchaseOrderId,
    positions,
    isDisableAction,
    setIsRejectModalOpen,
}) => {
    const {t} = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                height: '100vh',
                padding: '15px 23px',
                paddingTop: '50px',
            }}
        >
            <Box
                sx={{
                    borderRadius: 2,
                    maxWidth: '600px',
                    minWidth: '350px',
                    width: '50%',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#1f2733' : 'rgb(244, 247, 250)',
                    boxShadow: (theme) =>
                        theme.palette.mode === 'dark' ? 'unset' : '0 8px 16px 0 #BDC9D7',
                }}
            >
                <Box
                    sx={{
                        borderRadius: 2,
                    }}
                >
                    <Positions
                        title={t('confirmposimply.new_purchase_order')}
                        purchaseOrderId={purchaseOrderId}
                        addressCode={addressCode}
                        positions={positions}
                        sxPositionsList={{
                            height: '64vh',
                            overflow: 'hidden',
                            overflowY: 'scroll',
                            ...customScrollbar,
                        }}
                    />
                </Box>
            </Box>
            <ConfirmActionDefault
                purchaseOrderId={purchaseOrderId}
                isDisableAction={isDisableAction}
                setIsRejectModalOpen={setIsRejectModalOpen}
            />
        </Box>
    );
};

export default ConfirmActionWithDetails;
