import React, {ChangeEvent, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
    Box,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography,
    useTheme,
} from '@mui/material';
import Tab from '@mui/material/Tab';

import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
    ALLOWED_FILE_TYPES,
    MAX_FILE_SIZE_MB,
} from '@/components/orderSimply/utils/fileUploadConfig';
import {processFileName} from '@/components/orderSimply/utils/fileUploadHelpers';
import {
    fetchPostFileXml2wm,
    fetchPostParseFileXml,
} from '@components/orderSimply/redux/actions/OrdersSimplyActions';

import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';
import IconPDF from '@components/UI/atoms/icons/IconPDF.svg';
import IconXML from '@components/UI/atoms/icons/IconXML.svg';

import {
    ButtonCancel,
    Nav,
    SaveButton,
    StyledModal,
    TypographyStyled400,
    TypographyStyled500,
    TypographyStyled600,
} from '@/components/faqs/StyledFAQ';
import {
    FileUploadContainer,
    StyledFileLabelButton,
    StyledInput,
    StyledLabelFileUpload,
} from '@/components/legalAgreements/StyledLegalAgreements';
import FileValidations from '@/components/orderSimply/components/FileValidations';
import StatusMessage from '@/components/orderSimply/components/StatusMessage';
import {useTranslation} from 'react-i18next';
import {ErrorType} from '@/components/orderSimply/utils/format-orders';
import {isObjectEmpty} from '@/components/orderSimply/utils/constants';
import {ICfdi} from '@/components/orderSimply/interfaces/cfdi.interface';

interface IModal2WM {
    closeAndReload?: (event?: {preventDefault: () => void}, reason?: string) => void;
    closeModal: (event?: {preventDefault: () => void}, reason?: string) => void;
    lineItemsFilter?: string[];
    lineItems?: any[];
    isModalOpen: boolean;
    loading?: boolean;
    purchaseOrderId?: string;
    purchaseOrderStatus?: string;
    type?: string;
}

const Modal2WM: React.FC<IModal2WM> = ({
    closeAndReload,
    closeModal,
    lineItemsFilter = [],
    lineItems = [],
    isModalOpen,
    loading = false,
    purchaseOrderId = '',
    purchaseOrderStatus = '',
    type = '',
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [value, setValue] = useState<string>('1');
    const [errorFile, setErrorFile] = useState<string>('');
    const [files, setFiles] = useState<File[]>([]);
    const [fileNames, setFileNames] = useState<string[]>([]);
    const [showValidations, setShowValidations] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [allLineItems, setAllLineItems] = useState<boolean>(false);
    const [ranValidations, setRanValidations] = useState<boolean>(false);
    const [validationOk, setValidationOk] = useState<boolean>(false);
    const [previousErrors, setPreviousErrors] = useState<string[]>([]);
    const [lineItemAmounts, setLineItemAmounts] = useState<{[lineItemId: string]: number}>({});
    const [totalAssignedAmount, setTotalAssignedAmount] = useState<number>(0);

    const token = useSelector((state: any) => state.logins.accessToken);
    const errors = useSelector((state: any) => state.orderssimply.errors);
    const error = useSelector((state: any) => state.orderssimply.error);
    const currentData = useSelector((state: any) => state.orderssimply.orderssimply);
    const billErrors = useSelector((state: any) => state.orderssimply.billErrors);
    const jsonCfdi: ICfdi = useSelector((state: any) => state.orderssimply.jsonCfdi);
    const paidIsMoreThanSubtotal = totalAssignedAmount > Number(jsonCfdi.SubTotal);
    const paymentAssocIsFullfilled = totalAssignedAmount === Number(jsonCfdi.SubTotal);

    const VALIDATION_NO_ERRORS: boolean = error === ErrorType.NONE;
    const VALIDATION_SUCCESS: boolean = ranValidations && VALIDATION_NO_ERRORS;
    const VALIDATION_SUCCESS_WITH_SAP_ERROR: boolean =
        ranValidations &&
        error === ErrorType.RULE &&
        Array.isArray(errors) &&
        errors.some((error: string) => error === 'sap.confirm_error');

    useEffect(() => {
        if (isModalOpen) {
            const previousErrors =
                billErrors &&
                billErrors
                    .filter((lineItem: any) => lineItem.purchase_order_id === purchaseOrderId)
                    .map((lineItem: any) => lineItem.validationData ?? [])
                    .flat();

            if (previousErrors && previousErrors?.length > 0) {
                setValue('0');
                setPreviousErrors(previousErrors);
            } else {
                setValue('1');
                setPreviousErrors([]);
            }
        }
    }, [purchaseOrderId, isModalOpen, billErrors.length > 0]);

    useEffect(() => {
        if (!loading && Array.isArray(errors) && errors.length > 0) {
            setValidationErrors(errors);
            setPreviousErrors(errors);
            setShowValidations(true);
            setValue('2');
        } else {
            setPreviousErrors([]);
            setValidationErrors([]);
        }
        setValidationOk(VALIDATION_SUCCESS || VALIDATION_SUCCESS_WITH_SAP_ERROR);
    }, [errors, loading, VALIDATION_SUCCESS, VALIDATION_SUCCESS_WITH_SAP_ERROR]);

    useEffect(() => {
        const newFileNames = files.map((file) => {
            if (file.name.length > 22) {
                return processFileName(file.name);
            }
            return file.name;
        });
        setFileNames(newFileNames);
    }, [files]);

    useEffect(() => {
        setTotalAssignedAmount(
            Object.values(lineItemAmounts).reduce((acc, curr) => acc + (isNaN(curr) ? 0 : curr), 0)
        );
    }, [lineItemAmounts]);

    const schemaFormModal = yup.object({
        file: yup.mixed().required(t('modal_load_file_2wm.required_file')),
    });

    const {handleSubmit, control, reset} = useForm({
        resolver: yupResolver(schemaFormModal),
    });

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleCloseAndReload = () => {
        if (validationOk) onSubmit();
        resetData();
        if (closeAndReload) {
            closeAndReload();
        }
    };

    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
        type: string
    ) => {
        event.stopPropagation();
        const uploadedFiles = getSelectedFiles(event, type);
        if (!uploadedFiles || uploadedFiles.length === 0 || uploadedFiles.length > 2) {
            setErrorFile(t('modal_load_file_2wm.invalid_file_count'));
            return;
        }

        const {newFiles, fileError} = validateSelectedFiles(uploadedFiles);

        if (fileError.length > 0) {
            setErrorFile(fileError);
            return;
        }

        setErrorFile('');
        const updatedFiles = updateFilesState(newFiles);
        const {isFullfilled, pendingExtension} = filesAnalysis(updatedFiles);

        if (isFullfilled) {
            setValue('2');
            setShowValidations(false);
            parseXml(updatedFiles.find((f) => f.type === 'text/xml'));
        } else {
            setErrorFile(`${t('modal_load_file_2wm.penfile')} ${pendingExtension}.`);
            setValue('1');
        }
    };

    const getSelectedFiles = (
        event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
        type: string
    ): FileList | null => {
        return type === 'drop'
            ? (event as React.DragEvent<HTMLDivElement>).dataTransfer.files
            : (event.target as HTMLInputElement).files;
    };

    const validateSelectedFiles = (files: FileList) => {
        const newFiles: File[] = [];
        let fileError: string = '';
        let xmlCount = 0;
        let pdfCount = 0;

        Array.from(files).forEach((file) => {
            if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                fileError = t('modal_load_file_2wm.invalid_file');
                return;
            }
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                fileError = t(
                    `${t('modal_load_file_2wm.file_size_exceeds')} ${MAX_FILE_SIZE_MB} MB. ${t(
                        'modal_load_file_2wm.smaller_file'
                    )}`
                );
                return;
            }
            if (file.type === 'text/xml') {
                xmlCount++;
            } else if (file.type === 'application/pdf') {
                pdfCount++;
            }
            newFiles.push(file);
        });

        return {newFiles, fileError, xmlCount, pdfCount};
    };

    const updateFilesState = (newFiles: File[]) => {
        const stateFiles = [...files];

        newFiles.forEach((file) => {
            const existingFileIndex = files.findIndex((f) =>
                f.name.endsWith(file.name.split('.').pop() || '')
            );
            if (existingFileIndex !== -1) {
                stateFiles[existingFileIndex] = file;
            } else {
                stateFiles.push(file);
            }
        });

        setFiles(stateFiles);
        return stateFiles;
    };

    const filesAnalysis = (validFiles: File[]) => {
        const isFullfilled =
            validFiles.length === 2 && validFiles.some((f) => f.type === 'text/xml');
        const pdfCountFiles = validFiles.filter((f) => f.type === 'application/pdf').length;
        const pendingExtension = files.some((f) => f.type === 'text/xml') ? 'PDF' : 'XML';
        const totalFiles = validFiles.length;
        const xmlCountFiles = validFiles.filter((f) => f.type === 'text/xml').length;

        return {
            isFullfilled,
            pdfCountFiles,
            pendingExtension,
            totalFiles,
            xmlCountFiles,
        };
    };

    const parseXml = async (xml: File | undefined) => {
        try {
            if (xml) {
                await dispatch(fetchPostParseFileXml(token, {xml}));
                setValue('assoc_payments');
            }
        } catch (error) {
            //
        }
    };

    const validateFile = async () => {
        const invoiceMap = Object.entries(lineItemAmounts).map(([lineItemId, amount]) => ({
            lineItemId,
            amount,
        }));
        try {
            const xml = files.find((f) => f.type === 'text/xml');
            const pdf = files.find((f) => f.type === 'application/pdf');
            dispatch(
                fetchPostFileXml2wm(
                    token,
                    {xml, pdf},
                    purchaseOrderId,
                    lineItemsFilter,
                    allLineItems,
                    '2wm',
                    invoiceMap
                )
            );

            setShowValidations(true);
            setRanValidations(true);
            setValue('2');
        } catch (error) {
            setValidationOk(false);
            setRanValidations(false);
        }
    };

    const onSubmit = async () => {
        //
    };

    const handleChangeDelete = (fileName: string) => {
        const isXmlFile = fileName.includes('.xml');
        reset({file: null});
        const newFiles = fileNames.filter((name) => name !== fileName);

        setFileNames(newFiles);
        setErrorFile(`${t('modal_load_file_2wm.penfile')} ${isXmlFile ? 'XML' : 'PDF'}.`);
        setFiles(files.filter((file) => file.name !== fileName));

        if (isXmlFile || newFiles.length === 1) {
            handleCancelButton();
        }
    };

    const handleCancelButton = () => {
        if (['0', '1'].includes(value)) {
            handleCloseAndReload();
        } else if (value === '2') {
            setValidationErrors([]);
            handleTabChange({} as React.ChangeEvent<Element>, 'assoc_payments');
        } else if (value === 'assoc_payments') {
            setValidationErrors([]);
            handleTabChange({} as React.ChangeEvent<Element>, '1');
        }
    };

    const cleanup = () => {
        reset({file: null});
        setShowValidations(false);
        setValidationErrors([]);
        setValue('1');
        setPreviousErrors([]);
    };

    const resetData = () => {
        cleanup();
        setErrorFile('');
        setFiles([]);
        setFileNames([]);
        setRanValidations(false);
        setValidationOk(false);
        setAllLineItems(false);
        setShowValidations(false);
        setLineItemAmounts({});
        setTotalAssignedAmount(0);
    };

    const handleAmountChange = (lineItemId: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const amount = parseFloat(event.target.value);
        setLineItemAmounts((prevAmounts) => ({
            ...prevAmounts,
            [lineItemId]: amount,
        }));
    };

    const getHeight = () => {
        if (currentData.length === 1) return '60px';
        if (currentData.length === 2) return '110px';
        if (currentData.length > 2) return '160px';
    };

    const hasCurrentErrors = ranValidations && validationErrors.length > 1 && ['2'].includes(value);
    const hasPreviousErrors = previousErrors.length > 1 && ['0'].includes(value);
    const hasErrors = hasCurrentErrors || hasPreviousErrors;
    const errorCount =
        validationErrors.length > 0 ? validationErrors.length : previousErrors.length;

    return (
        <StyledModal
            open={isModalOpen}
            onClose={() => closeModal}
            disableEscapeKeyDown={true}
            disableEnforceFocus={true}
        >
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: '665px',
                    height: '501px',
                    zIndex: '100',
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            {t('load')} {type} 2WM
                        </Typography>
                    </Nav>
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleTabChange}>
                                {previousErrors && previousErrors.length > 0 ? (
                                    <Tab
                                        disabled={ranValidations}
                                        label={t('previous_errors')}
                                        value="0"
                                        sx={{
                                            textTransform: 'none',
                                        }}
                                    />
                                ) : null}
                                <Tab
                                    disabled={validationOk}
                                    label={t('upload_files')}
                                    value="1"
                                    sx={{textTransform: 'none'}}
                                />
                                <Tab
                                    disabled={isObjectEmpty(jsonCfdi) || files.length < 2}
                                    label={t('modal_load_file_2wm.tabs.assoc_payments')}
                                    value="assoc_payments"
                                    sx={{textTransform: 'none'}}
                                />
                                <Tab
                                    disabled={
                                        VALIDATION_SUCCESS ||
                                        fileNames.length < 2 ||
                                        paidIsMoreThanSubtotal ||
                                        !paymentAssocIsFullfilled
                                    }
                                    label={t('validation')}
                                    value="2"
                                    sx={{textTransform: 'none'}}
                                />
                            </TabList>
                        </Box>
                        {previousErrors && previousErrors.length > 0 ? (
                            <TabPanel value="0">
                                <FileUploadContainer
                                    sx={{
                                        justifyContent: 'flex-start',
                                        height: '40px',
                                        marginTop: '10px',
                                        borderBottomStyle: 'none',
                                        borderBottomLeftRadius: '0',
                                        borderBottomRightRadius: '0',
                                    }}
                                >
                                    <TypographyStyled500 sx={{marginLeft: '30px'}}>
                                        {t('modal_load_file_2wm.element')}
                                    </TypographyStyled500>
                                    <TypographyStyled500 sx={{marginLeft: '110px'}}>
                                        {t('state')}
                                    </TypographyStyled500>
                                    <TypographyStyled500 sx={{marginLeft: '50px'}}>
                                        {t('modal_load_file_2wm.comments')}
                                    </TypographyStyled500>
                                </FileUploadContainer>
                                <FileUploadContainer>
                                    <FileValidations
                                        errors={previousErrors}
                                        loading={loading}
                                        showValidations={true}
                                        successMessage=""
                                        sx={{
                                            height: '200px',
                                            padding: '24px',
                                            overflow: 'hidden',
                                            overflowY: 'auto',
                                        }}
                                    />
                                </FileUploadContainer>
                            </TabPanel>
                        ) : null}
                        <TabPanel value="1">
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Controller
                                    name="file"
                                    control={control}
                                    defaultValue={null}
                                    render={({field, fieldState}) => (
                                        <FileUploadContainer
                                            sx={{
                                                minHeight: '230px',
                                                margin: fieldState.error ? '0' : '10px 0',
                                                borderColor: fieldState.error ? 'red' : 'none',
                                            }}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                const file = e.dataTransfer.files[0];
                                                if (file) field.onChange(file);
                                                handleFileChange(e, 'drop');
                                            }}
                                            onClick={() =>
                                                document.getElementById('fileInput')?.click()
                                            }
                                        >
                                            <StyledInput
                                                id="fileInput"
                                                type="file"
                                                multiple={true}
                                                accept="application/pdf, text/xml"
                                                onChange={(e) => {
                                                    handleFileChange(e, 'change');
                                                }}
                                            />
                                            {field.value && !errorFile ? (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Typography variant="subtitle2">
                                                        {t('modal_load_file_2wm.last_file')}:
                                                        {field.value.name}
                                                    </Typography>
                                                    {!loading && (
                                                        <IconButton
                                                            disabled={validationOk}
                                                            aria-label="Eliminar"
                                                            onClick={(e) => {
                                                                field.onChange(null);
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            ) : (
                                                <Box>
                                                    <StyledFileLabelButton
                                                        variant="outlined"
                                                        as="span"
                                                    >
                                                        <img src={CloudIcon} />
                                                        <StyledLabelFileUpload>
                                                            {t('modal_load_file_2wm.files_here')}
                                                        </StyledLabelFileUpload>
                                                    </StyledFileLabelButton>
                                                    <Typography variant="caption" color="error">
                                                        {errorFile}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </FileUploadContainer>
                                    )}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            <TypographyStyled400 sx={{fontSize: '16px'}}>
                                {t('modal_load_file_2wm.following_files')}:
                            </TypographyStyled400>
                            <FileUploadContainer
                                sx={{
                                    height: '50px',
                                    cursor: 'default',
                                    marginTop: '15px',
                                }}
                            >
                                {fileNames && (
                                    <>
                                        {fileNames.map((fileName, index) => (
                                            <Box
                                                key={index}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <img
                                                    src={
                                                        fileName.includes('xml') ? IconXML : IconPDF
                                                    }
                                                />
                                                <Typography
                                                    sx={{
                                                        color: 'rgba(0, 0, 0, 0.38)',
                                                        fontSize: '16px',
                                                        marginLeft: '5px',
                                                    }}
                                                >
                                                    {fileName}
                                                </Typography>
                                                {!validationOk && (
                                                    <IconButton
                                                        aria-label="Eliminar"
                                                        onClick={() => handleChangeDelete(fileName)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        ))}
                                    </>
                                )}
                            </FileUploadContainer>
                            <TypographyStyled600 sx={{marginTop: '20px'}}>
                                {t('modal_load_file_2wm.sumary')}:
                            </TypographyStyled600>
                            <FileUploadContainer
                                sx={{
                                    justifyContent: 'flex-start',
                                    height: '40px',
                                    marginTop: '10px',
                                    borderBottomStyle: 'none',
                                    borderBottomLeftRadius: '0',
                                    borderBottomRightRadius: '0',
                                }}
                            >
                                <TypographyStyled500 sx={{marginLeft: '30px'}}>
                                    {t('modal_load_file_2wm.element')}
                                </TypographyStyled500>
                                <TypographyStyled500 sx={{marginLeft: '110px'}}>
                                    {t('state')}
                                </TypographyStyled500>
                                <TypographyStyled500 sx={{marginLeft: '50px'}}>
                                    {t('modal_load_file_2wm.comments')}
                                </TypographyStyled500>
                            </FileUploadContainer>
                            <FileUploadContainer
                                sx={{
                                    borderTopLeftRadius: '0',
                                    borderTopRightRadius: '0',
                                    display: 'block',
                                    height: '80px',
                                    justifyContent: 'flex-start',
                                    overflow: 'hidden',
                                    overflowY: 'scroll',
                                }}
                            >
                                <FileValidations
                                    errors={validationErrors}
                                    loading={loading}
                                    showValidations={showValidations}
                                    successMessage={
                                        purchaseOrderStatus === 'CONFIRMED'
                                            ? t('validated_invoice')
                                            : t('invoice_scheduled_payment')
                                    }
                                />
                            </FileUploadContainer>
                        </TabPanel>
                        <TabPanel value="assoc_payments">
                            <Box sx={{minHeight: '200px'}}>
                                <Grid
                                    xs={12}
                                    item
                                    container
                                    sx={{
                                        background: '#e9e6e6',
                                        padding: '4px 2px 4px 2px',
                                    }}
                                >
                                    <Grid item xs={4}>
                                        <span style={{fontWeight: 'bold'}}>
                                            {t('modal_load_file_2wm.table.pos')}
                                        </span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{fontWeight: 'bold'}}>
                                            {t('modal_load_file_2wm.table.quantity')}
                                        </span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{fontWeight: 'bold'}}>
                                            {' '}
                                            {t('modal_load_file_2wm.table.import')}
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid
                                    xs={12}
                                    item
                                    container
                                    sx={{
                                        marginBottom: '10px',
                                        height: getHeight(),
                                        overflowY: 'auto',
                                    }}
                                >
                                    {isModalOpen &&
                                        lineItemsFilter &&
                                        lineItemsFilter.length > 0 &&
                                        lineItemsFilter.map(
                                            (lineItemElement: string, index: number) => {
                                                const lineItemId = lineItemElement.split('-')[0];
                                                const item = lineItems.find(
                                                    (lineItem: any) =>
                                                        lineItem.line_item_id === lineItemId
                                                );
                                                return (
                                                    <Grid
                                                        container
                                                        key={index}
                                                        sx={{marginTop: '10px'}}
                                                    >
                                                        <Grid item xs={4}>
                                                            <span
                                                                style={{
                                                                    padding: '4px 2px',
                                                                }}
                                                            >
                                                                {item.position}
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <span
                                                                style={{
                                                                    padding: '4px 2px',
                                                                }}
                                                            >
                                                                {Number(item.entry_received) ||
                                                                    Number(item.quantity) ||
                                                                    0}
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth sx={{m: 0}}>
                                                                <OutlinedInput
                                                                    id={`lineItem-${lineItemId}`}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    }
                                                                    type="number"
                                                                    inputProps={{
                                                                        step: '0.01',
                                                                        min: '0',
                                                                        defaultValue: '0',
                                                                    }}
                                                                    onChange={(
                                                                        e: ChangeEvent<HTMLInputElement>
                                                                    ) =>
                                                                        handleAmountChange(
                                                                            lineItemId,
                                                                            e
                                                                        )
                                                                    }
                                                                    value={
                                                                        lineItemAmounts[
                                                                            lineItemId
                                                                        ] || 0
                                                                    }
                                                                    onFocus={(e) =>
                                                                        e.target.select()
                                                                    }
                                                                    sx={{height: '35px'}}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            }
                                        )}
                                </Grid>
                            </Box>
                            <Box>
                                <TypographyStyled500>
                                    {t('modal_load_file_2wm.association_info.subtotal')}: $
                                    {jsonCfdi.SubTotal}
                                </TypographyStyled500>
                                <TypographyStyled500>
                                    {t('modal_load_file_2wm.association_info.assigned')}: $
                                    {totalAssignedAmount}
                                </TypographyStyled500>
                                {paidIsMoreThanSubtotal && (
                                    <TypographyStyled500 sx={{color: 'red'}}>
                                        {t(
                                            'modal_load_file_2wm.association_info.assigned_more_than_subtotal'
                                        )}
                                    </TypographyStyled500>
                                )}
                                {!paidIsMoreThanSubtotal && (
                                    <TypographyStyled500 sx={{color: 'green'}}>
                                        {t(
                                            'modal_load_file_2wm.association_info.pending_to_assign'
                                        )}
                                        : ${Number(jsonCfdi.SubTotal) - totalAssignedAmount}
                                    </TypographyStyled500>
                                )}
                            </Box>
                        </TabPanel>
                    </TabContext>
                    <Box
                        sx={{
                            paddingX: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{display: 'flex', justifySelf: 'flex-start'}}>
                            {hasErrors && (
                                <StatusMessage
                                    message={`${t(
                                        'modal_load_file_2wm.total_errors'
                                    )}: ${errorCount}`}
                                    color="#f50057"
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                height: '60px',
                                alignItems: 'center',
                                justifySelf: 'flex-end',
                            }}
                        >
                            <ButtonCancel
                                sx={{
                                    color: validationOk ? '#D3D3D3' : null,
                                    border: `1.5px solid ${validationOk ? '#D3D3D3' : null}`,
                                }}
                                disabled={validationOk}
                                onClick={() => handleCancelButton()}
                            >
                                {['0', '1'].includes(value)
                                    ? t('cancel').toUpperCase()
                                    : t('back').toUpperCase()}
                            </ButtonCancel>
                            {value === '2' && (
                                <SaveButton
                                    sx={{
                                        padding: '10px',
                                        display: `${validationOk ? 'none' : 'block'}`,
                                    }}
                                    disabled={files.length !== 2 || loading}
                                    onClick={() => validateFile()}
                                >
                                    {t('schedule_payment').toUpperCase()}
                                </SaveButton>
                            )}
                            {value === 'assoc_payments' && (
                                <SaveButton
                                    sx={{
                                        padding: '10px',
                                        display: `${validationOk ? 'none' : 'block'}`,
                                    }}
                                    disabled={
                                        files.length !== 2 ||
                                        loading ||
                                        paidIsMoreThanSubtotal ||
                                        !paymentAssocIsFullfilled
                                    }
                                    onClick={() => setValue('2')}
                                >
                                    {t('continue').toUpperCase()}
                                </SaveButton>
                            )}
                            {value === '0' && previousErrors.length > 0 && (
                                <SaveButton
                                    sx={{
                                        padding: '10px',
                                        display: `${validationOk ? 'none' : 'block'}`,
                                    }}
                                    disabled={loading}
                                    onClick={() => setValue('1')}
                                >
                                    {t('upload_files').toUpperCase()}
                                </SaveButton>
                            )}
                            {validationOk && (
                                <SaveButton
                                    sx={{padding: '10px'}}
                                    onClick={() => handleCloseAndReload()}
                                >
                                    {t('close').toUpperCase()}
                                </SaveButton>
                            )}
                        </Box>
                    </Box>
                </form>
            </Box>
        </StyledModal>
    );
};

export default React.memo(Modal2WM);
