import {RootState} from '@/config/store';
import NotificationItem from '@components/notifications/components/NotificationItem';
import {
    fetchGetNotifications,
    fetchPatchNotification,
    fetchPatchNotifications,
    postNotificationFetch,
} from '@components/notifications/redux/actions/notification-action';
import {NotificationItemType} from '@components/notifications/redux/type/notification-type';
import iconNotificationActive from '@images/notification-active.svg';
import iconNotification from '@images/notification.svg';
import {Box, CardContent, Divider, IconButton, Menu, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
const logger = console;
const TinkerBell: React.FC = () => {
    const [isNotification, setIsNotification] = useState(true);
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const notifications = useSelector((state: RootState) => state.notifications.notifications);
    const userType = useSelector((state: RootState) => state.logins.user.type);
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const dispatch = useDispatch();

    const handleNotifications = (event: React.MouseEvent<HTMLElement>) => {
        setIsNotification(!isNotification);
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setIsNotification(!isNotification);
    };
    const readNotification = (id: string) => {
        dispatch(fetchPatchNotification(id, userType, token));
    };
    useEffect(() => {
        if (userType && token) {
            dispatch(fetchGetNotifications(userType, token));
        }
    }, [userType, token]);

    useEffect(() => {
        if (userType) {
            let eventSource: EventSource | null = null;

            const connect = () => {
                eventSource = new EventSource(
                    `${process.env.REACT_APP_BASE_URL}/v2/sse/publish/${userType}`
                );
                eventSource.onmessage = ({data}) => {
                    const dataObj = JSON.parse(data);
                    dispatch(postNotificationFetch(dataObj.data.notification));
                };
                eventSource.onopen = (status) => {
                    logger.log(status);
                };
                eventSource.onerror = (e) => {
                    logger.log(e);
                };
            };
            connect();
            return () => {
                if (eventSource) {
                    eventSource.close();
                }
            };
        }
    }, [userType]);
    return (
        <IconButton color="inherit">
            {notifications.filter(({notification: item}: NotificationItemType) => !item.isRead)
                .length > 0 ? (
                <img src={iconNotificationActive} onClick={handleNotifications} />
            ) : (
                <img src={iconNotification} onClick={handleNotifications} />
            )}
            <Menu
                anchorEl={anchorEl}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Typography
                            gutterBottom
                            component="div"
                            sx={{
                                color: '#2D313F',
                                cursor: 'pointer',
                                fontWeight: '500',
                                lineHeight: '19.5px',
                                fontSize: '21px',
                            }}
                        >
                            {t('topbar.notifications.notification')}
                        </Typography>

                        <Typography
                            gutterBottom
                            component="div"
                            sx={{
                                color: '#00ACFF',
                                cursor: 'pointer',
                                fontWeight: '400',
                                lineHeight: '14.63px',
                                textDecoration: 'underline',
                            }}
                            onClick={() => {
                                dispatch(fetchPatchNotifications(token, userType));
                            }}
                        >
                            {notifications.length > 0
                                ? t('topbar.notifications.check_as_seen')
                                : ''}
                        </Typography>
                    </Box>
                    {notifications.map(({notification: item, id}: NotificationItemType) => (
                        <div key={id}>
                            <NotificationItem
                                key={item.title}
                                title={item.title}
                                date={item.date}
                                description={item.description}
                                id={id}
                                onClick={readNotification}
                                isRead={item.isRead}
                            />
                            <Divider />
                        </div>
                    ))}

                    <Typography
                        sx={{
                            marginTop: '10px',
                            fontSize: '16px',
                            cursor: 'pointer',
                            fontWeight: '600',
                            color: '#00ACFF',
                            textAlign: 'center',
                        }}
                    >
                        {notifications.length > 0
                            ? t('topbar.notifications.show_more')
                            : t('topbar.notifications.no_notifications')}
                    </Typography>
                </CardContent>
            </Menu>
        </IconButton>
    );
};
export default TinkerBell;
