import UsersTable from '@/components/user/UsersTable';
import {UserType} from '@/components/user/interface';
import {FeatureCodeEnum, ROLE_PERMISSIONS_ENUM, UsersEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import RolesTable from '@components/roles/RoleTable';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Box, Tab, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

const Roles: React.FC = () => {
    const {t} = useTranslation();
    const [currentTab, setCurrentTab] = React.useState('1');
    const theme = useTheme();
    const {allowed} = useCheckAbilities();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '27px 23px',
                overflow: 'auto',
            }}
        >
            <Typography
                sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '24px',
                    fontWeight: 400,
                }}
            >
                {t('roles.title')}
            </Typography>
            <TabContext value={currentTab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider', marginTop: '10px'}}>
                    <TabList onChange={handleChange} aria-label="">
                        {allowed(
                            FeatureCodeEnum.ROLE_PERMISSIONS,
                            ROLE_PERMISSIONS_ENUM.SEE_LIST
                        ) && (
                            <Tab
                                label={t('roles.tabs.roles').toUpperCase()}
                                value="1"
                                sx={{textTransform: 'none'}}
                            />
                        )}

                        {allowed(FeatureCodeEnum.USERS, UsersEnum.SEE_LIST) && (
                            <Tab
                                label={t('roles.tabs.internal_user').toUpperCase()}
                                value="2"
                                sx={{textTransform: 'none'}}
                            />
                        )}
                        {allowed(FeatureCodeEnum.USERS, UsersEnum.SEE_LIST) && (
                            <Tab
                                label={t('roles.tabs.vendor_user').toUpperCase()}
                                value="3"
                                sx={{textTransform: 'none'}}
                            />
                        )}
                    </TabList>
                </Box>
                {allowed(FeatureCodeEnum.ROLE_PERMISSIONS, ROLE_PERMISSIONS_ENUM.SEE_LIST) && (
                    <TabPanel value="1">
                        <RolesTable />
                    </TabPanel>
                )}
                {allowed(FeatureCodeEnum.USERS, UsersEnum.SEE_LIST) && (
                    <TabPanel value="2">
                        <UsersTable
                            title={t('roles.panels.internal_user.title')}
                            type={UserType.INTERNAL_USER}
                        />
                    </TabPanel>
                )}
                {allowed(FeatureCodeEnum.USERS, UsersEnum.SEE_LIST) && (
                    <TabPanel value="3">
                        <UsersTable
                            title={t('roles.panels.vendor_user.title')}
                            type={UserType.SUPPLIER}
                        />
                    </TabPanel>
                )}
            </TabContext>
        </Box>
    );
};

export default Roles;
