import {RootState} from '@/config/store';
import {AbilityContext} from '@/context/AbilityContext';
import {CustomsAgentsEnum, FeatureCodeEnum, ROLES} from '@/enums/permissions.enum';
import {
    AnyAbility,
    ExtractSubjectType,
    MongoQuery,
    PureAbility,
    Subject,
    SubjectRawRule,
} from '@casl/ability';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

type MongoQueryType = Record<string, unknown>;
type SubjectRawRuleType = SubjectRawRule<
    string,
    ExtractSubjectType<Subject>,
    MongoQuery<MongoQueryType>
>;

export type AbilitiesProps = SubjectRawRuleType[] | undefined;
export interface CaslAbilitiesProps {
    abilities: PureAbility;
}

export const useAbilitiesContext = (): AnyAbility => useContext(AbilityContext);
const endsWith = (mainString: string, searchString: string): boolean => {
    const mainStringLength = mainString.length;
    const searchStringLength = searchString.length;
    if (mainStringLength < searchStringLength) {
        return false;
    }

    // Extract the substring from the main string that has the same length as the search string
    const lastCharacters = mainString.substring(mainStringLength - searchStringLength);

    // Check if the extracted substring matches the search string
    return lastCharacters === searchString;
};
export const useAbilities = (): CaslAbilitiesProps => {
    const user = useSelector((state: RootState) => state.logins.user);
    const [abilities, setAabilities] = useState(new PureAbility());

    const updateAbilities = useCallback(
        (rules: AbilitiesProps) => {
            const newAbilities = new PureAbility(rules);
            setAabilities(newAbilities);
        },
        [setAabilities]
    );

    useEffect(() => {
        if (user) {
            const permissions: {action: string; subject: string}[] = [];
            if (user.permissions && user.permissions.length > 0) {
                user.permissions.forEach((permission: string) => {
                    const module = Object.values(FeatureCodeEnum).find((module) => {
                        return endsWith(permission, module);
                    });

                    if (module)
                        permissions.push({
                            action: permission,
                            subject: module,
                        });
                });
                if (
                    user.rolesAssign.filter((role: string) =>
                        role.toLowerCase().includes(ROLES.INTER_USER.toLowerCase())
                    ).length > 0
                )
                    permissions.push({
                        action: CustomsAgentsEnum.FILTER_AA,
                        subject: FeatureCodeEnum.CUSTOMS_AGENTS,
                    });
                updateAbilities(permissions);
                if (
                    user.rolesAssign.filter((role: string) => role.toLowerCase().includes('admin'))
                        .length > 0
                ) {
                    updateAbilities([
                        {
                            action: 'manage',
                            subject: 'all',
                        },
                    ]);
                }
            }
        }
    }, [user]);

    return {abilities};
};
