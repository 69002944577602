import {saveLogAction} from '@/services/route-interceptor/log-action.service';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
const modulesEs = {
    auth: 'Autenticación',
    taxRequirements: 'Requisitos Fiscales',
    urls: 'Url',
    binnacles: 'Bitacora',
    perfil: 'Perfil',
    legalAgreements: 'Acuerdos Legales',
    manuals: 'Manuales',
    faqs: 'Preguntas frecuentes',
    roles: 'Roles',
    consultpo: 'Consultar Ordenes de Compra',
    confirmposimply: 'Confirmar Ordenes de Compra',
    poagents: 'Agentes aduanales',
    legalagreementsAdmin: 'Acuerdos Legales',
    csf: 'Constancia de Situación Fiscal',
    account: 'cuenta',
};
// Componente de interceptor de rutas
const RouteInterceptor = ({children, pathname = '', action = ''}) => {
    const location = useLocation();
    const token = useSelector((state) => state.logins.accessToken);

    useEffect(() => {
        if (!token) return;
        let key = !location.pathname.includes('configuration')
            ? location.pathname.split('/')[1]
            : pathname;
        const module = modulesEs[key];
        if (module) saveLogAction({module, action}, token);
    }, [location.pathname, pathname, token]); // Ejecutar useEffect solo cuando la ruta cambie
    if (!children) return null;
    // No renderiza nada en el DOM, ya que es un componente de interceptor
    return <>{children}</>;
};

export default RouteInterceptor;
