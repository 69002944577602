import {
    DELETE_ROLES,
    DELETE_ROLES_ERROR,
    DELETE_ROLES_SUCCESS,
    GET_MODULES,
    GET_MODULES_ERROR,
    GET_MODULES_SUCCESS,
    GET_PERMISSIONS,
    GET_PERMISSIONS_ERROR,
    GET_PERMISSIONS_SUCCESS,
    GET_ROLE_BYID,
    GET_ROLE_BYID_ERROR,
    GET_ROLE_BYID_SUCCESS,
    GET_ROLES,
    GET_ROLES_ERROR,
    GET_ROLES_SUCCESS,
    POST_ROLES,
    POST_ROLES_ERROR,
    POST_ROLES_SUCCESS,
    UPDATE_ROLE_STATUS,
    UPDATE_ROLE_STATUS_ERROR,
    UPDATE_ROLES,
    UPDATE_ROLES_ERROR,
    UPDATE_ROLES_SUCCESS,
} from '@/components/roles/redux/types/RolesTypes';
import axios from 'axios';

import {IRole} from '@/components/roles/interface';

export const getModules = () => {
    return {
        type: GET_MODULES,
    };
};
export const getModulesSuccess = (result: boolean) => {
    return {
        type: GET_MODULES_SUCCESS,
        value: result,
    };
};
export const getModulesError = (error: boolean, result?: any) => {
    return {
        type: GET_MODULES_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGeModules(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(getModules());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/roles/modules/permissions`,
                {headers}
            );
            dispatch(getModulesSuccess(response.data));
        } catch (error) {
            dispatch(getModulesError(true));
        }
    };
}

export const getPermissions = () => {
    return {
        type: GET_PERMISSIONS,
    };
};
export const getPermissionsSuccess = (result: boolean) => {
    return {
        type: GET_PERMISSIONS_SUCCESS,
        value: result,
    };
};
export const getPermissionsError = (error: boolean, result?: any) => {
    return {
        type: GET_PERMISSIONS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetPermissions(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(getPermissions());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/roles/modules/permissions`,
                {headers}
            );
            dispatch(getPermissionsSuccess(response.data));
        } catch (error) {
            dispatch(getPermissionsError(true));
        }
    };
}

export const getRoles = () => {
    return {
        type: GET_ROLES,
    };
};
export const getRolesSuccess = (result: any) => {
    return {
        type: GET_ROLES_SUCCESS,
        value: result,
    };
};
export const getRolesError = (error: boolean, result?: any) => {
    return {
        type: GET_ROLES_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetRoles(token: string, page: number, limit: number) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const url =
        limit === 0
            ? `${process.env.REACT_APP_BASE_URL}/v2/roles?isCatalogue=1`
            : `${process.env.REACT_APP_BASE_URL}/v2/roles?limit=${limit}&page=${page}&sortOrder=DESC`;
    return async (dispatch: any) => {
        dispatch(getRoles());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getRolesSuccess(response.data));
        } catch (error) {
            dispatch(getRolesError(true, error));
        }
    };
}

export const postRoles = () => {
    return {
        type: POST_ROLES,
    };
};
export const postRolesSuccess = (result: boolean) => {
    return {
        type: POST_ROLES_SUCCESS,
        value: result,
    };
};
export const postRolesError = (error: boolean, result?: any) => {
    return {
        type: POST_ROLES_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostRoles(token: string, data: IRole) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(postRoles());
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v2/roles`, data, {
                headers,
            });
            dispatch(postRolesSuccess(response.data.data));
        } catch (error) {
            dispatch(postRolesError(true));
        }
    };
}

export const putRoles = () => {
    return {
        type: UPDATE_ROLES,
    };
};
export const putRolesSuccess = (result: boolean) => {
    return {
        type: UPDATE_ROLES_SUCCESS,
        value: result,
    };
};
export const putRolesError = (error: boolean, result?: any) => {
    return {
        type: UPDATE_ROLES_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutRoles(token: string, role: IRole) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(putRoles());
        const {id, ...data} = role;
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/roles/${id}`,
                data,
                {headers}
            );
            dispatch(putRolesSuccess(response.data.data));
        } catch (error) {
            dispatch(putRolesError(true));
        }
    };
}

export const putRoleStatus = () => {
    return {
        type: UPDATE_ROLE_STATUS,
    };
};
export const putRoleStatusSuccess = (result: boolean) => {
    return {
        type: UPDATE_ROLES_SUCCESS,
        value: result,
    };
};
export const putRoleStatusError = (error: boolean, result?: any) => {
    return {
        type: UPDATE_ROLE_STATUS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutStatusRole(token: string, role: IRole) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(putRoleStatus());
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/v2/roles/${role.id}`,
                {enabled: role.enabled},
                {headers}
            );
            dispatch(putRoleStatusSuccess(response.data.data));
        } catch (error) {
            dispatch(putRoleStatusError(true));
        }
    };
}

export const deleteRoles = () => {
    return {
        type: DELETE_ROLES,
    };
};
export const deleteRolesSuccess = (result: boolean) => {
    return {
        type: DELETE_ROLES_SUCCESS,
        value: result,
    };
};
export const deleteRolesError = (error: boolean, result?: any) => {
    return {
        type: DELETE_ROLES_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchDeleteRoles(token: string, rolId: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(deleteRoles());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/v2/roles/${rolId}`,
                {headers}
            );
            dispatch(deleteRolesSuccess(response.data.data));
        } catch (error) {
            dispatch(deleteRolesError(true));
        }
    };
}

export const getRoleById = () => {
    return {
        type: GET_ROLE_BYID,
    };
};
export const getRolesByIdSuccess = (result: any) => {
    return {
        type: GET_ROLE_BYID_SUCCESS,
        value: result,
    };
};
export const getRoleByIdError = (error: boolean, result?: any) => {
    return {
        type: GET_ROLE_BYID_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetRoleById(token: string, rolId: string) {
    const headers = {
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(getRoleById());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/roles/${rolId}`,
                {headers}
            );
            dispatch(getRolesByIdSuccess(response.data));
        } catch (error) {
            dispatch(getRoleByIdError(true));
        }
    };
}
