import axios from 'axios';

import {IModeloramaOrderFilter} from '@/components/modelorama/redux/reducers/ModeloramaReducers';
import {
    CREATE_FILE_XML_MODELORAMA,
    CREATE_FILE_XML_MODELORAMA_ERROR,
    CREATE_FILE_XML_MODELORAMA_SUCCESS,
    GET_MODELORAMA_BUSSINES,
    GET_MODELORAMA_BUSSINES_ERROR,
    GET_MODELORAMA_BUSSINES_ORDERS_ERROR,
    GET_MODELORAMA_BUSSINES_ORDERS_SUCCESS,
    GET_MODELORAMA_BUSSINES_SUCCESS,
    GET_MODELORAMA_ORDERS_BUSSINES,
    GET_MODELORAMA_ORDERS_FILTERS,
    SET_FILE_UPLOAD_STATUS,
} from '@/components/modelorama/redux/types/Modelorama';
import StringUtils from '@/utils/StringUtils';

export const ErrorType = {
    AUTH: 'AUTH',
    NETWORK: 'NETWORK',
    NONE: 'NONE',
    RULE: 'RULE',
    SYSTEM: 'SYSTEM',
};

export const getModeloramaBussines = () => {
    return {
        type: GET_MODELORAMA_BUSSINES,
    };
};
export const getModeloramaBussinesSuccess = (result: any) => {
    return {
        type: GET_MODELORAMA_BUSSINES_SUCCESS,
        value: result,
    };
};
export const getModeloramaBussinesError = (error: boolean, result?: any) => {
    return {
        type: GET_MODELORAMA_BUSSINES_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetModeloramaBussines(
    token: string,
    page: number,
    limit: number,
    businessNumber?: string
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params: any = {
        page,
        limit,
    };
    if (businessNumber) {
        params['businessNumber'] = businessNumber;
    }
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: any) => {
        dispatch(getModeloramaBussines());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/contracts${queryString}`,
                {headers}
            );
            dispatch(getModeloramaBussinesSuccess(response.data));
        } catch (error) {
            dispatch(getModeloramaBussinesError(true));
        }
    };
}

export const getModeloramaOrdersBussines = () => {
    return {
        type: GET_MODELORAMA_ORDERS_BUSSINES,
    };
};
export const getModeloramaOrdersBussinesSuccess = (result: any) => {
    return {
        type: GET_MODELORAMA_BUSSINES_ORDERS_SUCCESS,
        value: result,
    };
};
export const getModeloramaOrdersBussinesError = (error: boolean, result?: any) => {
    return {
        type: GET_MODELORAMA_BUSSINES_ORDERS_ERROR,
        value: error,
        payload: result,
    };
};

export const postOrderFilters = (result: any) => {
    return {
        type: GET_MODELORAMA_ORDERS_FILTERS,
        value: result,
    };
};
export function fetchGetModeloramaOrders(
    token: string,
    bussinesId: string,
    page: number,
    limit: number,
    filters: IModeloramaOrderFilter
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    let params = null;
    if (Object.keys(filters).length === 0) {
        params = {
            page,
            limit,
        };
    } else {
        params = {
            page,
            limit,
            referenceId: filters.orderId,
        };
    }
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: any) => {
        dispatch(getModeloramaOrdersBussines());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/contract/${bussinesId}${queryString}`,
                {headers}
            );
            dispatch(getModeloramaOrdersBussinesSuccess(response.data));
        } catch (error) {
            dispatch(getModeloramaOrdersBussinesError(true));
        }
    };
}

export const postXmlFileModelorama = () => {
    return {
        type: CREATE_FILE_XML_MODELORAMA,
    };
};

export const postXMLFileModeloramaSuccess = (result: any) => {
    return {
        type: CREATE_FILE_XML_MODELORAMA_SUCCESS,
        value: result,
    };
};

export const setFileUploadStatus = (result: any) => {
    return {
        type: SET_FILE_UPLOAD_STATUS,
        value: result,
    };
};

export const postXmlFileModeloramaError = (error: any, result: any) => {
    return {
        type: CREATE_FILE_XML_MODELORAMA_ERROR,
        value: error,
        payload: result,
    };
};
export function fetchPostFileXml3wm(
    token: string,
    data: any,
    purchaseOrderId: string,
    entrySheets: any
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const formData = new FormData();
    formData.append('xml', data.xml);
    formData.append('pdf', data.pdf);
    formData.append('lineItems', JSON.stringify(entrySheets));
    return async (dispatch: any) => {
        dispatch(postXmlFileModelorama());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/${purchaseOrderId}/upload-bill/modelorama/validate-xml`,
                formData,
                {headers}
            );
            if (response) {
                dispatch(
                    postXMLFileModeloramaSuccess({...response.data?.data, error: ErrorType.NONE})
                );
            }
        } catch (error: any) {
            if (['ERR_CONNECTION_REFUSED', 'ERR_NETWORK'].includes(error.code)) {
                dispatch(
                    postXMLFileModeloramaSuccess({
                        error: ErrorType.NETWORK,
                        errors: ['network.error'],
                    })
                );
            }

            if (error.response?.data?.error === 'auth.unauthorized') {
                dispatch(
                    postXmlFileModeloramaError(
                        {error: ErrorType.AUTH, errors: ['auth.unauthorized']},
                        null
                    )
                );
            }

            const ruleValidationData = error?.response?.data?.data;

            if (ruleValidationData) {
                dispatch(
                    postXmlFileModeloramaError(
                        {error: ErrorType.AUTH, errors: ruleValidationData.errors},
                        null
                    )
                );
            } else {
                const generalErrorData = error?.response?.data;
                if (generalErrorData) {
                    dispatch(
                        postXmlFileModeloramaError(
                            {
                                errors: [generalErrorData.error],
                                error: ErrorType.SYSTEM,
                            },
                            null
                        )
                    );
                }
            }
        }
    };
}
