/* eslint-disable */
export enum UserType {
    INTERNAL_USER = 'INTERNAL_USER',
    SUPPLIER = 'SUPPLIER',
}

export enum UserStatus {
    ACTIVATED = 'ACTIVATED',
    DISABLED = 'DISABLED',
}

export interface IProfile {
    firstLastName: string;
    secondLastName: string;
}

export interface IUser {
    id: string;
    email: string;
    role: string;
    countryId: number;
    firstLastName: string;
    secondLastName: string;
    profile: IProfile;
    type: UserType;
    roles: any[];
    externalId: string;
    status: UserStatus;
    company?: any;
    country?: any;
}
export interface IFilter {
    type?: UserType;
    search?: string;
    limit?: number;
    page?: number;
}
