import React from 'react';

import {Box, Modal, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {styled} from '@mui/material/styles';
import {Nav, ButtonCancel} from '@/components/faqs/StyledFAQ';
import {useTranslation} from 'react-i18next';

interface ModalProps {
    isModalOpen: any;
    closeModal: any;
}

const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30vw;
    border-radius: 10px;
`;

const ModalAlertOrder: React.FC<ModalProps> = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {isModalOpen, closeModal} = props;

    return (
        <StyledModal open={isModalOpen}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                    width: '30vw',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {t('alert')}
                    </Typography>
                </Nav>
                <Box sx={{padding: '20px'}}>{t('pending_order')}</Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        margin: '20px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonCancel onClick={() => closeModal(false)}>{t('close')}</ButtonCancel>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default React.memo(ModalAlertOrder);
