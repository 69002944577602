import GridTableCell from '@/components/orderSimply/components/GridTableCell';
import {selectChecboxAllowedStatus} from '@/components/orderSimply/utils/constants';
import {
    allowedChecked,
    checkProcessedStatus,
    isPurchaseOrderBillConfirmed,
    isPurchaseOrderBillPartiallyDelivereded,
    isPurchaseOrderBillValidated,
    isPurchaseOrderDelivered,
    OrderStatusEnum,
} from '@/components/orderSimply/utils/orderConfirmHelpers';
import StatusText from '@/components/UI/atoms/buttons/StatusText';
import {TableCell, TableCellIcons} from '@/components/UI/molecules/tables/TablePrincipal';
import {RootState} from '@/config/store';
import {ConfirmDeliveryScheduleInvoiceEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import IconConfirmOrderAction from '@components/UI/atoms/icons/IconConfirmOrderAction.svg';
import ArticleIcon from '@mui/icons-material/Article';
import {Checkbox, Grid, Link, styled, useTheme, Tooltip} from '@mui/material';
import {t} from 'i18next';
import {DateTime} from 'luxon';
import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
export const StyledTableCell = styled(TableCell)({
    fontSize: '9.5px',
    backgroundColor: 'transparent',
});
type CompanyTypeKey = 'FOREIGN' | 'NATIONAL';
export interface IItem {
    //TODO: volver a tipar de acuerdo a lo recibido en el nuevo endpoint
    bill: any;
    billAmount: number;
    billingDate: string;
    billReference: string;
    billValidated: boolean;
    companyGroup: string;
    currency: string;
    entrySheet: string;
    externalId: string;
    iterations: number;
    lineItemId: string;
    message: string;
    orderFindId: string;
    orderId: string;
    paymentDate: string;
    pos: number;
    priceUnit: number;
    productName: string;
    remaining: number;
    societyName: string;
    status: OrderStatusEnum;
    materialType: string;
    orderType: string;
    paymentDetails: string;
    hasEntriesAndBills: boolean;
    bills: any[];
    goodsReceiptReference: string;
}
const orderDownloadRestricted = [OrderStatusEnum.CREATED];

const LineItemRow = ({
    item,
    backgroundColor,
    selectedLineItems,
    handleLineItemSelection,
    handleDownloadOrder,
    showModalDetail,
    showModalEntryDetail,
    poType,
}: {
    item: any;
    backgroundColor: string;
    selectedLineItems: string[];
    handleLineItemSelection: (
        e: React.ChangeEvent<HTMLInputElement>,
        lineItemFilter: string,
        orderFindId: string,
        orderStatus: string,
        society_id: string,
        type: string
    ) => void;
    handleDownloadOrder: (orderFindId: string) => void;
    showModalDetail: (item: IItem) => void;
    showModalEntryDetail: (item: IItem) => void;
    poType: string;
}) => {
    const theme = useTheme();
    const {notAllowed, allowed} = useCheckAbilities();
    const emptyValuePlaceHolder = '---';
    const navigate = useNavigate();
    const websiteType: CompanyTypeKey = useSelector(
        (state: RootState) => state.logins.user.company.websiteType
    );
    const allowedBillStatuses = [
        'CREATED',
        'INVOICE_IN_PROCESS',
        'SCHEDULED_TO_PAY',
        'PAID',
        'CLOSE',
    ];
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // TODO: This should be refactored. Tag: LINE_ITEM_STATUS_TEMPORARY_SOLUTION
        const lineItemFilter = `${String(item.line_item_id)}-${String(item.entry_sheet_number)}`;
        handleLineItemSelection(
            e,
            lineItemFilter,
            item.purchase_order_id,
            item.frontStatus,
            item.society_id,
            item.type
        );
    };

    const handleDownloadClick = () => {
        if (
            allowed(
                FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                ConfirmDeliveryScheduleInvoiceEnum.DOWNLOAD_PO
            )
        ) {
            handleDownloadOrder(item.purchase_order_id);
        }
    };

    const handleEntrySheetClick = () => {
        if (
            allowed(
                FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                ConfirmDeliveryScheduleInvoiceEnum.VIEW_ENTRY_SHEET_DETAILS
            )
        ) {
            showModalDetail(item);
        }
    };

    const handleConfirmClick = () => {
        navigate(`/confirmpoaction/${item.purchase_order_id}/${item.company_group}`);
    };

    const lineItemFilterByPOStatus = isPurchaseOrderDelivered(item.frontStatus)
        ? `${String(item.line_item_id)}-${String(item.entry_sheet_number)}`
        : `${String(item.line_item_id)}-${String(item.entry_sheet_number)}`;

    const notAllowedStatus = !selectChecboxAllowedStatus.includes(item.frontStatus);
    const hasProcessedStatus = checkProcessedStatus(
        OrderStatusEnum[item.frontStatus as OrderStatusEnum]
    );
    const isServiceFo = item.material_type === 'SERVICE' && item.type === 'FO';
    const defaultValidation = notAllowedStatus && !isServiceFo;

    const isDisabled = () => {
        let response = false;
        if (!companyType[websiteType](item.frontStatus)) {
            return true;
        }
        if (defaultValidation) {
            response = true;
        }
        if (isPurchaseOrderBillConfirmed(item.frontStatus)) {
            response = false;
        }
        return response;
    };

    const checked = () => {
        let response = false;
        if (
            isPurchaseOrderDelivered(item.frontStatus) ||
            isPurchaseOrderBillPartiallyDelivereded(item.frontStatus)
        ) {
            response = selectedLineItems.includes(lineItemFilterByPOStatus);
        }

        if (
            isPurchaseOrderBillConfirmed(item.frontStatus) ||
            isPurchaseOrderBillValidated(item.frontStatus)
        ) {
            response = selectedLineItems.includes(lineItemFilterByPOStatus);
        }

        if (allowedChecked(item.status)) {
            response = selectedLineItems.includes(lineItemFilterByPOStatus);
        }

        if (isServiceFo) {
            response = selectedLineItems.includes(lineItemFilterByPOStatus);
        }

        return response;
    };

    const companyType: Record<CompanyTypeKey, (status: OrderStatusEnum) => boolean> = {
        FOREIGN: (status: OrderStatusEnum) => status === OrderStatusEnum.DELIVERED,
        NATIONAL: (status: OrderStatusEnum) => !orderDownloadRestricted.includes(status),
    };

    const calculate2WMAmount = (bills: any) => {
        const invoiceMaps = bills
            .filter((bill: any) => allowedBillStatuses.includes(bill.status))
            .filter((bill: any) => bill.invoice_map?.length > 0)
            .map((bill: any) => bill.invoice_map);

        const amount = invoiceMaps.reduce((acc: number, invoice: any) => {
            return acc + Number(invoice[0]?.amount ?? 0);
        }, 0);

        return amount;
    };

    const itemAmountPaid =
        poType === 'FO'
            ? calculate2WMAmount(item.bills)
            : item.bills[item.bills.length - 1]?.amount;

    return (
        <div
            style={{
                width: '100%',
                backgroundColor: backgroundColor,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'normal',
            }}
        >
            <GridTableCell sx={{backgroundColor: 'transparent'}} xs={1}>
                <Checkbox
                    size="small"
                    onChange={handleCheckboxChange}
                    disabled={isDisabled()}
                    checked={checked()}
                />

                {!orderDownloadRestricted.includes(item.frontStatus) ? (
                    <Link
                        component={'button'}
                        underline="none"
                        style={{cursor: 'pointer', color: theme.palette.secondary.main}}
                        onClick={handleDownloadClick}
                        disabled={notAllowed(
                            FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                            ConfirmDeliveryScheduleInvoiceEnum.DOWNLOAD_PO
                        )}
                    >
                        {item.external_id}
                    </Link>
                ) : (
                    item.external_id
                )}
            </GridTableCell>
            <GridTableCell xs={0.5}>{item.society_id}</GridTableCell>
            <GridTableCell xs={1}>{item.society_name}</GridTableCell>
            <GridTableCell xs={1}>
                {hasProcessedStatus && item.bills[0]?.reference
                    ? item.bills[0]?.reference
                    : emptyValuePlaceHolder}
            </GridTableCell>
            <GridTableCell xs={1}>{item.product_name}</GridTableCell>
            <GridTableCell xs={0.5}>
                {Number(item.unit_price).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
            </GridTableCell>
            <GridTableCell xs={0.5}>{item.currency}</GridTableCell>
            <GridTableCell xs={1}>
                {item.entry_sheet_reference ? item.entry_sheet_reference : emptyValuePlaceHolder}
            </GridTableCell>
            <GridTableCell xs={1}>
                <a
                    style={{cursor: 'pointer', color: theme.palette.secondary.main}}
                    onClick={handleEntrySheetClick}
                >
                    {item.entry_sheet_number}
                </a>
            </GridTableCell>
            <GridTableCell xs={1}>
                {hasProcessedStatus && item.bills[0]?.date
                    ? DateTime.fromISO(item.bills[0]?.date).toFormat('dd/MM/yyyy')
                    : emptyValuePlaceHolder}
            </GridTableCell>
            <GridTableCell xs={0.5}>{item.position}</GridTableCell>
            <GridTableCell xs={0.5}>
                {itemAmountPaid
                    ? Number(itemAmountPaid).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                      })
                    : emptyValuePlaceHolder}
            </GridTableCell>
            <GridTableCell xs={1}>
                {/**
                 * // TODO: This should be refactored in order to have real LineItem Statuses
                 * This scenario assumes that a LineItem can have a processed and success Bill validation,
                 * but the others (if there are) don't.
                 * This is a "temporary" solution in order to show the correct LineItem status in the UI.
                 * Find this tag in the Front and Back repos: LINE_ITEM_STATUS_TEMPORARY_SOLUTION
                 */}

                <StatusText status={item.frontStatus} />
            </GridTableCell>
            <GridTableCell xs={1}>{item.payment_details}</GridTableCell>
            <GridTableCell xs={1}>
                {hasProcessedStatus && item.bills[item.bills.length - 1]?.payment_date
                    ? DateTime.fromISO(item.bills[item.bills.length - 1]?.payment_date).toFormat(
                          'dd/MM/yyyy'
                      )
                    : emptyValuePlaceHolder}
            </GridTableCell>
            <GridTableCell xs={1}>{item.message}</GridTableCell>
            <Grid item xs={0.5} display="grid">
                <TableCellIcons sx={{backgroundColor: 'transparent'}}>
                    {(item.frontStatus === OrderStatusEnum.CREATED ||
                        item.frontStatus === OrderStatusEnum.CONFIRMED) &&
                        item.material_type !== 'SERVICE' &&
                        Number(item.iterations) < 2 && (
                            <img
                                style={{cursor: 'pointer'}}
                                alt="Confirmación de PO"
                                title={t('po_confirmation')}
                                src={IconConfirmOrderAction}
                                onClick={handleConfirmClick}
                            />
                        )}
                    {item.material_type === 'SERVICE' &&
                        item.type === 'FO' &&
                        item.bills.filter((bill: any) => allowedBillStatuses.includes(bill.status))
                            .length > 0 && (
                            <Tooltip title={t('confirmposimply.bill_detail_modal.details_order')}>
                                <ArticleIcon
                                    style={{color: '00ACFF', cursor: 'pointer'}}
                                    onClick={() => showModalEntryDetail(item)}
                                />
                            </Tooltip>
                        )}
                </TableCellIcons>
            </Grid>
        </div>
    );
};

export default LineItemRow;
