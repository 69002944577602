import {Box, CardContent, Typography} from '@mui/material';
import React from 'react';

const bull = (
    <Box component="span" sx={{display: 'inline-block', mx: '2px', transform: 'scale(0.8)'}}>
        •
    </Box>
);
export interface NotificationItemProps {
    title: string;
    date: string;
    description: string;
    isRead: boolean;
    id: string;
    onClick: (id: string) => void;
}
const NotificationItem: React.FC<NotificationItemProps> = ({
    title,
    date,
    description,
    isRead,
    id,
    onClick,
}) => {
    const readMessage = () => {
        onClick(id);
    };
    return (
        <CardContent sx={{maxWidth: 360}} onClick={readMessage}>
            <Box display="flex" justifyContent="space-between">
                <Typography
                    gutterBottom
                    component="div"
                    sx={{
                        width: '65%',
                        color: isRead ? '#2D313F' : '#CD9F28',
                        cursor: 'pointer',
                        fontWeight: '600',
                        lineHeight: '19.5px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {bull} {title}
                </Typography>

                <Typography
                    gutterBottom
                    component="div"
                    sx={{
                        color: '#00ACFF',
                        cursor: 'pointer',
                        fontWeight: '400',
                        lineHeight: '14.63px',
                        width: '35%',
                    }}
                >
                    {date}
                </Typography>
            </Box>

            <Typography variant="body2" color="text.secondary">
                {description}
            </Typography>
        </CardContent>
    );
};

export default NotificationItem;
