import {
    GET_MANUALS,
    GET_MANUALS_SUCCESS,
    GET_MANUALS_ERROR,
    POST_MANUALS,
    POST_MANUALS_SUCCESS,
    POST_MANUALS_ERROR,
    UPDATE_MANUALS,
    UPDATE_MANUALS_SUCCESS,
    UPDATE_MANUALS_ERROR,
    DELETE_MANUALS,
    DELETE_MANUALS_SUCCESS,
    DELETE_MANUALS_ERROR,
    CREATE_FILE_MANUALS,
    CREATE_FILE_MANUALS_SUCCESS,
    CREATE_FILE_MANUALS_ERROR,
    GET_MANUALS_BYID,
    GET_MANUALS_BYID_SUCCESS,
    GET_MANUALS_BYID_ERROR,
    CLOSE_MODAL_PREVIEW,
    OPEN_MODAL_PREVIEW,
} from '@/components/manuals/redux/types/ManualsTypes';

import axios from 'axios';
export const getManuals = () => {
    return {
        type: GET_MANUALS,
    };
};
export const getManualsSuccess = (result) => {
    return {
        type: GET_MANUALS_SUCCESS,
        value: result,
    };
};
export const getManualsError = (error, result) => {
    return {
        type: GET_MANUALS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetManuals(token, page, limit) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getManuals());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/manuals?limit=${limit}&page=${page}&sortOrder=DESC`,
                {headers}
            );
            dispatch(getManualsSuccess(response.data));
        } catch (error) {
            dispatch(getManualsError(true));
        }
    };
}

export const postFileManuals = () => {
    return {
        type: CREATE_FILE_MANUALS,
    };
};
export const postFileManualsSuccess = (result) => {
    return {
        type: CREATE_FILE_MANUALS_SUCCESS,
        value: result,
    };
};
export const postFileManualsError = (error, result) => {
    return {
        type: CREATE_FILE_MANUALS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostFileManuals(token, URL, data) {
    return async (dispatch) => {
        dispatch(postFileManuals());
        try {
            const response = await axios.put(`${URL}`, data, {
                headers: {'Content-Type': data.type},
            });
            dispatch(postFileManualsSuccess(response.data.data));
        } catch (error) {
            dispatch(postFileManualsError(true));
        }
    };
}

export const postManuals = () => {
    return {
        type: POST_MANUALS,
    };
};
export const postManualsSuccess = (result) => {
    return {
        type: POST_MANUALS_SUCCESS,
        value: result,
    };
};
export const postManualsError = (error, result) => {
    return {
        type: POST_MANUALS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostManuals(token, data, dataform, file) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(postManuals());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/manuals`,
                data,
                {
                    headers,
                }
            );
            dispatch(postManualsSuccess(response.data.data));
            dispatch(fetchPostFileManuals(token, response.data.data.url, file));
            dispatch(postManualsError(false));
        } catch (error) {
            dispatch(postManualsError(true));
        }
    };
}

export const putManuals = () => {
    return {
        type: UPDATE_MANUALS,
    };
};
export const putManualsSuccess = (result) => {
    return {
        type: UPDATE_MANUALS_SUCCESS,
        value: result,
    };
};
export const putManualsError = (error, result) => {
    return {
        type: UPDATE_MANUALS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutManuals(manualId, token, data, file) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(putManuals());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/manuals/${manualId}`,
                data,
                {headers}
            );
            dispatch(putManualsSuccess(response.data.data));
            if (file !== '') {
                dispatch(fetchPostFileManuals(token, response.data.data.url, file));
            }
            dispatch(putManualsError(false));
        } catch (error) {
            dispatch(putManualsError(true));
        }
    };
}

export const deleteManuals = () => {
    return {
        type: DELETE_MANUALS,
    };
};
export const deleteManualsSuccess = (result) => {
    return {
        type: DELETE_MANUALS_SUCCESS,
        value: result,
    };
};
export const deleteManualsError = (error, result) => {
    return {
        type: DELETE_MANUALS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchDeleteManuals(manualId, token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(deleteManuals());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/v2/manuals/${manualId}`,
                {headers}
            );
            dispatch(deleteManualsSuccess(response.data.data));
            dispatch(deleteManualsError(false));
        } catch (error) {
            dispatch(deleteManualsError(true));
        }
    };
}
export const getMediaType = (Mediatype, src) => {
    return {
        type: OPEN_MODAL_PREVIEW,
        Mediatype: Mediatype,
        src: src,
    };
};
export const getCloseMediaType = () => {
    return {
        type: CLOSE_MODAL_PREVIEW,
    };
};
export const getManualsByid = () => {
    return {
        type: GET_MANUALS_BYID,
    };
};
export const getManualsByidSuccess = (result) => {
    return {
        type: GET_MANUALS_BYID_SUCCESS,
        value: result,
    };
};
export const getManualsByidError = (error, result) => {
    return {
        type: GET_MANUALS_BYID_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetManualsByid(token, manualId, type) {
    const headers = {
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getManualsByid());
        try {
            const URL =
                type === 1
                    ? `${process.env.REACT_APP_BASE_URL}/v2/manuals/${manualId}/?view=true`
                    : `${process.env.REACT_APP_BASE_URL}/v2/manuals/${manualId}`;
            const response = await axios.get(URL, {headers});

            dispatch(getManualsByidSuccess(response.data));
            if (type === 1) {
                dispatch(getMediaType(response.data.data.type, response.data.data.urlManual));
            } else {
                event.preventDefault(); // Evitar que el enlace abra la URL directamente

                const manualUrl = response.data.data.urlManual;
                const downloadLink = document.createElement('a');
                downloadLink.href = manualUrl;
                downloadLink.download = 'nombre_del_archivo'; // Nombre del archivo a descargar
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            dispatch(getManualsByidError(true));
        }
    };
}

export function fetchGetManualsByidInfo(token, manualId) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getManualsByid());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/manuals/${manualId}`,
                {headers}
            );
            const {id, mediaType, fileName, createdAt, extension} = response.data.data.attachment;
            const formattedResponse = {
                id: id,
                type: mediaType,
                name: fileName,
                createdAt,
                extension,
                size: -1,
            };
            dispatch(getManualsByidSuccess(formattedResponse));
        } catch (error) {
            dispatch(getManualsByidError(true));
        }
    };
}
