import {AddButton, SaveButton} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import IconAlertDelete from '@/components/UI/atoms/icons/IconAlertDelete.svg';
import IconDelete from '@/components/UI/atoms/icons/IconDelete.svg';
import IconEdit from '@/components/UI/atoms/icons/IconEdit.svg';
import SuccessUpdateModal from '@/components/UI/molecules/modals/SuccessUpdateModal';
import WarningUpdateModal from '@/components/UI/molecules/modals/WarningUpdateModal';
import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow'; // Asegúrate de proporcionar la ruta correcta
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableCellIcons,
    TableContainer,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {Can} from '@/context/AbilityContext';
import {FeatureCodeEnum, ManualsEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import useConfigurationUrl from '@/hooks/useConfigurationUrl';
import {getCountryId, getCountryName} from '@/utils/utils';
import {ButtonCancel} from '@components/UI/atoms/buttons/ButtonAlternative';
import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {ACLObj} from '@components/auth/guard/AclGuard';
import {InputLabelRol, StyledSelect} from '@components/faqs/StyledFAQ';
import {
    StyledFileLabelButton,
    StyledInput,
    StyledLabelFileUpload,
} from '@components/legalAgreements/StyledLegalAgreements';
import IconDownload from '@components/manuals/images/IconDownload.svg';
import {fetchGetRoles} from '@components/roles/redux/actions/RolesActions';
import store, {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import MediaComponent from '@manuals/MediaComponent';
import {
    fetchDeleteManuals,
    fetchGetManuals,
    fetchGetManualsByid,
    fetchGetManualsByidInfo,
    fetchPostManuals,
    fetchPutManuals,
    getCloseMediaType,
} from '@manuals/redux/actions/ManualsActions';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    Checkbox,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    Typography,
    useTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import {styled} from '@mui/system';
import {DateTime} from 'luxon';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';

const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40vw;
    border-radius: 10px;
`;

const Loader = styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px; /* Ajusta según el tamaño del CircularProgress */
    margin-left: -12px; /* Ajusta según el tamaño del CircularProgress */
`;

const FileUploadContainer = styled('div')({
    border: '1px solid #c5d1d8',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    width: '614px',
});
const ImgIcon = styled('img')`
    height: 40px;
    margin-bottom: 30px;
`;

export const Nav = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.primary.main, // Usando color principal del tema
    height: '59px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%',
    color: '#FFF',
    borderRadius: '3px 3px 0 0',
}));
const MAX_FILE_SIZE_MB = 10;
const ALLOWED_FILE_TYPES = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/quicktime',
    'text/xml',
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
interface IForm {
    roles: string[];
    countries: number[];
    name: string;
    type: string;
    description: string;
    file: File | null;
}
interface ITable {
    id: string;
    name: string;
    type: string;
    description: string;
    updatedAt: string;
    fileSize: string;
    roles: IRole[];
}

export interface IRole {
    id: string;
    name: string;
    description: string;
    enabled: boolean;
}
interface ICountry {
    id: number;
    countryName: string;
    countryId: string;
    status: boolean;
    createdAt: Date;
}
const Manuals: React.FC & {acl?: ACLObj} = () => {
    const {t} = useTranslation();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const manuals = useSelector((state: RootState) => state.manuals.manuals);
    const currentPage = useSelector((state: RootState) => state.manuals.page);
    const totalPages = useSelector((state: RootState) => state.manuals.totalPages);
    const loading = useSelector((state: RootState) => state.manuals.loading);
    const countryCatalog = useSelector((state: RootState) => state.country.country);
    const {showModalPreview, mediaType, mediaSrc} = useSelector(
        (state: RootState) => state.manuals
    );
    const limit = useSelector((state: RootState) => state.manuals.limit);
    const error = useSelector((state: RootState) => state.manuals.error);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isModalOpenDelete, setModalOpenDelete] = useState<boolean>(false);
    const [manualId, setManualId] = useState<string>('');
    const [manual, setManual] = useState<string>('');
    const [isAdd, setisAdd] = useState<number>(0);
    const [method, setMethod] = useState<string>('');
    const [inputRole, setInputRole] = useState<string[]>([]);
    const [inputCountry, setInputCountry] = useState<string[]>([]);
    const [errorFile, setErrorFile] = useState<string>('');
    const [flag, setFlag] = useState<boolean>(false);
    const [responseValidation, setResponseValidation] = useState<boolean>(false);
    const [modalOpen, setModalCatchResult] = useState<boolean>(false);
    const [checkAllCountry, setCheckAllCountry] = useState(false);
    const [checkAllRole, setCheckAllRole] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();
    const hasConfiguration = useConfigurationUrl();
    const {notAllowed} = useCheckAbilities();
    const roles = useSelector((state: RootState) => state.rolesManager.roles);

    const schemaFormModal = yup.object({
        roles: yup
            .array()
            .min(1, t('manuals.modals.errors.at_least_one_role'))
            .of(yup.string())
            .required(t('manuals.modals.errors.role_required')),
        countries: yup
            .array()
            .min(1, t('manuals.modals.errors.at_least_one_country'))
            .of(yup.number())
            .required(t('manuals.modals.errors.country_required')),
        name: yup
            .string()
            .required(t('manuals.modals.errors.name_required'))
            .max(90, t('manuals.modals.errors.name_max_length')),
        type: yup.string().required(t('manuals.modals.errors.type_required')),
        description: yup
            .string()
            .required(t('manuals.modals.errors.description_req'))
            .max(256, t('manuals.modals.errors.content_max_length')),
        file: yup.mixed().required(t('manuals.modals.errors.file_required')),
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        setValue,
    } = useForm<IForm>({resolver: yupResolver(schemaFormModal), mode: 'onChange'});
    const onSubmitDelete = async () => {
        try {
            if (isAdd === 3) {
                await dispatch(fetchDeleteManuals(manualId, token));
            }
            reset({
                name: '',
                type: '',
                description: '',
                file: null,
            });
            closeModalDelete();
        } catch (error) {
            // Código para ejecutar si la acción falla
            // Puedes agregar lógica adicional en caso de error
        } finally {
            dispatch(fetchGetManuals(token, currentPage, limit));
            setFlag((prev) => !prev);
        }
    };

    const onSubmit = async (data: IForm) => {
        if (errorFile === '') {
            const formData = new FormData();
            if (data.file && data.file.size !== -1) {
                formData.append('attachment[name]', data.name);
                formData.append('attachment[fileName]', data.file.name);
                formData.append('attachment[mediaType]', data.file.type);
                formData.append('attachment[extension]', data.file.type.split('/')[1]);
                formData.append('attachment[size]', data.file.size.toString());
                // Agregar el archivo al FormData
                formData.append('attachment[file]', data.file);
            }
            const json = {
                name: data.name,
                type: data.type,
                description: data.description,
                ...(data?.file?.size !== -1 && {
                    fileSize: data?.file?.size.toString(),
                    attachment: {
                        name: data?.file?.name,
                        fileName: data?.file?.name,
                        mediaType: data?.file?.type,
                        extension: data?.file?.type.split('/')[1],
                    },
                }),
                countryIds: data.countries,
                roles: data.roles,
            };
            try {
                if (isAdd === 1) {
                    await dispatch(fetchPostManuals(token, json, formData, data.file));
                }
                if (isAdd === 2) {
                    await dispatch(
                        fetchPutManuals(
                            manualId,
                            token,
                            json,
                            data?.file?.size !== -1 ? data.file : ''
                        )
                    );
                }
                if (isAdd === 3) {
                    await dispatch(fetchDeleteManuals(manualId, token));
                }
                reset({
                    name: '',
                    type: '',
                    description: '',
                    file: null,
                });
                closeModal();
                setInputRole([]);
                setInputCountry([]);
            } catch (error) {
                // Código para ejecutar si la acción falla
                // Puedes agregar lógica adicional en caso de error
            } finally {
                dispatch(fetchGetManuals(token, currentPage, limit));
                setFlag((prev) => !prev);
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetManuals(token, value, limit));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetManuals(token, 1, event.target.value));
    };
    const handleChangeDownload = (value: any, type: number) => {
        dispatch(fetchGetManualsByid(token, value, type));
    };
    const bytesToMB = (bytes: number) => {
        const megabytes = bytes / (1024 * 1024);
        return megabytes.toFixed(2); // Redondear a dos decimales
    };

    const openModal = () => {
        setModalOpen(true);
    };
    const openModalDelete = () => {
        setModalOpenDelete(true);
    };
    const addModal = () => {
        openModal();
        setisAdd(1);
        setMethod(t('manuals.modals.new'));
        setInputRole([]);
        setInputCountry([]);
        reset({
            name: '',
            type: '',
            description: '',
            file: null,
        });
        setErrorFile('');
    };
    const editModal = (item: any) => {
        store.dispatch(fetchGetManualsByidInfo(token, item.id));
        const rolesMapperIds = item.roles
            ? item.roles.filter((item: IRole) => item.enabled).map((item: IRole) => item.id)
            : [];
        const rolesMapperNames = item.roles
            ? item.roles.filter((item: IRole) => item.enabled).map((item: IRole) => item.name)
            : [];
        openModal();
        setisAdd(2);
        setMethod(t('manuals.modals.edit'));
        setManualId(item.id);
        reset({
            name: item.name,
            type: item.type,
            description: item.description,
            file: null,
        });
        setInputRole(rolesMapperNames);
        setInputCountry(getCountryName(item.countries));
        setValue('roles', rolesMapperIds);
        setValue('countries', getCountryId(item.countries));
        setErrorFile('');
        store.subscribe(() => {
            const state = store.getState();
            // Verifica si la acción que te interesa ha tenido lugar
            if (state.manuals.file !== null && state.manuals.file !== undefined) {
                setValue('file', state.manuals.file);
            }
        });
    };
    const deleteModal = (item: any) => {
        openModalDelete();
        setisAdd(3);
        setMethod(t('manuals.modals.delete'));
        setManual(item.name);
        setManualId(item.id);
    };
    const closeModal = () => {
        setModalOpen(false);
        setModalCatchResult(false);
    };
    const closeModalDelete = () => {
        setModalOpenDelete(false);
    };
    const closeModalMedia = () => {
        dispatch(getCloseMediaType());
    };
    const handleChangeRole = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterRole(value);
    };
    const handleChangeCountry = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterCountry(value);
    };
    const hanldeCheckAllCountry = () => {
        setCheckAllCountry((prev) => {
            cleanFilterCountry(!prev ? countryCatalog.map((item: any) => item.countryName) : []);
            return !prev;
        });
    };
    const hanldeCheckAllRole = () => {
        setCheckAllRole((prev) => {
            cleanFilterRole(
                !prev
                    ? roles.filter((role: IRole) => role.enabled).map((item: IRole) => item.name)
                    : []
            );
            return !prev;
        });
    };

    const cleanFilterCountry = (value: any) => {
        const selectedCountry =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item: string) => !item.includes('notCheck'));
        setInputCountry(selectedCountry);
        let newArray = [];
        if (selectedCountry.length > 0) {
            newArray = selectedCountry.map((item: string) => {
                return countryCatalog.find((e: ICountry) => e.countryName === item);
            });
        }
        setValue(
            'countries',
            newArray.map((item: ICountry) => {
                return item.id;
            })
        );
    };
    const cleanFilterRole = (value: any) => {
        const selectedRole =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item: string) => !item.includes('notCheck'));

        let newArray = [];

        setInputRole(selectedRole);

        if (selectedRole.length > 0) {
            newArray = selectedRole.map((item: string) => {
                return roles.find((e: IRole) => e.name === item);
            });
        }
        setValue(
            'roles',
            newArray.map((item: IRole) => {
                return item.id;
            })
        );
    };

    const handleFileChange = (event: any, type: string) => {
        event.stopPropagation();
        const file = type === 'drop' ? event.dataTransfer.files[0] : event.target.files?.[0];
        if (!file) {
            setErrorFile(`${t('manuals.modals.errors.file_required')}`);
            return;
        }
        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            setErrorFile(t('manuals.modals.errors.invalid_file_type'));
            return;
        } else if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            setErrorFile(`${t('manuals.modals.errors.invalid_file_size')} ${MAX_FILE_SIZE_MB} MB.`);
            return;
        } else {
            setErrorFile('');
        }
    };
    useEffect(() => {
        setCheckAllCountry(inputCountry.length === countryCatalog.length);
    }, [inputCountry]);
    useEffect(() => {
        setCheckAllRole(inputRole.length === roles.filter((role: IRole) => role.enabled).length);
    }, [inputRole]);
    useEffect(() => {
        if (token) {
            dispatch(fetchGetManuals(token, currentPage, limit));
            dispatch(fetchGetRoles(token, 1, 0));
        }
    }, [dispatch, token]);
    useEffect(() => {
        if (!loading && flag) {
            if (error) {
                setResponseValidation(true);
                setModalCatchResult(true);
            } else if (!error) {
                setResponseValidation(false);
                setModalCatchResult(true);
            }
            setFlag((prev) => !prev);
        }
    }, [error, loading]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '20px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('manuals.title')}
                </Typography>
                {hasConfiguration && (
                    <Can I={ManualsEnum.CREATE} a={FeatureCodeEnum.MANUALS}>
                        <AddButton
                            onClick={addModal}
                            disabled={notAllowed(FeatureCodeEnum.MANUALS, ManualsEnum.CREATE)}
                        >
                            {t('manuals.new_manual').toUpperCase()}
                        </AddButton>
                    </Can>
                )}
            </Box>
            <TableContainer
                container
                width={hasConfiguration ? 'calc(100vw - 330px)' : 'calc(100vw - 96px)'}
            >
                <Grid item xs={!hasConfiguration ? 6 : 3} sx={{boxShadow: 0}}>
                    <TableCellHeadInitial> {t('name')}</TableCellHeadInitial>
                </Grid>
                {hasConfiguration ? (
                    <>
                        <Grid item xs={1}>
                            <TableCellHead>{t('type')}</TableCellHead>
                        </Grid>
                        <Grid item xs={2}>
                            <TableCellHead>{t('csf.update_date')}</TableCellHead>
                        </Grid>
                    </>
                ) : null}
                <Grid item xs={!hasConfiguration ? 5 : 4}>
                    <TableCellHead>{t('description')}</TableCellHead>
                </Grid>
                {hasConfiguration ? (
                    <Grid item xs={1}>
                        <TableCellHead>{t('size')}</TableCellHead>
                    </Grid>
                ) : null}

                <Grid item xs={1}>
                    <TableCellHeadFinal>{t('actions')}</TableCellHeadFinal>
                </Grid>
                {loading ? (
                    // Muestra el esqueleto de carga mientras se cargan los datos
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    manuals.map((item: ITable) => (
                        <>
                            <Grid item xs={!hasConfiguration ? 6 : 3} display="grid">
                                <TableCell>
                                    <Typography
                                        sx={{
                                            fontSize: '13px',
                                            fontFamily: theme.typography.fontFamily,
                                            color: '#00ACFF',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                cursor: 'pointer',
                                            },
                                        }}
                                        onClick={() => {
                                            handleChangeDownload(item.id, 1);
                                        }}
                                    >
                                        {item.name}
                                    </Typography>
                                </TableCell>
                            </Grid>
                            {hasConfiguration ? (
                                <>
                                    <Grid item xs={1} display="grid">
                                        <TableCell>{item.type}</TableCell>
                                    </Grid>
                                    <Grid item xs={2} display="grid">
                                        <TableCell>
                                            {DateTime.fromISO(item.updatedAt).toFormat(
                                                'dd/MM/yyyy'
                                            )}
                                        </TableCell>
                                    </Grid>
                                </>
                            ) : null}
                            <Grid item xs={!hasConfiguration ? 5 : 4} display="grid">
                                <TableCell>{item.description}</TableCell>
                            </Grid>
                            {hasConfiguration ? (
                                <Grid item xs={1} display="grid">
                                    <TableCell>{bytesToMB(Number(item.fileSize))} MB</TableCell>
                                </Grid>
                            ) : null}

                            <Grid item xs={1} display="grid">
                                <TableCellIcons>
                                    {' '}
                                    {hasConfiguration && (
                                        <>
                                            <Can I={ManualsEnum.EDIT} a={FeatureCodeEnum.MANUALS}>
                                                <img
                                                    onClick={() => {
                                                        editModal(item);
                                                    }}
                                                    src={IconEdit}
                                                    title={t('manuals.modals.edit')}
                                                />{' '}
                                            </Can>
                                            <Can I={ManualsEnum.DELETE} a={FeatureCodeEnum.MANUALS}>
                                                <img
                                                    onClick={() => {
                                                        deleteModal(item);
                                                    }}
                                                    src={IconDelete}
                                                    title={t('manuals.modals.delete')}
                                                />
                                            </Can>
                                        </>
                                    )}
                                    <img
                                        src={IconDownload}
                                        alt={`${t('download')} Manual`}
                                        onClick={(e) => handleChangeDownload(item.id, 2)}
                                        style={{cursor: 'pointer'}}
                                        title={t('download')}
                                    />
                                </TableCellIcons>
                            </Grid>
                        </>
                    ))
                )}
            </TableContainer>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <StyledModal open={isModalOpen} onClose={closeModal}>
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                    }}
                >
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            {method} manual
                        </Typography>
                    </Nav>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px 10px',
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Box
                            display="flex"
                            sx={{maxWidth: '634px'}}
                            justifyContent="space-between"
                            paddingBottom="10px"
                        >
                            <Controller
                                render={({field, fieldState}) => (
                                    <FormControl sx={{width: '47%'}}>
                                        <InputLabelRol>
                                            {t('manuals.modals.fields.role')}
                                        </InputLabelRol>
                                        <StyledSelect
                                            {...field}
                                            multiple
                                            value={inputRole}
                                            onChange={handleChangeRole}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected: any) => selected.join(', ')}
                                            error={!!fieldState.error}
                                            MenuProps={{
                                                sx: {
                                                    '&& .Mui-selected': {
                                                        backgroundColor: '#FFFF',
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                value={'notCheck'}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        hanldeCheckAllRole();
                                                    }, 500);
                                                }}
                                            >
                                                <Checkbox
                                                    checked={checkAllRole}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: theme.palette.primary.main,
                                                        },
                                                    }}
                                                />
                                                <ListItemText
                                                    primary={t('manuals.modals.fields.select_all')}
                                                />
                                            </MenuItem>
                                            {roles
                                                .filter((item: IRole) => item.enabled)
                                                .map((item: IRole, index: number) => (
                                                    <MenuItem key={index} value={item?.name}>
                                                        <Checkbox
                                                            checked={
                                                                inputRole !== undefined &&
                                                                inputRole.length > 0 &&
                                                                inputRole.indexOf(item.name) > -1
                                                            }
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: theme.palette.primary
                                                                        .main,
                                                                },
                                                            }}
                                                        />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                        </StyledSelect>
                                        {fieldState.error ? (
                                            <FormHelperText error>
                                                {fieldState.error?.message}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                )}
                                control={control}
                                name="roles"
                            />

                            <Controller
                                render={({field, fieldState}) => (
                                    <FormControl sx={{width: '47%'}}>
                                        <InputLabelRol>
                                            {t('manuals.modals.fields.country')}
                                        </InputLabelRol>
                                        <StyledSelect
                                            {...field}
                                            multiple
                                            value={inputCountry}
                                            onChange={handleChangeCountry}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected: any) => selected.join(', ')}
                                            error={!!fieldState.error}
                                            MenuProps={{
                                                sx: {
                                                    '&& .Mui-selected': {
                                                        backgroundColor: '#FFFF',
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                value={'notCheck'}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        hanldeCheckAllCountry();
                                                    }, 500);
                                                }}
                                            >
                                                <Checkbox
                                                    checked={checkAllCountry}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: theme.palette.primary.main,
                                                        },
                                                    }}
                                                />
                                                <ListItemText
                                                    primary={t('manuals.modals.fields.select_all')}
                                                />
                                            </MenuItem>
                                            {countryCatalog.map((item: ICountry, index: number) => (
                                                <MenuItem key={index} value={item.countryName}>
                                                    <Checkbox
                                                        checked={
                                                            inputCountry.indexOf(item.countryName) >
                                                            -1
                                                        }
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: theme.palette.primary.main,
                                                            },
                                                        }}
                                                    />
                                                    <ListItemText primary={item.countryName} />
                                                </MenuItem>
                                            ))}
                                        </StyledSelect>
                                        {fieldState.error ? (
                                            <FormHelperText error>
                                                {fieldState.error?.message}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                )}
                                control={control}
                                name="countries"
                            />
                        </Box>
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={`${t('manuals.modals.fields.name')} *`}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{marginTop: fieldState.error ? '22px' : '0px'}}
                                />
                            )}
                            name="name"
                            control={control}
                        />
                        <Controller
                            name="type"
                            control={control}
                            render={({field, fieldState}) => (
                                <FormControl
                                    fullWidth
                                    sx={{margin: fieldState.error ? '0px' : '7px 0px'}}
                                >
                                    <InputLabel id="demo-simple-select-label">{`${t(
                                        'manuals.modals.fields.type'
                                    )}`}</InputLabel>
                                    <Select
                                        id="demo-simple-select"
                                        label="Motivo de Rechazo"
                                        {...field}
                                        error={!!fieldState.error}
                                    >
                                        <MenuItem value="Imágenes">
                                            {t('manuals.modals.fields.images')}
                                        </MenuItem>
                                        <MenuItem value="Video">Video</MenuItem>
                                        <MenuItem value="PDF">PDF </MenuItem>
                                        <MenuItem value="XML">XML</MenuItem>
                                        <MenuItem value="CSV">CSV </MenuItem>
                                        <MenuItem value="DOCX">DOCX</MenuItem>
                                        <MenuItem value="XLS">XLS</MenuItem>
                                    </Select>
                                    {fieldState.error ? (
                                        <FormHelperText error>
                                            {fieldState.error?.message}
                                        </FormHelperText>
                                    ) : null}
                                </FormControl>
                            )}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    id="outlined-required"
                                    label={`${t('description')} *`}
                                    size="small"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    sx={{margin: fieldState.error ? '0px' : '7px 0px'}}
                                />
                            )}
                            name="description"
                            control={control}
                        />
                        <Controller
                            name="file"
                            control={control}
                            defaultValue={null}
                            render={({field, fieldState}) => (
                                <Box>
                                    <FileUploadContainer
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => {
                                            e.preventDefault();
                                            if (
                                                e.dataTransfer.files &&
                                                e.dataTransfer.files.length > 0
                                            ) {
                                                field.onChange(e.dataTransfer.files[0]);
                                                handleFileChange(e, 'drop');
                                            }
                                        }}
                                        onClick={() => {
                                            const fileInput = document.getElementById('fileInput');
                                            if (fileInput) {
                                                fileInput.click();
                                            }
                                        }}
                                        sx={{
                                            marginTop: fieldState.error ? '0px' : '14px',
                                            borderColor: fieldState.error ? 'red' : 'none',
                                        }}
                                    >
                                        <StyledInput
                                            id="fileInput"
                                            type="file"
                                            onChange={(e) => {
                                                const file = e.target.files
                                                    ? e.target.files[0]
                                                    : null;
                                                if (file) field.onChange(file);
                                                handleFileChange(e, 'change');
                                            }}
                                        />
                                        {field.value && !errorFile ? (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Typography variant="body1" sx={{mr: 1}}>
                                                    {field.value.name}
                                                </Typography>
                                                <IconButton
                                                    aria-label="Eliminar"
                                                    onClick={(e) => {
                                                        field.onChange(null);
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            <Box>
                                                <StyledFileLabelButton variant="outlined" as="span">
                                                    <img src={CloudIcon} />
                                                    <StyledLabelFileUpload>
                                                        {t('drag_upload_files')}
                                                    </StyledLabelFileUpload>
                                                </StyledFileLabelButton>
                                                <Typography variant="caption" color="error">
                                                    {errorFile}
                                                </Typography>
                                            </Box>
                                        )}
                                    </FileUploadContainer>
                                </Box>
                            )}
                        />
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 300,
                                color: 'red',
                                marginBottom: '14px',
                            }}
                        >
                            {errors?.file?.message}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                height: '56px',
                                alignItems: 'center',
                                marginTop: '20px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ButtonCancel onClick={closeModal}>
                                {t('cancel').toUpperCase()}
                            </ButtonCancel>
                            <SaveButton disabled={loading}>
                                {loading ? <Loader size={24} /> : t('save').toUpperCase()}
                            </SaveButton>
                        </Box>
                    </Box>
                </Box>
            </StyledModal>
            <StyledModal open={isModalOpenDelete} onClose={closeModalDelete}>
                <Box
                    sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                    }}
                >
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            {method} manual
                        </Typography>
                    </Nav>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px 10px',
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <ImgIcon src={IconAlertDelete} />
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 400,
                                marginBottom: '14px',
                                color: '#13111a',
                            }}
                        >
                            {`${t('manuals.modals.sure_to_delete')} "${manual}" ?`}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                height: '56px',
                                alignItems: 'center',
                                marginTop: '20px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ButtonCancel onClick={closeModalDelete}>
                                {t('cancel').toUpperCase()}
                            </ButtonCancel>
                            <SaveButton disabled={loading} onClick={onSubmitDelete}>
                                {loading ? <Loader size={24} /> : t('delete').toUpperCase()}
                            </SaveButton>
                        </Box>
                    </Box>
                </Box>
            </StyledModal>

            <StyledModal onClose={closeModalMedia} open={showModalPreview}>
                <Box sx={{width: '50vw'}}>
                    <MediaComponent type={mediaType} src={mediaSrc} />
                </Box>
            </StyledModal>
            {modalOpen && (
                <>
                    {responseValidation ? (
                        <WarningUpdateModal isModalOpen={modalOpen} closeModal={closeModal} />
                    ) : (
                        <SuccessUpdateModal isModalOpen={modalOpen} closeModal={closeModal} />
                    )}
                </>
            )}
        </Box>
    );
};
Manuals.acl = {
    action: ManualsEnum.SEE_LIST,
    subject: FeatureCodeEnum.MANUALS,
};
export default Manuals;
