import {
    GET_DOWNLOAD_DATA_ORDER,
    GET_DOWNLOAD_DATA_ORDER_ERROR,
    GET_DOWNLOAD_DATA_ORDER_SUCCESS,
    GET_DOWNLOAD_ORDERSAGENTS,
    GET_DOWNLOAD_ORDERSAGENTS_ERROR,
    GET_DOWNLOAD_ORDERSAGENTS_SUCCESS,
    GET_DOWNLOAD_ORDERSMODELO,
    GET_DOWNLOAD_ORDERSMODELO_ERROR,
    GET_DOWNLOAD_ORDERSMODELO_SUCCESS,
    GET_DOWNLOAD_ORDERSSIMPLY,
    GET_DOWNLOAD_ORDERSSIMPLY_ERROR,
    GET_DOWNLOAD_ORDERSSIMPLY_SUCCESS,
} from '@/components/orderSimply/redux/types/OrdersSimplyTypes';
import StringUtils from '@utils/StringUtils';
import axios from 'axios';

export const getDownloadOrdersAgents = () => {
    return {
        type: GET_DOWNLOAD_ORDERSAGENTS,
    };
};
export const getDownloadOrdersAgentsSuccess = (result) => {
    return {
        type: GET_DOWNLOAD_ORDERSAGENTS_SUCCESS,
        value: result,
    };
};
export const getDownloadOrdersAgentsError = (error) => {
    return {
        type: GET_DOWNLOAD_ORDERSAGENTS_ERROR,
        value: error,
    };
};

export function fetchGetDownloadOrdersAgents(token, format, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {};
        } else {
            params = {
                initDate: filters.dateIni,
                endDate: filters.dateEnd,
                year: filters.year,
                society: filters.society ?? '',
                pediment: filters.pediment ?? '',
                folio: filters.folio ?? '',
                status: filters.status ?? '',
                aa: filters.aa ?? '',
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getDownloadOrdersAgents());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/customs-agents/download/${format}${queryString}`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `agents.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            dispatch(getDownloadOrdersAgentsSuccess());
        } catch (error) {
            dispatch(getDownloadOrdersAgentsError(true));
        }
    };
}

export const getDownloadOrdersModelo = () => {
    return {
        type: GET_DOWNLOAD_ORDERSMODELO,
    };
};
export const getDownloadOrdersModeloSuccess = (result) => {
    return {
        type: GET_DOWNLOAD_ORDERSMODELO_SUCCESS,
        value: result,
    };
};
export const getDownloadOrdersModeloError = (error) => {
    return {
        type: GET_DOWNLOAD_ORDERSMODELO_ERROR,
        value: error,
    };
};

export function fetchGetDownloadOrdersModelo(token, format, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {};
        } else {
            params = {
                dateIni: filters.dateIni,
                dateEnd: filters.dateEnd,
                year: filters.year,
                status: filters.status,
                modelorama: filters.modelorama,
                referenceId: filters.referenceId,
                contractBusinessNumber: filters?.contractBusinessNumber ?? '',
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getDownloadOrdersModelo());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/download/modelorama/${format}${queryString}`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `modelorama.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            dispatch(getDownloadOrdersModeloSuccess());
        } catch (error) {
            dispatch(getDownloadOrdersModeloError(true));
        }
    };
}

export const getDownloadOrdersSimply = () => {
    return {
        type: GET_DOWNLOAD_ORDERSSIMPLY,
    };
};
export const getDownloadOrdersSimplySuccess = (result) => {
    return {
        type: GET_DOWNLOAD_ORDERSSIMPLY_SUCCESS,
        value: result,
    };
};
export const getDownloadOrdersSimplyError = (error) => {
    return {
        type: GET_DOWNLOAD_ORDERSSIMPLY_ERROR,
        value: error,
    };
};

export function fetchGetDownloadOrdersSimply(token, format, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {};
        } else {
            params = {
                dateIni: filters.dateIni,
                dateEnd: filters.dateEnd,
                status: filters.status ?? '',
                year: filters.year,
                societyId: filters.society ?? '',
                societyName: filters.societyName ?? '',
                referenceId: filters.purchaseOrder ?? '',
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getDownloadOrdersSimply());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/download/${format}${queryString}`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `purchaseorder.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            dispatch(getDownloadOrdersSimplySuccess());
        } catch (error) {
            dispatch(getDownloadOrdersSimplyError(true));
        }
    };
}

export const getDownloadDataOrder = () => {
    return {
        type: GET_DOWNLOAD_DATA_ORDER,
    };
};
export const getDownloadDataOrderSuccess = (result) => {
    return {
        type: GET_DOWNLOAD_DATA_ORDER_SUCCESS,
        value: result,
    };
};
export const getDownloadDataOrderError = (error) => {
    return {
        type: GET_DOWNLOAD_DATA_ORDER_ERROR,
        value: error,
    };
};

export function fetchDownloadOrder(id, token) {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };

    return async (dispatch) => {
        dispatch(getDownloadDataOrder());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/${id}`,
                method: 'GET',
                headers: headers,
            });
            window.open(response.data.data.signedUrl);
            dispatch(getDownloadDataOrderSuccess());
        } catch (error) {
            dispatch(getDownloadDataOrderError(true));
        }
    };
}

export function fetchGetDownloadOrdersDetail(token, format, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {};
        } else {
            params = {
                dateIni: filters.dateIni,
                dateEnd: filters.dateEnd,
                status: filters.status ?? '',
                year: filters.year,
                societyId: filters.society ?? '',
                societyName: filters.societyName ?? '',
                referenceId: filters.purchaseOrder ?? '',
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getDownloadOrdersSimply());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/download/details/${format}${queryString}`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `purchaseorder-details.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            dispatch(getDownloadOrdersSimplySuccess());
        } catch (error) {
            dispatch(getDownloadOrdersSimplyError(true));
        }
    };
}
