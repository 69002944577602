import {
    SET_XML,
    SET_PDF,
    SET_ERROR_FILES,
    SET_ACTIVE_STEP,
    OPEN_DIALOG,
    CLOSE_DIALOG,
    SET_FETCH_BILLS,
    SET_FETCH_BILLS_SUCCESS,
    SET_FETCH_BILLS_ERROR,
    CLEAR_BILLS_ERROR,
    SET_BILLS_ERROR_FLAG,
    CLEAR_BILLS_ERROR_FLAG,
    VALIDATE_COMPLEMENT,
    VALIDATE_COMPLEMENT_SUCCESS,
    VALIDATE_COMPLEMENT_ERROR,
    UPLOAD_FILES,
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_ERROR,
    UPLOAD_PDF_2WM,
    UPLOAD_PDF_2WM_ERROR,
    UPLOAD_PDF_2WM_SUCCESS,
} from '@components/orderSimply/redux/types/LoadComplementTypes';
import axios from 'axios';

export function start2WM() {
    return {
        type: UPLOAD_PDF_2WM,
    };
}

export function error2WM(result) {
    return {
        type: UPLOAD_PDF_2WM_ERROR,
        value: result,
    };
}

export function setResponse2WM(result) {
    return {
        type: UPLOAD_PDF_2WM_SUCCESS,
        value: result,
    };
}

export function setActiveStep(result) {
    return {
        type: SET_ACTIVE_STEP,
        value: result,
    };
}

export function setXml(result) {
    return {
        type: SET_XML,
        value: result,
    };
}

export function setPdf(result) {
    return {
        type: SET_PDF,
        value: result,
    };
}

export function setErrorFiles(result) {
    return {
        type: SET_ERROR_FILES,
        value: result,
    };
}

export function closeDialog() {
    return {
        type: CLOSE_DIALOG,
    };
}

export function openDialog() {
    return {
        type: OPEN_DIALOG,
    };
}

export function setFetchBills() {
    return {
        type: SET_FETCH_BILLS,
    };
}

export function setFetchBillsSuccess(result) {
    return {
        type: SET_FETCH_BILLS_SUCCESS,
        value: result,
    };
}

export function setFetchBillsError(result) {
    return {
        type: SET_FETCH_BILLS_ERROR,
        value: result,
    };
}

export function setBillsErrorFlag(result) {
    return {
        type: SET_BILLS_ERROR_FLAG,
        value: result,
    };
}

export function clearBillsErrorFlag() {
    return {
        type: CLEAR_BILLS_ERROR_FLAG,
    };
}

export function setOpenCompDialog(flag) {
    return (dispatch) => {
        if (flag) {
            dispatch(openDialog());
        } else {
            dispatch(closeDialog());
        }
    };
}

export function verifyComplement(token, xmlFile) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const formData = new FormData();
    formData.append('file', xmlFile);
    return async (dispatch) => {
        dispatch(setXml(xmlFile));
        if (xmlFile && !xmlFile.error) {
            dispatch(setFetchBills());
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/complement/findBills`,
                    formData,
                    {headers}
                );
                if (response) {
                    dispatch(setFetchBillsSuccess({bills: response.data.data}));
                }
            } catch (error) {
                let msg = 'Unexpected error';
                if (error.response?.data?.message) msg = error.response.data.message;
                dispatch(setFetchBillsError(msg));
            }
        }
    };
}

export function validateComplement() {
    return {
        type: VALIDATE_COMPLEMENT,
    };
}

export function validateComplementSuccess() {
    return {
        type: VALIDATE_COMPLEMENT_SUCCESS,
    };
}

export function validateComplementError(result) {
    return {
        type: VALIDATE_COMPLEMENT_ERROR,
        value: result,
    };
}

export function validateXml(token, xmlFile) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const formData = new FormData();
    formData.append('file', xmlFile);
    return async (dispatch) => {
        dispatch(validateComplement());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/complement/validateXml`,
                formData,
                {headers}
            );
            if (response) {
                dispatch(validateComplementSuccess());
            }
        } catch (error) {
            const errors = error.response?.data?.data || [];
            dispatch(validateComplementError(errors));
        }
    };
}

export function uploadFiles() {
    return {
        type: UPLOAD_FILES,
    };
}

export function uploadFilesSuccess() {
    return {
        type: UPLOAD_FILES_SUCCESS,
    };
}

export function uploadFilesError(result) {
    return {
        type: UPLOAD_FILES_ERROR,
        value: result,
    };
}

export function fetchFiles({token, xmlFile, pdfFile}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const formData = new FormData();
    formData.append('xml', xmlFile);
    formData.append('pdf', pdfFile);
    return async (dispatch) => {
        dispatch(uploadFiles());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/complement/uploadFile`,
                formData,
                {headers}
            );
            if (response) dispatch(uploadFilesSuccess());
        } catch (error) {
            const message = error.response?.data?.message || 'Unexpected error';
            dispatch(uploadFilesError(message));
        }
    };
}

export function clearBillsError() {
    return {
        type: CLEAR_BILLS_ERROR,
    };
}

export function sendFile(token, form, id) {
    const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(start2WM());
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/${id}/upload-invoice/national`,
                form,
                {headers}
            );
            if (response) {
                dispatch(setResponse2WM(response.data));
            }
        } catch (error) {
            dispatch(error2WM(error.response.data));
        }
    };
}

export function sendFileExtarnalClient(token, form, id = '') {
    const headers = {
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(start2WM());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/upload-invoice/foreign`,
                form,
                {headers}
            );
            if (response) {
                dispatch(setResponse2WM(response.data));
            }
        } catch (error) {
            dispatch(error2WM());
        }
    };
}
