import {AddButton} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableContainer,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {TextButton, TextError, TextSuccess} from '@/components/UI/molecules/textButton/textButtons';
import UserDeleteModal from '@/components/user/UserDeleteModal';
import UserModal from '@/components/user/UserModal';
import UserModalDetail from '@/components/user/UserModalDetail';
import UserStatusModal from '@/components/user/UserStatusModal';
import {IFilter, IUser, UserStatus, UserType} from '@/components/user/interface';
import {fetchGetUser, fetchGetUsers} from '@/components/user/redux/actions/UserActions';
import {Can} from '@/context/AbilityContext';
import {FeatureCodeEnum, UsersEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {AppDispatch, RootState} from '@config/store';
import {Box, Button, Grid, Menu, MenuItem, TextField, Typography, useTheme} from '@mui/material';

import LineClamp from '@components/UI/molecules/LineClamp';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';

interface IUserTableProps {
    title: string;
    type: UserType;
}
const UsersTable: React.FC<IUserTableProps> = ({...props}) => {
    const {t} = useTranslation();
    const {title, type} = props;
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const users = useSelector((state: RootState) => state.user.users);
    const filtersUser = useSelector((state: RootState) => state.user.filtersUser);
    const filtersSupplier = useSelector((state: RootState) => state.user.filtersSupplier);
    const currentPage = useSelector((state: RootState) => state.user.page);
    const limit = useSelector((state: RootState) => state.user.limit);
    const totalPages = useSelector((state: RootState) => state.user.totalPages);
    const loading = useSelector((state: RootState) => state.user.loading);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isModalStatusOpen, setModalStatusOpen] = useState<boolean>(false);
    const [isModalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
    const [isModalDetailOpen, setModalDetailOpen] = useState<boolean>(false);
    const [method, setMethod] = useState('post');
    const [currentUser, setCurrentUser] = useState<IUser | null>(null);
    const [anchorEls, setAnchorEls] = React.useState<{[key: string]: HTMLElement | null}>({});
    const {allowed} = useCheckAbilities();
    const schemaFormModal = yup.object({
        search: yup.string(),
    });
    const getFilters = () => {
        switch (type) {
            case UserType.INTERNAL_USER:
                return filtersUser;
            case UserType.SUPPLIER:
                return filtersSupplier;
            default:
                return filtersUser;
        }
    };
    const {handleSubmit, control, watch} = useForm<IFilter>({
        resolver: yupResolver(schemaFormModal),
        defaultValues: getFilters(),
    });
    const watchForm = watch();
    const onSubmit = () => {
        const json = {
            search: watchForm.search ?? '',
        };
        dispatch(fetchGetUsers(token, type, currentPage, limit, json));
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, index: string) => {
        setAnchorEls((prevState) => ({
            ...prevState,
            [index]: event.currentTarget,
        }));
    };

    const handleClose = (index: string) => {
        setAnchorEls((prevState) => ({
            ...prevState,
            [index]: null,
        }));
    };
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if (currentPage !== value) dispatch(fetchGetUsers(token, type, value, limit, getFilters()));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetUsers(token, type, 1, event.target.value, getFilters()));
    };

    const openModal = () => {
        setMethod('post');
        setModalOpen(true);
    };

    const getDataUpdated = () => {
        setTimeout(() => {
            dispatch(fetchGetUsers(token, type, currentPage, limit, getFilters()));
        }, 500);
    };

    const closeModal = () => {
        setModalOpen(false);
        getDataUpdated();
    };

    const closeStatusModal = () => {
        setModalStatusOpen(false);
        getDataUpdated();
    };

    const closeDeleteModal = () => {
        setModalDeleteOpen(false);
        getDataUpdated();
    };

    const closeDetailModal = () => {
        setModalDetailOpen(false);
    };

    const addModal = () => {
        openModal();
    };

    const handleEditUser = (item: IUser) => {
        if (token) {
            setMethod('put');
            dispatch(fetchGetUser(token, item.id));
            setModalOpen(true);
        }
        handleClose(item.id);
    };

    const handleDetailUser = (item: IUser) => {
        if (token) {
            dispatch(fetchGetUser(token, item.id));
            setModalDetailOpen(true);
        }
        handleClose(item.id);
    };

    const handleStatusUser = (item: IUser) => {
        if (token) {
            dispatch(fetchGetUser(token, item.id));
            setModalStatusOpen(true);
        }
        handleClose(item.id);
    };

    const handleDeleteUser = (item: IUser) => {
        setCurrentUser(item);
        setModalDeleteOpen(true);
        handleClose(item.id);
    };

    useEffect(() => {
        if (token) {
            dispatch(fetchGetUsers(token, type, currentPage, limit, getFilters()));
        }
    }, [dispatch, token]);

    return (
        <Box>
            <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                marginBottom={'10px'}
                width={'calc(100vw - 350px)'}
            >
                <Typography
                    sx={{
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '16px',
                        fontWeight: 400,
                    }}
                >
                    {title}
                </Typography>
                {type === UserType.INTERNAL_USER &&
                    allowed(FeatureCodeEnum.USERS, UsersEnum.ADD_INTERNAL) && (
                        <AddButton onClick={addModal}>
                            {t('roles.buttons.new_user').toUpperCase()}
                        </AddButton>
                    )}
            </Grid>
            <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                marginBottom={'10px'}
                width={'calc(100vw - 350px)'}
            >
                <Box
                    sx={{
                        display: 'flex',
                        padding: '15px',
                        paddingTop: '0',
                        height: '73px',
                        background: '#fff',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <Box
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                            sx={{width: '100%'}}
                        >
                            <Controller
                                name="search"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('roles.filter.search')}
                                            size="small"
                                            placeholder=""
                                            fullWidth
                                        />
                                    );
                                }}
                            />
                        </Box>
                        <Box>
                            <TextButton type="submit" onClick={onSubmit}>
                                {t('roles.buttons.filter').toUpperCase()}
                            </TextButton>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Box>
                {type === UserType.INTERNAL_USER ? (
                    <TableContainer width={'calc(100vw - 350px)'}>
                        <TableHeadRow container>
                            <Grid item xs={2} sx={{boxShadow: 0}}>
                                <TableCellHeadInitial>
                                    {t('roles.panels.internal_user.table.headers.sharp_id')}
                                </TableCellHeadInitial>
                            </Grid>
                            <Grid item xs={2} sx={{boxShadow: 0}}>
                                <TableCellHead>
                                    {t('roles.panels.internal_user.table.headers.name')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={3} sx={{boxShadow: 0}}>
                                <TableCellHead>
                                    {t('roles.panels.internal_user.table.headers.email')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCellHead>
                                    {t('roles.panels.internal_user.table.headers.roles')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCellHead>
                                    {t('roles.panels.internal_user.table.headers.status')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCellHeadFinal>
                                    {t('roles.panels.internal_user.table.headers.actions')}
                                </TableCellHeadFinal>
                            </Grid>
                        </TableHeadRow>
                        {loading ? (
                            <>
                                <SkeletonRow />
                                <SkeletonRow />
                                <SkeletonRow />
                            </>
                        ) : (
                            users?.map((item: IUser) => (
                                <Grid container key={item.id}>
                                    <Grid item xs={2}>
                                        <TableCell>
                                            {item.externalId ? item.externalId : '-'}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TableCell>
                                            {item.profile
                                                ? `${item.profile?.firstLastName} ${item.profile.secondLastName}`
                                                : '-'}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TableCell>{item.email}</TableCell>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TableCell>
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <LineClamp items={item.roles} />
                                            </div>
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TableCell>
                                            {item.status === UserStatus.ACTIVATED ? (
                                                <TextSuccess sx={{fontSize: '13px'}}>
                                                    {t('roles.status.active')}
                                                </TextSuccess>
                                            ) : (
                                                <TextError sx={{fontSize: '13px'}}>
                                                    {t('roles.status.inactive')}
                                                </TextError>
                                            )}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TableCell>
                                            <Button
                                                id={`basic-button-${item.id}`}
                                                aria-controls={`basic-menu-${item.id}`}
                                                aria-haspopup="true"
                                                onClick={(event) => handleClick(event, item.id)}
                                                color="success"
                                            >
                                                ...
                                            </Button>
                                            <Menu
                                                id={`basic-menu-${item.id}`}
                                                anchorEl={anchorEls[item.id]}
                                                open={Boolean(anchorEls[item.id])}
                                                onClose={() => handleClose(item.id)}
                                                MenuListProps={{
                                                    'aria-labelledby': `basic-button-${item.id}`,
                                                }}
                                            >
                                                <MenuItem onClick={() => handleEditUser(item)}>
                                                    {t('roles.buttons.actions.edit')}
                                                </MenuItem>

                                                <Can
                                                    I={UsersEnum.BLOCK_UNLOCK}
                                                    a={FeatureCodeEnum.USERS}
                                                >
                                                    <MenuItem
                                                        onClick={() => handleStatusUser(item)}
                                                    >
                                                        {item.status === UserStatus.ACTIVATED
                                                            ? t('roles.buttons.actions.deactivate')
                                                            : t('roles.buttons.actions.activate')}
                                                    </MenuItem>
                                                </Can>
                                                {type === UserType.INTERNAL_USER && (
                                                    <Can
                                                        I={UsersEnum.DELETE}
                                                        a={FeatureCodeEnum.USERS}
                                                    >
                                                        <MenuItem
                                                            onClick={() => handleDeleteUser(item)}
                                                            sx={{color: 'red'}}
                                                        >
                                                            {t('roles.buttons.actions.delete')}
                                                        </MenuItem>
                                                    </Can>
                                                )}
                                            </Menu>
                                        </TableCell>
                                    </Grid>
                                </Grid>
                            ))
                        )}
                    </TableContainer>
                ) : (
                    <TableContainer width={'calc(100vw - 350px)'}>
                        <TableHeadRow container>
                            <Grid item xs={1} sx={{boxShadow: 0}}>
                                <TableCellHeadInitial>
                                    {t('roles.panels.internal_user.table.headers.vendor_id')}
                                </TableCellHeadInitial>
                            </Grid>
                            <Grid item xs={2} sx={{boxShadow: 0}}>
                                <TableCellHead>
                                    {t('roles.panels.internal_user.table.headers.user_name')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={2} sx={{boxShadow: 0}}>
                                <TableCellHead>
                                    {t('roles.panels.internal_user.table.headers.company')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCellHead>
                                    {t('roles.panels.internal_user.table.headers.country')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCellHead>
                                    {t('roles.panels.internal_user.table.headers.role')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCellHead>
                                    {t('roles.panels.internal_user.table.headers.status')}
                                </TableCellHead>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCellHeadFinal>
                                    {t('roles.panels.internal_user.table.headers.actions')}
                                </TableCellHeadFinal>
                            </Grid>
                        </TableHeadRow>
                        {loading ? (
                            <>
                                <SkeletonRow />
                                <SkeletonRow />
                                <SkeletonRow />
                            </>
                        ) : (
                            users &&
                            users.map((item: IUser) => (
                                <Grid container key={item.id}>
                                    <Grid item xs={1}>
                                        <TableCell>
                                            {item.externalId ? item.externalId : '-'}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TableCell>
                                            {item.profile
                                                ? `${item.profile?.firstLastName} ${item.profile?.secondLastName}`
                                                : '-'}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TableCell>{item.company?.name}</TableCell>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TableCell>{item.country?.name}</TableCell>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TableCell>
                                            {item.roles.length > 0 ? item.roles[0].name : ''}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TableCell>
                                            {item.status === UserStatus.ACTIVATED ? (
                                                <TextSuccess sx={{fontSize: '13px'}}>
                                                    {t('roles.status.active')}
                                                </TextSuccess>
                                            ) : (
                                                <TextError sx={{fontSize: '13px'}}>
                                                    {t('roles.status.inactive')}
                                                </TextError>
                                            )}
                                        </TableCell>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TableCell>
                                            <Button
                                                id={`basic-button-${item.id}`}
                                                aria-controls={`basic-menu-${item.id}`}
                                                aria-haspopup="true"
                                                onClick={(event) => handleClick(event, item.id)}
                                                color="success"
                                            >
                                                ...
                                            </Button>
                                            <Menu
                                                id={`basic-menu-${item.id}`}
                                                anchorEl={anchorEls[item.id]}
                                                open={Boolean(anchorEls[item.id])}
                                                onClose={() => handleClose(item.id)}
                                                MenuListProps={{
                                                    'aria-labelledby': `basic-button-${item.id}`,
                                                }}
                                            >
                                                <MenuItem onClick={() => handleDetailUser(item)}>
                                                    {t('roles.buttons.actions.detail')}
                                                </MenuItem>

                                                <Can
                                                    I={UsersEnum.BLOCK_UNLOCK}
                                                    a={FeatureCodeEnum.USERS}
                                                >
                                                    <MenuItem
                                                        onClick={() => handleStatusUser(item)}
                                                    >
                                                        {item.status === UserStatus.ACTIVATED
                                                            ? t('roles.buttons.actions.deactivate')
                                                            : t('roles.buttons.actions.activate')}
                                                    </MenuItem>
                                                </Can>
                                            </Menu>
                                        </TableCell>
                                    </Grid>
                                </Grid>
                            ))
                        )}
                    </TableContainer>
                )}
                <CustomPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handleChange={handleChange}
                    limit={limit}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Box>
            <UserModal
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                method={method}
                userType={type}
            />
            <UserStatusModal isModalOpen={isModalStatusOpen} closeModal={closeStatusModal} />
            <UserDeleteModal
                isModalOpen={isModalDeleteOpen}
                closeModal={closeDeleteModal}
                user={currentUser}
            />
            <UserModalDetail isModalOpen={isModalDetailOpen} closeModal={closeDetailModal} />
        </Box>
    );
};

export default UsersTable;
