import GridTableCell from '@/components/orderSimply/components/GridTableCell';
import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableContainerBinnance,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import CustomPagination from '@/components/UI/organisms/pagination/Pagination';
import {AppDispatch, RootState} from '@/config/store';
import {Can} from '@/context/AbilityContext';
import {FeatureCodeEnum, PendingBillsEnum} from '@/enums/permissions.enum';
import {parseDateFormat} from '@/utils/FormatDate';
import {ACLObj} from '@components/auth/guard/AclGuard';
import OrderConfirmStatusModal from '@components/orderConfirm/OrderConfirmStatusModal';
import {fetchGetOrderConfirm} from '@components/orderConfirm/redux/actions/OrderConfirmActions';
import {yupResolver} from '@hookform/resolvers/yup';
import {Search} from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {enUS, es} from 'date-fns/locale';
import {DateTime} from 'luxon';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
export const StyledSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '',
    },
    height: '40px',
    minWidth: '100px',
    width: 'auto',
    marginLeft: '10px',
});

const ExternalOrderConfirm: React.FC & {acl?: ACLObj} = () => {
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const list = useSelector((state: RootState) => state.orderConfirm.list);
    const order = useSelector((state: RootState) => state.orderConfirm.order);
    const currentPage = useSelector((state: RootState) => state.orderConfirm.page);
    const limit = useSelector((state: RootState) => state.orderConfirm.limit);
    const totalPages = useSelector((state: RootState) => state.orderConfirm.totalPages);
    const loading = useSelector((state: RootState) => state.orderConfirm.loading);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [isModalStatusOpen, setModalStatusOpen] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');

    const schemaForm = yup.object({
        initDate: yup.date().required(t('confirmposimply.required_start_date')),
        endDate: yup.date().required('Fecha final requerido'),
        year: yup.string(),
        societyName: yup.string(),
        purchaseOrder: yup.string(),
        society: yup.string(),
        supplierId: yup.string(),
    });

    const {handleSubmit, control, watch, setValue} = useForm({
        resolver: yupResolver(schemaForm),
    });

    const watchForm = watch();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {};

    const handleChangeRowsPerPage = (event: any) => {};

    const onSubmit = () => {
        const json = {
            initDate: watchForm.initDate
                ? parseDateFormat(watchForm.initDate, 'en', '-')
                : parseDateFormat(DateTime.now().startOf('month'), 'en', '-'),
            endDate: watchForm.endDate
                ? parseDateFormat(watchForm.endDate, 'en', '-')
                : parseDateFormat(DateTime.now(), 'en', '-'),
            year: watchForm.year,
            societyId: watchForm.society,
            societyName: watchForm.societyName,
            referenceId: watchForm.purchaseOrder,
            page: 1,
            limit: limit,
        };
        dispatch(fetchGetOrderConfirm(token, json));
    };
    useEffect(() => {
        setValue('initDate', DateTime.now().startOf('month'));
        setValue('endDate', DateTime.now());
        onSubmit();
    }, [dispatch, token]);

    useEffect(() => {
        if (order) {
            onSubmit();
        }
    }, [order]);

    const setShowStatusModal = (item: any, action: string) => {
        setCurrentOrder(item);
        setAction(action);
        setModalStatusOpen(true);
    };

    const closeStatusModal = () => {
        setModalStatusOpen(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '15px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('pending_bills')}
                </Typography>
            </Box>
            <Can I={PendingBillsEnum.FILTER} a={FeatureCodeEnum.PENDING_BILLS}>
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: '20px',
                            padding: '15px',
                            height: '73px',
                            background: '#fff',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Controller
                                name="society"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('confirmposimply.filters.society')}
                                            size="small"
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="societyName"
                                control={control}
                                render={({field: {onChange, value = ''}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('confirmposimply.filters.society_name')}
                                            size="small"
                                            sx={{marginLeft: '10px'}}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="purchaseOrder"
                                control={control}
                                render={({field: {onChange, value = ''}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('consultpo.table.headers.purchase_order')}
                                            size="small"
                                            sx={{
                                                marginLeft: '10px',
                                                width: '160px',
                                                height: '40px',
                                            }}
                                        />
                                    );
                                }}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                                <Controller
                                    name="initDate"
                                    control={control}
                                    defaultValue={DateTime.now().startOf('month').toJSDate()}
                                    render={({field: {onChange, value}}) => (
                                        <DatePicker
                                            label={t('logbook_and_details.start_date')}
                                            value={value}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                            marginLeft: '10px',
                                                        },
                                                    }}
                                                />
                                            )}
                                            maxDate={watchForm.endDate}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                                <Controller
                                    name="endDate"
                                    control={control}
                                    defaultValue={new Date()}
                                    render={({field: {onChange, value}}) => (
                                        <DatePicker
                                            label={t('logbook_and_details.end_date')}
                                            value={value}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            height: '40px',
                                                            marginLeft: '10px',
                                                        },
                                                    }}
                                                />
                                            )}
                                            minDate={watchForm.initDate}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <Controller
                                name="year"
                                control={control}
                                defaultValue={2024}
                                render={({field, fieldState}) => (
                                    <FormControl sx={{marginLeft: '10px', height: '40px'}}>
                                        <InputLabel>
                                            {t('components.year_selector.title')}
                                        </InputLabel>
                                        <Select
                                            label="Motivo de Rechazo"
                                            {...field}
                                            error={!!fieldState.error}
                                            sx={{width: '100px', height: '40px'}}
                                        >
                                            <MenuItem value={2024}>2024</MenuItem>
                                            <MenuItem value={2023}>2023</MenuItem>
                                            <MenuItem value={2022}>2022</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            />

                            <Tooltip
                                title={t('components.filter_btn.title')}
                                placement="bottom"
                                arrow
                            >
                                <Button
                                    type="submit"
                                    aria-label="submit"
                                    color="secondary"
                                    variant="outlined"
                                    sx={{
                                        minWidth: '40px',
                                        height: '36px',
                                        padding: '5px 5px',
                                        marginLeft: '20px',
                                    }}
                                >
                                    <Search />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </Can>
            <TableContainerBinnance container>
                <TableHeadRow container>
                    <Grid item xs={1}>
                        <TableCellHead>{t('consultpo.table.headers.purchase_order')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('confirmposimply.filters.society')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>{t('confirmposimply.filters.society_name')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>
                            {t('confirmposimply.table.headers.material_text_description')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead> {t('unit_price')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('money')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TableCellHead>
                            {t('confirmposimply.table.headers.entry_sheet_number')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>Pos</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead> {t('net_amount')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('file')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TableCellHeadFinal>{t('actions')}</TableCellHeadFinal>
                    </Grid>
                </TableHeadRow>
                {loading && (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                )}
                {!loading &&
                    list &&
                    list
                        .filter((e: any) => e.itemStatus === 'IN_VALIDATION')
                        .map((item: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        width: '100%',
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'normal',
                                    }}
                                >
                                    <GridTableCell xs={1}>{item.orderId}</GridTableCell>
                                    <GridTableCell xs={1}>{item.externalId}</GridTableCell>
                                    <GridTableCell xs={2}>{item.societyName}</GridTableCell>
                                    <GridTableCell xs={2}>{item.productName}</GridTableCell>
                                    <GridTableCell xs={1}>
                                        {item.priceUnit.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                    </GridTableCell>
                                    <GridTableCell xs={1}>{item.currency}</GridTableCell>
                                    <GridTableCell xs={1.5}>{item.entrySheet}</GridTableCell>
                                    <GridTableCell xs={1}>{item.pos}</GridTableCell>
                                    <GridTableCell xs={1}>
                                        {item.billAmount.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            maximumFractionDigits: 2,
                                        })}
                                    </GridTableCell>
                                    <GridTableCell xs={1}>
                                        {item.urls &&
                                            item.urls.map((url: string, index: number) => (
                                                <a
                                                    href={url}
                                                    key={index}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <DescriptionIcon color="info" />
                                                </a>
                                            ))}
                                    </GridTableCell>
                                    <Grid item xs={1.5}>
                                        <TableCell sx={{fontSize: '11px'}}>
                                            <Can
                                                I={PendingBillsEnum.SEE_INVOICES_IN_PROCESS}
                                                a={FeatureCodeEnum.PENDING_BILLS}
                                            >
                                                <IconButton
                                                    color="success"
                                                    onClick={() =>
                                                        setShowStatusModal(
                                                            item,
                                                            'INVOICE_IN_PROCESS'
                                                        )
                                                    }
                                                >
                                                    <ThumbUpAltIcon />
                                                </IconButton>
                                            </Can>
                                            <Can
                                                I={PendingBillsEnum.SEE_INVOICES_REJECTED}
                                                a={FeatureCodeEnum.PENDING_BILLS}
                                            >
                                                <IconButton
                                                    color="warning"
                                                    onClick={() =>
                                                        setShowStatusModal(item, 'REJECTED')
                                                    }
                                                >
                                                    <ThumbDownAltIcon />
                                                </IconButton>
                                            </Can>
                                        </TableCell>
                                    </Grid>
                                </div>
                            );
                        })}
            </TableContainerBinnance>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <OrderConfirmStatusModal
                isModalOpen={isModalStatusOpen}
                closeModal={closeStatusModal}
                order={currentOrder}
                action={action}
            />
        </Box>
    );
};

ExternalOrderConfirm.acl = {
    action: PendingBillsEnum.SEE_LIST,
    subject: FeatureCodeEnum.PENDING_BILLS,
};

export default ExternalOrderConfirm;
