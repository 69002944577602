import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, useWatch, Controller} from 'react-hook-form';

import {
    Box,
    Typography,
    IconButton,
    useTheme,
    TextField,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {DateTime} from 'luxon';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
    MAX_FILE_SIZE_MB,
    ALLOWED_FILE_TYPES,
} from '@/components/orderSimply/utils/fileUploadConfig';
import {processFileName} from '@/components/orderSimply/utils/fileUploadHelpers';

import CloudIcon from '@components/UI/atoms/icons/CloudIcon.svg';

import StatusMessage from '@/components/orderSimply/components/StatusMessage';
import {Nav, StyledModal, ButtonCancel, SaveButton} from '@/components/faqs/StyledFAQ';
import {
    FileUploadContainer,
    StyledFileLabelButton,
    StyledInput,
    StyledLabelFileUpload,
} from '@/components/legalAgreements/StyledLegalAgreements';
import {useTranslation} from 'react-i18next';
import {
    sendFileExtarnalClient,
    error2WM,
} from '@/components/orderSimply/redux/actions/LoadComplementActions';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {RootState} from '@/config/store';
import {ErrorType} from '@/components/orderSimply/utils/format-orders';

interface ModalClientExternalProps {
    closeAndReload?: (event?: {preventDefault: () => void}, reason?: string) => void;
    closeModal: (event?: {preventDefault: () => void}, reason?: string) => void;
    lineItemsFilter?: string[];
    isModalOpen: boolean;
    loading?: boolean;
    purchaseOrderId?: string;
    purchaseOrderStatus?: string;
    type?: string;
}

const parseDate = (date: Date | string | DateTime): DateTime => {
    let dateTime: DateTime = DateTime.now().startOf('month');
    if (date && date instanceof DateTime) dateTime = date;

    if (date && date instanceof Date) dateTime = DateTime.fromJSDate(date);
    if (date && typeof date === 'string') dateTime = DateTime.fromISO(date);

    return dateTime;
};

interface IForm {
    file: File | null;
    invoiceNumber: string;
    indirectCosts: string;
    amount: number;
    currency: string;
    invoiceDate: string;
    freight: number;
    packaging: number;
    insurance: number;
    others: number;
}
const ModalClientExternal: React.FC<ModalClientExternalProps> = ({
    closeAndReload,
    closeModal,
    lineItemsFilter = [],
    isModalOpen,
    loading = false,
    purchaseOrderId = '',
    purchaseOrderStatus = '',
    type = '',
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const orderssimply = useSelector((state: RootState) => state.orderssimply.orderssimply);
    const [value, setValues] = useState<string>('1');
    const [errorFile, setErrorFile] = useState<string>('');
    const [files, setFiles] = useState<File[]>([]);
    const [fileNames, setFileNames] = useState<string[]>([]);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [ranValidations, setRanValidations] = useState<boolean>(false);
    const [validationOk, setValidationOk] = useState<boolean>(false);
    const [previousErrors, setPreviousErrors] = useState<string[]>([]);
    const [lineItems, setLineItems] = useState<any[]>([]);
    const [allLineItems, setAllLineItems] = useState<boolean>(false);
    const token = useSelector((state: any) => state.logins.accessToken);
    const errorsOrders = useSelector((state: any) => state.orderssimply.errors);
    const error = useSelector((state: any) => state.orderssimply.error);
    const currentData = useSelector((state: any) => state.orderssimply.orderssimply);
    const isLoadOK = useSelector((state: any) => state.complement.update2WM);
    const VALIDATION_NO_ERRORS: boolean = error === ErrorType.NONE;
    const VALIDATION_SUCCESS: boolean = ranValidations && VALIDATION_NO_ERRORS;
    const VALIDATION_SUCCESS_WITH_SAP_ERROR: boolean =
        ranValidations &&
        error === ErrorType.RULE &&
        Array.isArray(errorsOrders) &&
        errorsOrders.some((error: string) => error === 'sap.confirm_error');

    useEffect(() => {
        const previousErrors = currentData
            .filter((lineItem: any) => lineItem.purchase_order_id === purchaseOrderId)
            .filter((lineItem: any) => {
                const element =
                    purchaseOrderStatus === 'DELIVERED'
                        ? lineItem.entry_sheet_number
                        : lineItem.line_item_id;
                return lineItemsFilter.includes(element);
            })
            .map((lineItem: any) => lineItem.errors ?? [])
            .flat();

        if (previousErrors.length > 0) {
            setValues('0');
            setPreviousErrors(previousErrors);
        } else {
            setValues('1');
            setPreviousErrors([]);
        }
    }, [purchaseOrderId, isModalOpen]);

    useEffect(() => {
        if (!loading && Array.isArray(errorsOrders) && errorsOrders.length > 0) {
            setValidationErrors(errorsOrders);
            setPreviousErrors(errorsOrders);
            setValues('2');
        } else {
            setValidationErrors([]);
        }
        setValidationOk(VALIDATION_SUCCESS || VALIDATION_SUCCESS_WITH_SAP_ERROR);
    }, [errorsOrders, loading, VALIDATION_SUCCESS, VALIDATION_SUCCESS_WITH_SAP_ERROR]);

    useEffect(() => {
        const newFileNames = files.map((file) => {
            if (file.name.length > 22) {
                return processFileName(file.name);
            }
            return file.name;
        });
        setFileNames(newFileNames);
    }, [files]);

    const schemaFormModal = yup.object({
        file: yup.string().required('Archivo pdf requerido.'),
        invoiceNumber: yup.string().required('Número de factura requerido'),
        amount: yup.string().required('Monto requerido.'),
        currency: yup.string().required('Moneda requerida.'),
        invoiceDate: yup.date().required('Fecha requerida'),
        freight: yup.number().notRequired(),
        packaging: yup.number().notRequired(),
        insurance: yup.number().notRequired(),
        others: yup.number().notRequired(),
    });

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: {errors},
        setValue,
    } = useForm<IForm>({
        resolver: yupResolver(schemaFormModal),
    });

    const watchForm = watch();

    useEffect(() => {
        setValue('invoiceDate', parseDate(new Date()).toFormat('yyyy-MM-dd'), {shouldTouch: true});
        setValue('currency', 'MXN', {shouldTouch: true});
    }, []);

    useEffect(() => {
        const lines = orderssimply.filter((item: any) =>
            lineItemsFilter.includes(
                `${String(item.line_item_id)}-${String(item.entry_sheet_number)}`
            )
        );
        setLineItems(lines);
        const total = lines.reduce((carry: number, item: any) => {
            return Number(item.item_price) + carry;
        }, 0);
        setValue('amount', total);
    }, [lineItemsFilter]);

    const valorPackaging = useWatch({
        control,
        name: 'packaging',
        defaultValue: 0,
    });
    const valorInsurance = useWatch({
        control,
        name: 'insurance',
        defaultValue: 0,
    });
    const valorFreight = useWatch({
        control,
        name: 'freight',
        defaultValue: 0,
    });
    const valorOthers = useWatch({
        control,
        name: 'others',
        defaultValue: 0,
    });

    useEffect(() => {
        const lines = orderssimply.filter((item: any) =>
            lineItemsFilter.includes(
                `${String(item.line_item_id)}-${String(item.entry_sheet_number)}`
            )
        );
        let total = lines.reduce((carry: number, item: any) => {
            return Number(item.item_price) + carry;
        }, 0);

        if (!isNaN(valorPackaging)) {
            total += Number(valorPackaging);
        }

        if (!isNaN(valorFreight)) {
            total += Number(valorFreight);
        }

        if (!isNaN(valorOthers)) {
            total += Number(valorOthers);
        }

        if (!isNaN(valorInsurance)) {
            total += Number(valorInsurance);
        }

        setValue('amount', total);
    }, [valorPackaging, valorFreight, valorInsurance, valorOthers]);

    const onSubmit = async () => {
        const form = new FormData();
        form.append('pdf', watchForm.file || '');
        form.append(
            'payload',
            JSON.stringify({
                invoiceNumber: watchForm.invoiceNumber,
                amount: watchForm.amount,
                currency: watchForm.currency,
                invoiceDate: parseDate(watchForm.invoiceDate).toFormat('yyyy-MM-dd'),
                lineItems: lineItemsFilter,
                allLineItems: allLineItems,
                indirectCosts: {
                    freight: watchForm.freight,
                    packaging: watchForm.packaging,
                    others: watchForm.others,
                    insurance: watchForm.insurance,
                },
            })
        );
        dispatch(sendFileExtarnalClient(token, form, purchaseOrderId));
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValues(newValue);
    };

    const handleCloseAndReload = () => {
        resetData();
        if (closeAndReload) {
            closeAndReload();
        }
    };

    // useEffect(() => {
    //     if (isLoadOK) handleCloseAndReload();
    // }, [isLoadOK]);

    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
        type: string
    ) => {
        event.stopPropagation();
        const file =
            type === 'drop'
                ? (event as React.DragEvent<HTMLDivElement>).dataTransfer.files[0]
                : (event.target as HTMLInputElement).files?.[0];
        if (!file) {
            return;
        }
        if (!ALLOWED_FILE_TYPES[1].includes(file.type)) {
            setErrorFile(t('modal_load_file.invalid_file'));
            return;
        }
        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            setErrorFile(
                `${t('modal_load_file.file_size_exceeds')} ${MAX_FILE_SIZE_MB} MB. ${t(
                    'modal_load_file.smaller_file'
                )}`
            );
            return;
        }
        setErrorFile('');
        const existingFileIndex = files.findIndex((f) => f.type === file.type);
        let newFiles: File[] = [];
        let newFileNames: string[] = [];

        if (existingFileIndex !== -1) {
            newFiles = [...files];
            newFiles[existingFileIndex] = file;
            newFileNames = fileNames.map((name) => {
                if (name.includes(`.${file.type}`)) {
                    return file.name;
                }
                return name;
            });
        } else {
            newFiles = [...files, file];
            newFileNames = [...fileNames, file.name];
        }

        setFiles(newFiles);
        setFileNames(newFileNames);
    };

    const handleCancelButton = () => {
        if (['0', '1'].includes(value)) {
            handleCloseAndReload();
        } else if (value === '2') {
            setValidationErrors([]);
            handleTabChange({} as React.ChangeEvent<Element>, '1');
        }
    };

    const cleanup = () => {
        reset({file: null});
        setValidationErrors([]);
        setValues('1');
        setPreviousErrors([]);
    };

    const resetData = () => {
        cleanup();
        setErrorFile('');
        setFiles([]);
        setFileNames([]);
        setRanValidations(false);
        setValidationOk(false);
        setAllLineItems(false);
        dispatch(error2WM());
    };

    const hasCurrentErrors = ranValidations && validationErrors.length > 1 && ['2'].includes(value);
    const hasPreviousErrors = previousErrors.length > 1 && ['0'].includes(value);
    const hasErrors = hasCurrentErrors || hasPreviousErrors;
    const errorCount =
        validationErrors.length > 0 ? validationErrors.length : previousErrors.length;

    const handleCheckAllLineItems = () => {
        setAllLineItems((prevState) => !prevState);
    };

    return (
        <StyledModal
            open={isModalOpen}
            onClose={() => closeModal}
            disableEscapeKeyDown={true}
            disableEnforceFocus={true}
        >
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: '665px',
                    minHeight: '501px',
                    zIndex: '100',
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Nav>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 700,
                                color: 'white',
                                marginLeft: '10px',
                            }}
                        >
                            {t('load')} {type}
                        </Typography>
                    </Nav>
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleTabChange}>
                                {previousErrors && previousErrors.length > 0 ? (
                                    <Tab
                                        disabled={ranValidations}
                                        label={t('previous_errors')}
                                        value="0"
                                        sx={{
                                            textTransform: 'none',
                                        }}
                                    />
                                ) : null}
                                <Tab
                                    disabled={validationOk}
                                    label={t('upload_files')}
                                    value="1"
                                    sx={{textTransform: 'none'}}
                                />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            {isLoadOK ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '240px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '20px',
                                        gap: '20px',
                                    }}
                                >
                                    <svg
                                        style={{marginTop: '40px'}}
                                        width="60"
                                        height="60"
                                        viewBox="0 0 120 120"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx="57" cy="57" r="57" fill="#4BBC68" />
                                        <path
                                            d="M26 57L44.8675 75.8675C45.493 76.493 46.507 76.493 47.1325 75.8675L87 36"
                                            stroke="white"
                                            strokeWidth="5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <span> La información se proceso con exito!</span>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Controller
                                        name="file"
                                        control={control}
                                        defaultValue={null}
                                        render={({field, fieldState}) => (
                                            <FileUploadContainer
                                                sx={{
                                                    minHeight: '100px',
                                                    margin: fieldState.error ? '0' : '10px 0',
                                                    borderColor: fieldState.error ? 'red' : 'none',
                                                }}
                                                onDragOver={(e) => e.preventDefault()}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    const file = e.dataTransfer.files[0];
                                                    if (file) field.onChange(file);
                                                    handleFileChange(e, 'drop');
                                                }}
                                                onClick={() =>
                                                    document.getElementById('fileInput')?.click()
                                                }
                                            >
                                                <StyledInput
                                                    id="fileInput"
                                                    type="file"
                                                    accept="application/pdf"
                                                    multiple={true}
                                                    onChange={(e) => {
                                                        const file = e.target.files?.[0];
                                                        if (file) field.onChange(file);
                                                        handleFileChange(e, 'change');
                                                    }}
                                                />
                                                {field.value && !errorFile ? (
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Typography variant="subtitle2">
                                                            {t('modal_load_file.last_file')}:
                                                            {field.value.name}
                                                        </Typography>
                                                        {!loading && (
                                                            <IconButton
                                                                disabled={validationOk}
                                                                aria-label="Eliminar"
                                                                onClick={(e) => {
                                                                    field.onChange(null);
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                ) : (
                                                    <Box>
                                                        <StyledFileLabelButton
                                                            variant="outlined"
                                                            as="span"
                                                        >
                                                            <img src={CloudIcon} />
                                                            <StyledLabelFileUpload>
                                                                Arrastra o sube el archivo PDF aquí
                                                            </StyledLabelFileUpload>
                                                        </StyledFileLabelButton>
                                                        <Typography variant="caption" color="error">
                                                            {errorFile}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </FileUploadContainer>
                                        )}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 300,
                                            color: 'red',
                                            marginBottom: '14px',
                                        }}
                                    >
                                        {errors?.file?.message}
                                    </Typography>
                                    <Grid container spacing={0.5}>
                                        <Grid xs={12} item container sx={{marginBottom: '10px'}}>
                                            Orden de compra:{' '}
                                            <span style={{fontWeight: 'bold', marginLeft: '10px'}}>
                                                {lineItems && lineItems.length > 0
                                                    ? lineItems[0].orderId
                                                    : ''}
                                            </span>
                                        </Grid>
                                        <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                            <Controller
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        id="field-provider-name"
                                                        label="Número de factura"
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                )}
                                                name="invoiceNumber"
                                                control={control}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 300,
                                                    color: 'red',
                                                    marginBottom: '14px',
                                                }}
                                            >
                                                {errors?.invoiceNumber?.message}
                                            </Typography>
                                        </Grid>

                                        <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <Controller
                                                    render={({field: {onChange, value}}) => (
                                                        <DatePicker
                                                            label={'Fecha factura'}
                                                            value={value}
                                                            onChange={onChange}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    sx={{width: '100%'}}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                    name="invoiceDate"
                                                    control={control}
                                                />
                                            </LocalizationProvider>
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 300,
                                                    color: 'red',
                                                    marginBottom: '14px',
                                                }}
                                            >
                                                {errors?.invoiceDate?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} item container sx={{marginBottom: '10px'}}>
                                            <Grid
                                                xs={12}
                                                item
                                                container
                                                sx={{
                                                    background: '#e9e6e6',
                                                    padding: '4px 2px 4px 2px',
                                                }}
                                            >
                                                <Grid item xs={3}>
                                                    <span style={{fontWeight: 'bold'}}>Pos</span>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <span style={{fontWeight: 'bold'}}>
                                                        Cantidad
                                                    </span>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <span style={{fontWeight: 'bold'}}>
                                                        Hoja de entrada
                                                    </span>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <span style={{fontWeight: 'bold'}}>
                                                        {' '}
                                                        Importe
                                                    </span>
                                                </Grid>
                                            </Grid>
                                            {lineItems &&
                                                lineItems.map((item, index) => {
                                                    return (
                                                        <Grid container key={index}>
                                                            <Grid item xs={3}>
                                                                <span
                                                                    style={{
                                                                        padding: '4px 2px 4px 2px',
                                                                    }}
                                                                >
                                                                    {item.position}
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <span
                                                                    style={{
                                                                        padding: '4px 2px 4px 2px',
                                                                    }}
                                                                >
                                                                    {Number(
                                                                        item.received_quantity
                                                                    ) || 0}
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <span
                                                                    style={{
                                                                        padding: '4px 2px 4px 2px',
                                                                    }}
                                                                >
                                                                    {item.entry_sheet_number}
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <span
                                                                    style={{
                                                                        padding: '4px 2px 4px 2px',
                                                                    }}
                                                                >
                                                                    {(
                                                                        Number(item.item_price) +
                                                                        Number(item.item_taxes)
                                                                    ).toLocaleString('en-US', {
                                                                        style: 'currency',
                                                                        currency: 'USD',
                                                                    })}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>

                                        <Grid xs={12} item container sx={{marginBottom: '10px'}}>
                                            <Grid
                                                xs={12}
                                                item
                                                sx={{
                                                    background: '#e9e6e6',
                                                    padding: '4px 2px 4px 2px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Constos indirectos
                                            </Grid>
                                        </Grid>
                                        <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                            <Controller
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        id="field"
                                                        label="Flete"
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                )}
                                                name="freight"
                                                control={control}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 300,
                                                    color: 'red',
                                                    marginBottom: '14px',
                                                }}
                                            >
                                                {errors?.freight?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                            <Controller
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        id="field"
                                                        label="Embalaje"
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                )}
                                                name="packaging"
                                                control={control}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 300,
                                                    color: 'red',
                                                    marginBottom: '14px',
                                                }}
                                            >
                                                {errors?.packaging?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                            <Controller
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        id="outlined-required"
                                                        label="Seguros"
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                )}
                                                name="insurance"
                                                control={control}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 300,
                                                    color: 'red',
                                                    marginBottom: '14px',
                                                }}
                                            >
                                                {errors?.insurance?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                            <Controller
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        id="outlined-required"
                                                        label="Otros incrementables"
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                )}
                                                name="others"
                                                control={control}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 300,
                                                    color: 'red',
                                                    marginBottom: '14px',
                                                }}
                                            >
                                                {errors?.others?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} item container sx={{marginBottom: '10px'}}>
                                            <Grid
                                                xs={12}
                                                item
                                                sx={{
                                                    background: '#e9e6e6',
                                                    padding: '4px 2px 4px 2px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Totales
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            xs={6}
                                            item
                                            container
                                            sx={{marginBottom: '10px', flexDirection: 'column'}}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 500,
                                                    color: 'black',
                                                }}
                                            >
                                                Monto
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 700,
                                                    color: 'black',
                                                    marginBottom: '14px',
                                                }}
                                            >
                                                {watchForm.amount
                                                    ? watchForm.amount.toLocaleString('en-US', {
                                                          style: 'currency',
                                                          currency: 'USD',
                                                      })
                                                    : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={6} item container sx={{marginBottom: '10px'}}>
                                            <Controller
                                                render={({field}) => (
                                                    <FormControl
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <InputLabel>Moneda</InputLabel>
                                                        <Select
                                                            label="Moneda"
                                                            {...field}
                                                            sx={{width: '100%'}}
                                                        >
                                                            <MenuItem value={'MXN'}>MXN</MenuItem>
                                                            <MenuItem value={'USD'}>USD</MenuItem>
                                                            <MenuItem value={'GBP'}>GBP</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                                name="currency"
                                                control={control}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: 300,
                                                    color: 'red',
                                                    marginBottom: '14px',
                                                }}
                                            >
                                                {errors?.currency?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} item container sx={{marginBottom: '10px'}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        onChange={handleCheckAllLineItems}
                                                        checked={allLineItems}
                                                    />
                                                }
                                                label={
                                                    <StatusMessage
                                                        message={`${t('all_these')} ${
                                                            purchaseOrderStatus === 'CONFIRMED'
                                                                ? t('positions')
                                                                : t('entries')
                                                        }`}
                                                        color="#000"
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </TabPanel>
                    </TabContext>
                    <Box
                        sx={{
                            paddingX: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{display: 'flex', justifySelf: 'flex-start'}}>
                            {hasErrors && (
                                <StatusMessage
                                    message={`${t('modal_load_file.total_errors')}: ${errorCount}`}
                                    color="#f50057"
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                height: '60px',
                                alignItems: 'center',
                                justifySelf: 'flex-end',
                            }}
                        >
                            {isLoadOK ? (
                                <ButtonCancel
                                    sx={{
                                        color: validationOk ? '#D3D3D3' : null,
                                        border: `1.5px solid ${validationOk ? '#D3D3D3' : null}`,
                                    }}
                                    disabled={validationOk}
                                    onClick={() => handleCancelButton()}
                                >
                                    {t('close').toUpperCase()}
                                </ButtonCancel>
                            ) : (
                                <>
                                    {' '}
                                    <ButtonCancel
                                        sx={{
                                            color: validationOk ? '#D3D3D3' : null,
                                            border: `1.5px solid ${
                                                validationOk ? '#D3D3D3' : null
                                            }`,
                                        }}
                                        disabled={validationOk}
                                        onClick={() => handleCancelButton()}
                                    >
                                        {['0', '1'].includes(value)
                                            ? t('cancel').toUpperCase()
                                            : t('back').toUpperCase()}
                                    </ButtonCancel>
                                    <SaveButton
                                        sx={{
                                            padding: '10px',
                                            display: `${validationOk ? 'none' : 'block'}`,
                                        }}
                                        disabled={loading}
                                        onClick={() => setValues('1')}
                                    >
                                        SUBIR
                                    </SaveButton>
                                </>
                            )}
                        </Box>
                    </Box>
                </form>
            </Box>
        </StyledModal>
    );
};

export default React.memo(ModalClientExternal);
