import DeleteModal from '@/components/UI/molecules/modals/DeleteModal';
import SuccessUpdateModal from '@/components/UI/molecules/modals/SuccessUpdateModal';
import WarningUpdateModal from '@/components/UI/molecules/modals/WarningUpdateModal';
import {getCountryId, getCountryName} from '@/utils/utils';
import IconCloseModal from '@components/UI/atoms/icons/IconCloseModal.svg';
import {
    ButtonCancel,
    ImgIconClose,
    InputLabelRol,
    Loader,
    Nav,
    SaveButton,
    StyledFormControlCountry,
    StyledFormControlRol,
    StyledModal,
    StyledSelect,
    StyledTextField,
} from '@components/faqs/StyledFAQ';
import {
    fetchDeleteFaqs,
    fetchPostFaqs,
    fetchPutFaqs,
} from '@components/faqs/redux/actions/FAQsActions';
import {useTranslation} from 'react-i18next';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    Box,
    Checkbox,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Typography,
    useTheme,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
const ModalFAQ = ({
    isModalOpen,
    closeModal,
    method,
    message = '',
    listDelete = [],
    setlistDelete,
    item = {},
}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const token = useSelector((state) => state.logins.accessToken);
    const loading = useSelector((state) => state.faqs.loading);
    const error = useSelector((state) => state.faqs.error);
    const countryCatalog = useSelector((state) => state.country.country);

    const roles = useSelector((state) => state.rolesManager.roles);

    const [status, setStatus] = useState('');
    const [inputRole, setInputRole] = useState([]);
    const [inputCountry, setInputCountry] = useState([]);
    const [checkAllCountry, setCheckAllCountry] = useState(false);
    const [checkAllRole, setCheckAllRole] = useState(false);

    const schemaFormModal = yup.object({
        question: yup
            .string()
            .max(90, t('faqs.question_max'))
            .required(t('faqs.question_required')),
        answer: yup.string().max(256, t('faqs.answer_max')).required(t('faqs.answer_required')),
        role: yup
            .array()
            .min(1, t('faqs.role_min'))
            .of(yup.string())
            .required()
            .typeError(t('faqs.role_required')),
        country: yup
            .array()
            .min(1, t('faqs.country_min'))
            .of(yup.number())
            .required()
            .typeError(t('faqs.country_required')),
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        setValue,
    } = useForm({resolver: yupResolver(schemaFormModal), mode: 'onChange'});

    const onSubmit = async (data) => {
        const json = {
            question: data.question,
            answer: data.answer,
            roles: data.role,
            countryIds: data.country,
        };
        try {
            if (method === 'Nueva') {
                await dispatch(fetchPostFaqs(token, json));
            }
            if (method === 'Editar') {
                await dispatch(fetchPutFaqs(item.id, token, json));
            }
            reset({
                question: '',
                answer: '',
                role: '',
                country: '',
            });
            setInputRole([]);
            setInputCountry([]);
        } catch (error) {
            setStatus('warning');
        }
    };

    const onSubmitDelete = async () => {
        const json = {
            ids:
                listDelete.length === 0 && item && Object.keys(item).length > 0
                    ? [item.id]
                    : listDelete,
        };
        try {
            await dispatch(fetchDeleteFaqs(json, token));
        } catch (error) {
            setStatus('warning');
        }
    };

    const handleChangeRole = (event) => {
        const {
            target: {value},
        } = event;
        cleanFilterRole(value);
    };
    const hanldeCheckAllRole = () => {
        setCheckAllRole((prev) => {
            cleanFilterRole(
                !prev ? roles.filter((item) => item.enabled).map((item) => item.name) : []
            );
            return !prev;
        });
    };
    const cleanFilterRole = (value) => {
        const selectedRole =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item) => !item.includes('notCheck'));
        let newArray = [];
        setInputRole(selectedRole);
        if (selectedRole.length > 0) {
            newArray = selectedRole.map((item) => {
                return roles.filter((item) => item.enabled).find((e) => e.name === item);
            });
        }
        setValue(
            'role',
            newArray.map((item) => {
                return item.id;
            })
        );
    };

    const handleChangeCountry = (event) => {
        const {
            target: {value},
        } = event;
        cleanFilterCountry(value);
    };
    const hanldeCheckAllCountry = () => {
        setCheckAllCountry((prev) => {
            cleanFilterCountry(!prev ? countryCatalog.map((item) => item.countryName) : []);
            return !prev;
        });
    };
    const cleanFilterCountry = (value) => {
        const selectedCountry =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item) => !item.includes('notCheck'));
        setInputCountry(selectedCountry);
        let newArray = [];
        if (selectedCountry.length > 0) {
            newArray = selectedCountry.map((item) => {
                return countryCatalog.find((e) => e.countryName === item);
            });
        }

        setValue(
            'country',
            newArray.map((item) => {
                return item.id;
            })
        );
    };

    useEffect(() => {
        if (!loading) {
            if (error) {
                setStatus('warning');
            } else if (!error) {
                setStatus('modified');
                setlistDelete([]);
            }
        }
    }, [error, loading]);

    useEffect(() => {
        switch (method) {
            case 'Eliminar':
                setStatus('delete');
                break;
            case 'Nueva':
                setStatus('create');
                reset({
                    question: '',
                    answer: '',
                    role: '',
                    country: '',
                });
                setInputRole([]);
                setInputCountry([]);
                break;
            case 'Editar': {
                setStatus('create');
                reset({
                    question: item.question,
                    answer: item.answer,
                });
                const rolesMapperNames = item.roles
                    ? item.roles.filter((item) => item.enabled).map((item) => item.name)
                    : [];
                const rolesMapperIds = item.roles
                    ? item.roles.filter((item) => item.enabled).map((item) => item.id)
                    : [];
                setInputRole(rolesMapperNames);
                setInputCountry(getCountryName(item.countries));
                setValue('role', rolesMapperIds);
                setValue('country', getCountryId(item.countries));
                break;
            }
            case 'Modificada':
                setStatus('modified');
                break;
            case 'Error':
                setStatus('warning');
                break;
            default:
                return;
        }
    }, [method]);

    useEffect(() => {
        if (token) {
            reset({
                question: '',
                answer: '',
                role: '',
                country: '',
            });
        }
    }, [dispatch, token]);
    useEffect(() => {
        setCheckAllRole(inputRole.length === roles.filter((item) => item.enabled).length);
    }, [inputRole]);
    useEffect(() => {
        setCheckAllCountry(inputCountry.length === countryCatalog.length);
    }, [inputCountry]);
    const validateStatus = () => {
        switch (status) {
            case 'create':
                return (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '20px 30px',
                                height: '250px',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: theme.typography.fontFamily,
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                            >
                                {t('faqs.fill_fields')}
                            </Typography>
                            <Box display="flex" justifyContent="space-between">
                                <Box>
                                    <Controller
                                        render={({field}) => (
                                            <StyledFormControlRol
                                                sx={{
                                                    marginTop: '15px',
                                                    marginBottom: errors?.role?.message
                                                        ? '15px'
                                                        : '30px',
                                                }}
                                            >
                                                <InputLabelRol>
                                                    {t('faqs.specific_role')}
                                                </InputLabelRol>
                                                <StyledSelect
                                                    {...field}
                                                    multiple
                                                    value={inputRole}
                                                    onChange={handleChangeRole}
                                                    input={<OutlinedInput label="Tag" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={{
                                                        sx: {
                                                            '&& .Mui-selected': {
                                                                backgroundColor: '#FFFF',
                                                            },
                                                            '&:hover': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem
                                                        value={'notCheck'}
                                                        onClick={() => {
                                                            setTimeout(() => {
                                                                hanldeCheckAllRole();
                                                            }, 500);
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={checkAllRole}
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: theme.palette.primary
                                                                        .main,
                                                                },
                                                            }}
                                                        />
                                                        <ListItemText
                                                            primary={t('faqs.select_all')}
                                                        />
                                                    </MenuItem>
                                                    {roles
                                                        .filter((item) => item.enabled)
                                                        .map((item, index) => (
                                                            <MenuItem key={index} value={item.name}>
                                                                <Checkbox
                                                                    checked={
                                                                        inputRole.indexOf(
                                                                            item.name
                                                                        ) > -1
                                                                    }
                                                                    sx={{
                                                                        '&.Mui-checked': {
                                                                            color: theme.palette
                                                                                .primary.main,
                                                                        },
                                                                    }}
                                                                />
                                                                <ListItemText primary={item.name} />
                                                            </MenuItem>
                                                        ))}
                                                </StyledSelect>
                                            </StyledFormControlRol>
                                        )}
                                        control={control}
                                        name="role"
                                    />
                                    {errors?.role?.message && (
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: theme.typography.fontFamily,
                                                fontWeight: 300,
                                                color: 'red',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {errors?.role?.message}
                                        </Typography>
                                    )}
                                </Box>
                                <Box>
                                    <Controller
                                        render={({field}) => (
                                            <StyledFormControlCountry
                                                sx={{
                                                    marginTop: '15px',
                                                    marginBottom: errors?.country?.message
                                                        ? '15px'
                                                        : '30px',
                                                }}
                                            >
                                                <InputLabelRol>{t('faqs.country')}</InputLabelRol>
                                                <StyledSelect
                                                    {...field}
                                                    multiple
                                                    value={inputCountry}
                                                    onChange={handleChangeCountry}
                                                    input={<OutlinedInput label="Tag" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={{
                                                        sx: {
                                                            '&& .Mui-selected': {
                                                                backgroundColor: '#FFFF',
                                                            },
                                                            '&:hover': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem
                                                        value={'notCheck'}
                                                        onClick={() => {
                                                            setTimeout(() => {
                                                                hanldeCheckAllCountry();
                                                            }, 500);
                                                        }}
                                                    >
                                                        <Checkbox
                                                            onChange={hanldeCheckAllCountry}
                                                            checked={checkAllCountry}
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: theme.palette.primary
                                                                        .main,
                                                                },
                                                            }}
                                                        />
                                                        <ListItemText
                                                            primary={t('faqs.select_all')}
                                                        />
                                                    </MenuItem>
                                                    {countryCatalog.map((item, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={item.countryName}
                                                        >
                                                            <Checkbox
                                                                checked={
                                                                    inputCountry.indexOf(
                                                                        item.countryName
                                                                    ) > -1
                                                                }
                                                                sx={{
                                                                    '&.Mui-checked': {
                                                                        color: theme.palette.primary
                                                                            .main,
                                                                    },
                                                                }}
                                                            />
                                                            <ListItemText
                                                                primary={item.countryName}
                                                            />
                                                        </MenuItem>
                                                    ))}
                                                </StyledSelect>
                                            </StyledFormControlCountry>
                                        )}
                                        control={control}
                                        name="country"
                                    />
                                    {errors?.country?.message && (
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: theme.typography.fontFamily,
                                                fontWeight: 300,
                                                color: 'red',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {errors?.country?.message}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Controller
                                render={({field, fieldState}) => (
                                    <StyledTextField
                                        {...field}
                                        id="outlined-required"
                                        label={t('faqs.enter_question')}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error ? fieldState.error.message : ''
                                        }
                                        sx={{marginBottom: '15px'}}
                                    />
                                )}
                                name="question"
                                control={control}
                            />
                            <Controller
                                render={({field, fieldState}) => (
                                    <StyledTextField
                                        {...field}
                                        id="outlined-required"
                                        label={t('faqs.response')}
                                        multiline
                                        rows={5}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error ? fieldState.error.message : ''
                                        }
                                    />
                                )}
                                name="answer"
                                control={control}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                height: '56px',
                                alignItems: 'center',
                                marginTop: '120px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ButtonCancel onClick={closeModal}>
                                {' '}
                                {t('cancel').toUpperCase()}
                            </ButtonCancel>
                            <SaveButton disabled={loading} sx={{marginRight: '30px'}}>
                                {loading ? <Loader size={24} /> : t('save').toUpperCase()}
                            </SaveButton>
                        </Box>
                    </form>
                );
            case 'modified':
                return (
                    <SuccessUpdateModal
                        isModalOpen={true}
                        method={method}
                        closeModal={closeModal}
                        message={message}
                    />
                );
            case 'warning':
                return (
                    <WarningUpdateModal
                        isModalOpen={true}
                        method={method}
                        closeModal={closeModal}
                        setStatus={setStatus}
                    />
                );
            case 'delete':
                return (
                    <DeleteModal
                        isModalOpen={true}
                        closeModal={closeModal}
                        onSubmitDelete={onSubmitDelete}
                    />
                );
            default:
                return;
        }
    };

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '3px',
                    width: '665px',
                    height: '501px',
                    zIndex: '100',
                }}
            >
                <Nav sx={{justifyContent: 'space-between'}}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            paddingLeft: '15px',
                        }}
                    >
                        {method === 'Modificada' || method === 'Error'
                            ? ''
                            : method === 'Nueva'
                            ? t('faqs.add_new_question')
                            : `${method} ${t('faqs.question')}`}
                    </Typography>
                    <ImgIconClose
                        src={IconCloseModal}
                        onClick={closeModal}
                        sx={{paddingRight: '15px'}}
                    />
                </Nav>
                {validateStatus()}
            </Box>
        </StyledModal>
    );
};

export default ModalFAQ;
