import {Box, Button, Typography, useTheme} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import React, {FunctionComponent} from 'react';

import {ConfirmDeliveryScheduleInvoiceEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import {TypographyStyled600} from '@/components/faqs/StyledFAQ';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import {FF_SHOW_PO_DETAILS} from '@/utils/envvars';

interface ConfirmActionDefaultProps {
    purchaseOrderId: string;
    isDisableAction: boolean;
    setIsRejectModalOpen: (value: boolean) => void;
}

const ConfirmActionDefault: FunctionComponent<ConfirmActionDefaultProps> = ({
    purchaseOrderId,
    isDisableAction,
    setIsRejectModalOpen,
}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {orderId, group} = useParams();
    const navigate = useNavigate();

    const {notAllowed} = useCheckAbilities();
    const goToUrl = (url: string) => {
        navigate(url);
    };
    return (
        <Box
            sx={{
                height: '75vh',
                minWidth: '580px',
                maxWidth: '100%',
                padding: '15px 23px',
                width: '100%',
            }}
        >
            {!FF_SHOW_PO_DETAILS && (
                <Box display="flex" justifyContent="flex-start" sx={{paddingTop: '50px'}}>
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                        }}
                    >
                        {t('confirmposimply.new_purchase_order')}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: '#CD9F28',
                            marginLeft: '10px',
                        }}
                    >
                        # {purchaseOrderId}
                    </Typography>
                </Box>
            )}
            <Box
                sx={{
                    borderRadius: '3px',
                    border: '1px solid #C2D1D9',
                    height: '218px',
                    background: '#FFFFFF',
                    marginTop: '30px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '15px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 700,
                        textAlign: 'center',
                        marginTop: '50px',
                    }}
                >
                    {t('confirmposimply.estimated_provider')}:
                </Typography>
                <Typography
                    sx={{
                        fontSize: '15px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                        marginTop: '20px',
                        textAlign: 'center',
                        padding: '0 50px',
                    }}
                >
                    {t('confirmposimply.review_caption')}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center" sx={{marginTop: '30px'}}>
                <Button
                    onClick={() => goToUrl(`/purchase-order/${orderId}/confirm/${group}`)}
                    sx={{
                        backgroundColor: '#4BBC68',
                        textTransform: 'none',
                        color: '#FFFFFF',
                        width: '182px',
                        margin: '0 10px',
                    }}
                    disabled={
                        notAllowed(
                            FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                            ConfirmDeliveryScheduleInvoiceEnum.REJECT_CONFIRM
                        ) || isDisableAction
                    }
                >
                    <TypographyStyled600 sx={{margin: '5px'}}>
                        {t('confirmposimply.full_confirmation')}
                    </TypographyStyled600>
                </Button>
                <Button
                    onClick={() => goToUrl(`/purchase-order/${orderId}/partial/${group}`)}
                    sx={{
                        backgroundColor: '#F5E003',
                        textTransform: 'none',
                        color: '#000000',
                        width: '182px',
                        margin: '0 10px',
                    }}
                    disabled={isDisableAction}
                >
                    <TypographyStyled600 sx={{margin: '5px'}}>
                        {t('confirmposimply.partial_confirmation')}
                    </TypographyStyled600>
                </Button>
                <Button
                    onClick={() => setIsRejectModalOpen(true)}
                    sx={{
                        backgroundColor: '#921A28',
                        textTransform: 'none',
                        color: '#FFFFFF',
                        width: '182px',
                        margin: '0 10px',
                    }}
                    disabled={
                        notAllowed(
                            FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                            ConfirmDeliveryScheduleInvoiceEnum.REJECT_CONFIRM
                        ) || isDisableAction
                    }
                >
                    <TypographyStyled600 sx={{margin: '5px'}}> {t('decline')}</TypographyStyled600>
                </Button>
                {group !== 'MARKETPLACE' && (
                    <Button
                        onClick={() => goToUrl(`/purchase-order/${orderId}/confirm/success`)}
                        sx={{
                            border: '2px solid #D1A33C',
                            backgroundColor: '#FFFFFF',
                            textTransform: 'none',
                            color: '#D1A33C',
                            margin: '0 10px',
                        }}
                        disabled={isDisableAction}
                    >
                        <TypographyStyled600 sx={{margin: '5px'}}>
                            {t('confirmposimply.full_confirmation_according_po')}
                        </TypographyStyled600>
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default ConfirmActionDefault;
