import Enterprise from '@components/auth/Enterprise';
import Mdweb from '@components/auth/Mdweb';
import ForgotPassword from '@components/auth/ForgotPassword';
import ResetPassword from '@components/auth/ResetPassword';
import LoginAdmin from '@login/Login';
import {DashboardRoutes} from '@routers/dashboardRoutes';
import {InterceptorApp} from '@routers/interceptor';
import {PrivateRoute} from '@routers/privateRoute';
import {PublicRoute} from '@routers/publicRoute';
import RouteInterceptor from '@utils/RouterInterceptor';
import React from 'react';
import {useDispatch} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import FullRejectPO from '@/components/purchase-order/reject/FullRejectPO';
import FullConfirmationPOSuccess from '@/components/purchase-order/confirm/FullConfirmationPOSuccess';
export const AppRouter = () => {
    const dispatch = useDispatch();
    InterceptorApp(dispatch);

    return (
        <div>
            <RouteInterceptor />

            <Routes>
                <Route
                    path="auth/*"
                    element={
                        <PublicRoute>
                            <Routes>
                                <Route path="/login" element={<LoginAdmin />} />
                                <Route path="/enterprise" element={<Enterprise />} />
                                <Route path="/mdweb" element={<Mdweb />} />
                                <Route path="/forgotpassword" element={<ForgotPassword />} />
                                <Route path="/resetpassword" element={<ResetPassword />} />
                            </Routes>
                        </PublicRoute>
                    }
                />

                <Route
                    path="purchase-order/public/*"
                    element={
                        <PublicRoute>
                            <Routes>
                                <Route
                                    path=":internalId/reject/:group"
                                    element={<FullRejectPO source="public" />}
                                />
                                <Route
                                    path=":internalId/confirm-total"
                                    element={<FullConfirmationPOSuccess />}
                                />
                            </Routes>
                        </PublicRoute>
                    }
                />

                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            <DashboardRoutes />
                        </PrivateRoute>
                    }
                />
            </Routes>
        </div>
    );
};
