import {TextError, TextSuccess} from '@/components/UI/molecules/textButton/textButtons';
import {fetchPutStatusOrderConfirm} from '@components/orderConfirm/redux/actions/OrderConfirmActions';
import {ButtonCancel, Loader, Nav, SaveButton} from '@components/faqs/StyledFAQ';
import {AppDispatch, RootState} from '@config/store';
import {Box, Modal, Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 50px;
`;

interface IOrderConfirmStatusModalProps {
    isModalOpen: boolean;
    closeModal: any;
    order: any;
    action: string;
}

const OrderConfirmStatusModal = (props: IOrderConfirmStatusModalProps) => {
    const {t} = useTranslation();
    const {isModalOpen, closeModal, action, order} = props;
    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.rolesManager.loading);
    const updateInvoiceStatus = () => {
        if (token && order) {
            dispatch(fetchPutStatusOrderConfirm(token, order, action));
        }
        closeModal();
    };

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {action === 'INVOICE_IN_PROCESS'
                            ? t('confirm_invoice')
                            : t('Reject invoice')}
                    </Typography>
                </Nav>
                <Box sx={{padding: '20px'}}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                        }}
                    >
                        <span style={{color: 'gray'}}>
                            {t('consultpo.table.headers.purchase_order')}:{' '}
                            <span style={{color: 'black'}}>{order ? order.orderId : ''}</span>
                        </span>
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                            marginTop: '20px',
                        }}
                    >
                        <Typography>
                            {t('change_status_to')}:{' '}
                            {action === 'INVOICE_IN_PROCESS' ? (
                                <TextSuccess>{t('candidates.approved').toUpperCase()}</TextSuccess>
                            ) : (
                                <TextError>{t('candidates.rejected').toUpperCase()}</TextError>
                            )}{' '}
                        </Typography>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        margin: '20px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonCancel onClick={closeModal}> {t('cancel')}</ButtonCancel>
                    <SaveButton disabled={loading} onClick={updateInvoiceStatus}>
                        {loading ? <Loader size={24} /> : t('apply').toUpperCase()}
                    </SaveButton>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default OrderConfirmStatusModal;
