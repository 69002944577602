import {
    GET_BINNACLES,
    GET_BINNACLES_SUCCESS,
    GET_BINNACLES_ERROR,
    GET_BINNACLES_BYID,
    GET_BINNACLES_BYID_SUCCESS,
    GET_BINNACLES_BYID_ERROR,
    GET_BINNACLES_DETAILS,
    GET_DOWNLOAD_BINNACLES,
    GET_DOWNLOAD_BINNACLES_SUCCESS,
    GET_DOWNLOAD_BINNACLES_ERROR,
    GET_DOWNLOAD_BINNACLES_BYID,
    GET_DOWNLOAD_BINNACLES_BYID_SUCCESS,
    GET_DOWNLOAD_BINNACLES_BYID_ERROR,
} from '@/components/log/redux/types/BinnaclesTypes';

const initialState = {
    binnacles: [],
    count: 0,
    page: 1,
    limit: 10,
    typeUsers: null,
    totalPages: 1,
    detailsbinnacles: [],
    detailscount: 0,
    detailspage: 1,
    detailslimit: 10,
    detailstotalPages: 1,
    loading: false,
    error: false,
    types: '',
    name: '',
    firstRegistrationDate: null,
    latestRegistrationDate: null,
};

const binnacles = (state = initialState, action) => {
    switch (action.type) {
        case GET_BINNACLES:
            return {
                ...state,
                loading: true,
            };
        case GET_BINNACLES_SUCCESS:
            return {
                ...state,
                binnacles: action.value.data,
                count: action.value.metadata.count,
                typeUsers: action.value.metadata.typeUsers,
                page: action.value.metadata.currentPage,
                limit: action.value.metadata.limit,
                totalPages: action.value.metadata.totalPages,
                firstRegistrationDate: action.value.metadata.firstRegistrationDate,
                latestRegistrationDate: action.value.metadata.latestRegistrationDate,
                loading: false,
            };
        case GET_BINNACLES_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_BINNACLES_BYID:
            return {
                ...state,
                loading: true,
            };
        case GET_BINNACLES_BYID_SUCCESS:
            return {
                ...state,
                detailsbinnacles: action.value.data,
                detailscount: action.value.metadata.count,
                detailspage: action.value.metadata.currentPage,
                detailslimit: action.value.metadata.limit,
                detailstotalPages: action.value.metadata.totalPages,
                loading: false,
            };
        case GET_BINNACLES_BYID_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_BINNACLES_DETAILS:
            return {
                ...state,
                types: action.types,
                name: action.name,
            };
        case GET_DOWNLOAD_BINNACLES:
            return {
                ...state,
                loading: true,
            };
        case GET_DOWNLOAD_BINNACLES_SUCCESS:
            return {
                ...state,
            };
        case GET_DOWNLOAD_BINNACLES_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case GET_DOWNLOAD_BINNACLES_BYID:
            return {
                ...state,
                loading: true,
            };
        case GET_DOWNLOAD_BINNACLES_BYID_SUCCESS:
            return {
                ...state,
            };
        case GET_DOWNLOAD_BINNACLES_BYID_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        default:
            return state;
    }
};

export default binnacles;
