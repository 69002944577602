import {
    Box,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    TextField,
    Typography,
} from '@mui/material';
import React, {useEffect} from 'react';
import logoAbi from '@order/images/logoAbi.svg';
import Loader from '@components/UI/molecules/loading/Loading';
import {useIsMobile} from '@hooks/useIsMobile';
import {useFormatNumber} from '@hooks/useFormatNumber';
import {useParams} from 'react-router-dom';
import {useCalculateReceived} from '@hooks/useCalculateReceived';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import iconArrowUp from '@order/images/iconArrowUp.svg';
import iconArrowDown from '@order/images/iconArrowDown.svg';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {es, enUS} from 'date-fns/locale';
import {Controller} from 'react-hook-form';
import {DatePicker} from '@mui/x-date-pickers';
import {
    StyledLocalizationProvider,
    StyledControllerWrapper,
    StyledLocalizationProviders,
    StyledControllerWrappers,
} from './styles';
import {MenuItem, Select} from '@mui/material';
import {fetchPostOrder, resetOrderIncompleteSuccess} from '@order/redux/actions/OrderActions';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import {OrderButton} from '@components/UI/atoms/buttons/ButtonPrincipal';
import {orderSchema} from '@order/orderIncomplete/validationSchemas';
import {options} from '@order/orderIncomplete/data';
import OrderHeader from '@order/OrderHeader';
const OrderIncomplete = () => {
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const formatNumber = useFormatNumber();
    const {orderId} = useParams();
    const isMobile = useIsMobile();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const orderIncomplete = useSelector((state: RootState) => state.order.orderIncomplete);
    const orderNumber = useSelector((state: RootState) => state.order.orderId);
    const loading = useSelector((state: RootState) => state.order.loading);
    const loadingpost = useSelector((state: RootState) => state.order.loadingpost);
    useEffect(() => {
        if (orderIncomplete === null || orderId !== orderNumber) {
            navigate(`/order/${orderId}`);
            dispatch(resetOrderIncompleteSuccess());
        }
    }, [orderIncomplete, navigate]);
    const {
        control,
        watch,
        handleSubmit,
        //formState: {errors},
        setValue,
    } = useForm({
        defaultValues: orderIncomplete,
        resolver: yupResolver(orderSchema),
        mode: 'onChange',
    });
    const handleOpenMenu = (lineItemId: number) => {
        const isOpenMenu: boolean = watch(`lineItems[${lineItemId}].isOpenBatchDetails`);
        setValue(`lineItems[${lineItemId}].isOpenBatchDetails`, !isOpenMenu);
    };
    const onSubmitPatch = (data: any) => {
        const json = {
            billNumber: watchForm?.billNumber,
            lineItems: watchForm.lineItems.map((item: any, index: number) => {
                let cont;

                if (orderIncomplete.typeorder === 'MARKETPLACE') {
                    const cont = {
                        itemId: item.id,
                        details: item.details,
                        rejectedBy: item.rejectedBy,
                        batches: item.batches.map((items: any) => {
                            const conts = {
                                batch: items.batch === '' ? '00.00.0000' : items.batch,
                                received: Number(items.received),
                                dateExpire: items.dateExpire === '' ? new Date() : items.dateExpire,
                                manufacturingDate:
                                    items.manufacturingDate === ''
                                        ? new Date()
                                        : items.manufacturingDate,
                            };
                            return conts;
                        }),
                    };
                    return cont;
                } else {
                    cont = {
                        itemId: item.id,
                        details: item.details,
                        rejectedBy: item.rejectedBy,
                        batches: [{received: Number(item.received)}],
                    };
                }
                return cont;
            }),
        };
        dispatch(fetchPostOrder(orderId, json, token));
    };
    const watchForm = watch();
    return (
        <>
            {loadingpost === true && <Loader text="Generando Hoja de entrada, espere un momento" />}
            <form onSubmit={handleSubmit(onSubmitPatch)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <OrderHeader isMobile={isMobile} logoAbi={logoAbi} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'calc(100% - 70px)',
                            background: '#EDEFF2',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                background: '#fff',
                                padding: isMobile ? '60px 40px 52px 40px' : '30px 20%',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '45px' : '22px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 600,
                                    color: '#011B34',
                                }}
                            >
                                Detalles de orden incompleta
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '33.6px' : '22.4px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    color: '#011B34',
                                }}
                            >
                                Por favor, ayudanos con mas detalle acerca de los productos que no
                                fueron marcados como completos
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: isMobile ? '0 40px' : '0 20%',
                            }}
                        >
                            {orderIncomplete?.lineItems.map((item: any, index: number) => {
                                const {
                                    receivedSum,
                                    receivedDifferenceNumber,
                                    receivedDifference,
                                    receivedText,
                                } = useCalculateReceived(orderIncomplete.typeorder, watch, index);
                                <Controller
                                    key={index}
                                    name={item.difference}
                                    control={control}
                                    defaultValue={receivedDifferenceNumber}
                                    render={({field, fieldState}) => <></>}
                                />;
                                if (receivedDifferenceNumber !== 0) {
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                marginTop: isMobile ? '36px' : '24px',
                                                display: 'flex',
                                                paddingBottom: isMobile ? '60px' : '30px',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {orderIncomplete.typeorder === 'MARKETPLACE' && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: isMobile ? '38px' : '25px',
                                                            fontFamily: 'Poppins',
                                                            fontWeight: 600,
                                                            color: '#000000',
                                                            opacity: 0.5,
                                                        }}
                                                    >
                                                        Ver detalles por lote{' '}
                                                    </Typography>
                                                    {Object.keys(watchForm).length > 0 &&
                                                    Array.isArray(watchForm.lineItems) ? (
                                                        watch(
                                                            `lineItems[${index}].isOpenBatchDetails`
                                                        ) === true ? (
                                                            <img
                                                                src={iconArrowDown}
                                                                width="30px"
                                                                onClick={() =>
                                                                    handleOpenMenu(index)
                                                                }
                                                            />
                                                        ) : (
                                                            <img
                                                                src={iconArrowUp}
                                                                width="30px"
                                                                onClick={() =>
                                                                    handleOpenMenu(index)
                                                                }
                                                            />
                                                        )
                                                    ) : (
                                                        <img
                                                            src={iconArrowUp}
                                                            width="30px"
                                                            onClick={() => handleOpenMenu(index)}
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            {Object.keys(watchForm).length > 0 &&
                                            Array.isArray(watchForm.lineItems)
                                                ? watch(
                                                      `lineItems[${index}].isOpenBatchDetails`
                                                  ) === false &&
                                                  orderIncomplete.typeorder === 'MARKETPLACE' && (
                                                      <>
                                                          {item.batches
                                                              .filter(
                                                                  (items: any) => items !== null
                                                              )
                                                              .map((items: any, index: number) => (
                                                                  <Box
                                                                      key={index}
                                                                      sx={{
                                                                          display: 'flex',
                                                                          flexDirection: 'column',
                                                                          borderTop:
                                                                              index > 0
                                                                                  ? '2px solid #E5B611'
                                                                                  : 'none',
                                                                          paddingTop:
                                                                              index > 0
                                                                                  ? '20px'
                                                                                  : '0',
                                                                          marginTop:
                                                                              index > 0
                                                                                  ? '20px'
                                                                                  : '0',
                                                                      }}
                                                                  >
                                                                      <Box
                                                                          sx={{
                                                                              display: 'flex',
                                                                              justifyContent:
                                                                                  'space-between',
                                                                          }}
                                                                      >
                                                                          {/* Lote */}
                                                                          <TextField
                                                                              value={items.batch}
                                                                              label="Lote"
                                                                              fullWidth
                                                                              InputProps={{
                                                                                  readOnly: true,
                                                                              }}
                                                                              sx={{
                                                                                  width: '60%',
                                                                                  '& .MuiInputBase-root':
                                                                                      {
                                                                                          height: '56px',
                                                                                          fontSize:
                                                                                              isMobile
                                                                                                  ? '28px'
                                                                                                  : '19px',
                                                                                      },
                                                                              }}
                                                                          />
                                                                          <StyledLocalizationProviders
                                                                              dateAdapter={
                                                                                  AdapterDateFns
                                                                              }
                                                                              locale={lang}
                                                                          >
                                                                              <StyledControllerWrappers>
                                                                                  <DatePicker
                                                                                      label="Fecha caducidad"
                                                                                      value={
                                                                                          items.dateExpire
                                                                                      }
                                                                                      readOnly
                                                                                      onChange={() => {}}
                                                                                      renderInput={(
                                                                                          params
                                                                                      ) => (
                                                                                          <TextField
                                                                                              {...params}
                                                                                              fullWidth
                                                                                              sx={{
                                                                                                  '& .MuiInputBase-root':
                                                                                                      {
                                                                                                          height: '56px',
                                                                                                          fontSize:
                                                                                                              isMobile
                                                                                                                  ? '28px'
                                                                                                                  : '19px',
                                                                                                      },
                                                                                              }}
                                                                                              InputProps={{
                                                                                                  readOnly:
                                                                                                      true,
                                                                                              }}
                                                                                          />
                                                                                      )}
                                                                                      minDate={
                                                                                          new Date()
                                                                                      }
                                                                                  />
                                                                              </StyledControllerWrappers>
                                                                          </StyledLocalizationProviders>
                                                                      </Box>
                                                                      <Box
                                                                          sx={{
                                                                              display: 'flex',
                                                                              justifyContent:
                                                                                  'space-between',
                                                                              paddingTop: '10px',
                                                                          }}
                                                                      >
                                                                          <StyledLocalizationProvider
                                                                              dateAdapter={
                                                                                  AdapterDateFns
                                                                              }
                                                                              locale={lang}
                                                                          >
                                                                              <StyledControllerWrapper>
                                                                                  <DatePicker
                                                                                      label="Fecha fabricación"
                                                                                      value={
                                                                                          items.manufacturingDate
                                                                                      }
                                                                                      readOnly
                                                                                      onChange={() => {}}
                                                                                      renderInput={(
                                                                                          params
                                                                                      ) => (
                                                                                          <TextField
                                                                                              {...params}
                                                                                              fullWidth
                                                                                              sx={{
                                                                                                  '& .MuiInputBase-root':
                                                                                                      {
                                                                                                          height: '56px',
                                                                                                          fontSize:
                                                                                                              isMobile
                                                                                                                  ? '28px'
                                                                                                                  : '19px',
                                                                                                      },
                                                                                              }}
                                                                                              InputProps={{
                                                                                                  readOnly:
                                                                                                      true,
                                                                                              }}
                                                                                          />
                                                                                      )}
                                                                                  />
                                                                              </StyledControllerWrapper>
                                                                          </StyledLocalizationProvider>
                                                                          {/* Total/lote */}
                                                                          <TextField
                                                                              value={items.received}
                                                                              label="Total/producto"
                                                                              fullWidth
                                                                              InputProps={{
                                                                                  readOnly: true,
                                                                              }}
                                                                              sx={{
                                                                                  width: '38%',
                                                                                  '& .MuiInputBase-root':
                                                                                      {
                                                                                          height: '56px',
                                                                                          fontSize:
                                                                                              isMobile
                                                                                                  ? '28px'
                                                                                                  : '19px',
                                                                                      },
                                                                              }}
                                                                          />
                                                                      </Box>
                                                                  </Box>
                                                              ))}
                                                      </>
                                                  )
                                                : null}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    borderBottom: '1px solid #0000001A',
                                                    paddingBottom: '10px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        paddingTop: '10px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            margin: '10px 15px',
                                                            padding: isMobile
                                                                ? '10px 0 10px 0'
                                                                : '10px 0 0 0',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: isMobile
                                                                        ? '38px'
                                                                        : '25px',
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: 600,
                                                                    color: '#011B34',
                                                                }}
                                                            >
                                                                {item.productName}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: isMobile
                                                                            ? '33.6px'
                                                                            : '22.4px',
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: 400,
                                                                        color: '#00000',
                                                                        opacity: 0.5,
                                                                    }}
                                                                >
                                                                    Total{' '}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: isMobile
                                                                            ? '33.6px'
                                                                            : '22.4px',
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: 500,
                                                                        color: '#00ACFF',
                                                                        marginLeft: '10px',
                                                                    }}
                                                                >
                                                                    {formatNumber(
                                                                        receivedSum * item.price
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '48px'
                                                                    : '32px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 500,
                                                                color: '#D1A33C',
                                                            }}
                                                        >
                                                            X{receivedSum}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            margin: '0px 15px',
                                                            padding: '0px 0 25px 0',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '33.6px'
                                                                    : '22.4px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 400,
                                                                color: '#000000',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            {receivedText}
                                                        </Typography>
                                                        <Controller
                                                            key={index}
                                                            name={`lineItems[${index}].difference`}
                                                            control={control}
                                                            render={({field, fieldState}) => {
                                                                const color =
                                                                    Object.keys(watchForm).length >
                                                                        0 &&
                                                                    Array.isArray(
                                                                        watchForm.lineItems
                                                                    )
                                                                        ? receivedSum ===
                                                                          item.remaining
                                                                            ? '#4BBC68'
                                                                            : receivedSum >
                                                                              item.remaining
                                                                            ? '#325A6D'
                                                                            : receivedSum <
                                                                                  item.remaining &&
                                                                              receivedSum > 0
                                                                            ? '#E5B611'
                                                                            : '#C7C7C7'
                                                                        : '#C7C7C7';

                                                                return (
                                                                    <TextField
                                                                        color="success"
                                                                        {...field}
                                                                        id={`totalReceived-${index}`}
                                                                        label="Total"
                                                                        value={receivedDifference}
                                                                        InputProps={{
                                                                            readOnly: true,
                                                                            endAdornment: (
                                                                                <InputAdornment
                                                                                    position="end"
                                                                                    sx={{
                                                                                        color: '#000000',
                                                                                        fontWeight: 400,
                                                                                        fontFamily:
                                                                                            'Poppins',
                                                                                    }}
                                                                                >
                                                                                    {item.unit}
                                                                                </InputAdornment>
                                                                            ),
                                                                            sx: {
                                                                                '& input': {
                                                                                    fontSize:
                                                                                        isMobile
                                                                                            ? '28px'
                                                                                            : '19px',
                                                                                    textAlign:
                                                                                        'end',
                                                                                    color: color,
                                                                                },
                                                                            },
                                                                        }}
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '250px'
                                                                                : '150px',
                                                                            border: color,
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        <Controller
                                                            name={`lineItems[${index}].rejectedBy`}
                                                            control={control}
                                                            defaultValue={null}
                                                            render={({field, fieldState}) => (
                                                                <FormControl
                                                                    fullWidth
                                                                    sx={{
                                                                        height: isMobile
                                                                            ? '100px'
                                                                            : '64px',
                                                                    }}
                                                                >
                                                                    <InputLabel
                                                                        id="demo-simple-select-label"
                                                                        sx={{
                                                                            fontSize: isMobile
                                                                                ? '30px'
                                                                                : '20px',
                                                                            fontWeight: 400,
                                                                            color: '#011B34',
                                                                            fontFamily: 'Poppins',
                                                                            margin: '-5px 0 0 5px',
                                                                        }}
                                                                    >
                                                                        Motivo de rechazo
                                                                    </InputLabel>
                                                                    <Select
                                                                        id="demo-simple-select"
                                                                        label="Motivo de Rechazo"
                                                                        {...field}
                                                                        error={!!fieldState.error}
                                                                        sx={{
                                                                            fontSize: isMobile
                                                                                ? '30px'
                                                                                : '20px',
                                                                            height: isMobile
                                                                                ? '100px'
                                                                                : '64px',
                                                                            fontFamily: 'Poppins',
                                                                            fontWeight: 700,
                                                                            color: '#011B34',
                                                                            backgroundColor: '#fff',
                                                                        }}
                                                                    >
                                                                        {options.map(
                                                                            (option, index) => (
                                                                                <MenuItem
                                                                                    key={index}
                                                                                    value={
                                                                                        option.value
                                                                                    }
                                                                                >
                                                                                    {option.label}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                    <FormHelperText
                                                                        sx={{color: '#d32f2f'}}
                                                                    >
                                                                        {fieldState.error
                                                                            ? 'Motivo de rechazo requerido'
                                                                            : ''}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            )}
                                                        />
                                                        <Controller
                                                            key={index}
                                                            name={`lineItems[${index}].diff`}
                                                            control={control}
                                                            defaultValue={item.diff}
                                                            render={({field, fieldState}) => <></>}
                                                        />
                                                        <Controller
                                                            name={`lineItems[${index}].details`}
                                                            control={control}
                                                            defaultValue={null}
                                                            render={({field, fieldState}) => (
                                                                <TextField
                                                                    {...field}
                                                                    id={`batch-${index}`}
                                                                    label="Detalles adicionales"
                                                                    multiline
                                                                    minRows={3}
                                                                    maxRows={3}
                                                                    error={!!fieldState.error}
                                                                    helperText={
                                                                        fieldState.error
                                                                            ? 'Detalles adicionales requerido'
                                                                            : ''
                                                                    }
                                                                    sx={{
                                                                        '& .MuiInputBase-root': {
                                                                            fontSize: isMobile
                                                                                ? '30px'
                                                                                : '20px',
                                                                            fontFamily: 'Poppins',
                                                                            fontWeight: 500,
                                                                            color: '#011B34',
                                                                            marginTop: '20px',
                                                                            background: 'white',
                                                                        },
                                                                        '& .MuiInputLabel-root': {
                                                                            fontSize: isMobile
                                                                                ? '30px'
                                                                                : '20px', // Ajusta el tamaño de la letra aquí
                                                                            fontFamily: 'Poppins',
                                                                            fontWeight: 400,
                                                                            color: '#011B34',
                                                                            marginTop: '10px',
                                                                        },
                                                                        '& .MuiInputLabel-root.Mui-focused':
                                                                            {
                                                                                color: '#D1A33C', // Cambia el color del label cuando está enfocado
                                                                            },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Typography
                                                sx={{
                                                    fontSize: isMobile ? '36px' : '24px',
                                                    fontFamily: 'Poppins',
                                                    fontWeight: 400,
                                                    color: '#011B34',
                                                    marginTop: '40px',
                                                }}
                                            >
                                                Verifica que toda la información sea correcta, al
                                                igual que el producto sea el correcto{' '}
                                            </Typography>
                                        </Box>
                                    );
                                }
                                return null;
                            })}
                        </Box>
                    </Box>
                    <OrderButton type="submit" disabled={loadingpost}>
                        {loading || loadingpost ? 'CARGANDO...' : 'CONFIRMAR'}
                    </OrderButton>
                </Box>
            </form>
        </>
    );
};
export default OrderIncomplete;
