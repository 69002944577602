import {
    ButtonAlternative,
    ButtonAlternativeMobile,
    ButtonMicrosoftMobile,
} from '@/components/UI/atoms/buttons/ButtonAlternative';
import {ColorButton, ColorButtonMobile} from '@/components/UI/atoms/buttons/ButtonPrincipal';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import background from '@login/images/laptop_abi.svg';
import {default as logoAbi, default as logoAbiQr} from '@login/images/logoAbi.svg';
import logoMicrosoft from '@login/images/logoMicrosoft.svg';
import logoModelo from '@login/images/logoModelo.svg';
import logoYaydoo from '@login/images/logoYaydoo.svg';
import {
    getHistory,
    getInfoUsers,
    getValidateUserAbi,
    getValidateUsers,
    setCreateEnterpriseSuccess,
    setError,
} from '@login/redux/actions/LoginActions';
import {Box, CircularProgress, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import LanguageSwitcher from '@login/componenents/LanguageSwitcher';
function esDispositivoMovil() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
}

const Login = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const mdPreloadData = useSelector((state: RootState) => state.logins.mdPreloadData);
    const history = useSelector((state: RootState) => state.logins.history);
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const flagPerfil = useSelector((state: RootState) => state.perfil.flagPerfil);
    const error = useSelector((state: RootState) => state.logins.error);

    const onSubmitValidate = async (jwt: any) => {
        try {
            await dispatch(getValidateUserAbi(jwt));
            await dispatch(getInfoUsers(jwt));
            if (history !== '') {
                navigate(history);
            } else {
                navigate('/home');
            }
        } catch (error) {
            // Manejar errores
        }
    };

    useEffect(() => {
        if (flagPerfil) {
            if (history !== '') {
                navigate(history);
            } else if (token) {
                navigate('/home');
            }
        }
    }, [flagPerfil, token]);

    useEffect(() => {
        if (error) {
            setIsLoading(false);
        }
    }, [error]);

    useEffect(() => {
        if (mdPreloadData) {
            navigate('/auth/mdweb');
        }
    }, [mdPreloadData]);

    interface IFormLogin {
        email: string;
        password: string;
    }
    const schemaFormModal = yup.object({
        // TODO: It is recommended to rename the email field to identifier
        // and create a CustomValidator to check RFC, MdWeb ID or email.
        email: yup.string(),
        password: yup
            .string()
            .min(8, t('auth.form.password_min'))
            .max(30, t('auth.form.password_max'))
            .required(t('auth.form.password_required')),
    });

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<IFormLogin>({resolver: yupResolver(schemaFormModal)});
    const onSubmit = async (data: IFormLogin) => {
        const json = {
            email: data.email,
            password: data.password,
        };
        dispatch(setError(false));
        setIsLoading(true);
        try {
            await dispatch(getValidateUsers(json));
        } catch (error) {
            // Manejar errores

            setIsLoading(false);
        }
    };
    useEffect(() => {
        dispatch(setError(false));
        const getJwtFromUrl = () => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('jwt');
        };
        const getJwtErrorFromUrl = () => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('jwterror');
        };
        const jwt = getJwtFromUrl();
        const jwtError = getJwtErrorFromUrl();
        if (jwt) {
            onSubmitValidate(jwt ?? token);
        } else if (jwtError) {
            alert(jwtError);
        }
    }, []);
    useEffect(() => {
        setIsMobile(esDispositivoMovil());
        if (
            location.state &&
            location.state.from &&
            location.state.from.pathname.includes('order')
        ) {
            dispatch(getHistory(location.state.from.pathname));
        }
    }, []);

    const goToProviderView = () => {
        dispatch(setCreateEnterpriseSuccess(false));
        window.location.href = 'auth/enterprise';
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            {!isMobile ? (
                <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
                    <Box
                        sx={{
                            boxSizing: 'border-box',
                            display: 'flex',
                            height: '100%',
                            minHeight: '800px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: '51vw',
                                background: '#E5B611',
                                paddingTop: '80px',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                color: '#FFFFFF',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '23px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 500,
                                }}
                            >
                                {t('auth.title')}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '40px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 700,
                                }}
                            >
                                {t('auth.subtitle')}
                            </Typography>
                            <img src={background} height="80%" />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '49vw',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    marginTop: '20vh',
                                }}
                            >
                                <LanguageSwitcher />
                            </Box>

                            <Box
                                component="form"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    marginTop: '8vh',
                                }}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <img width="184px" src={logoAbi} />
                                <Typography
                                    sx={{
                                        fontSize: '23px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 700,
                                        margin: '45px 0 25px 0',
                                    }}
                                >
                                    {t('auth.login')}
                                </Typography>
                                <Controller
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            id="outlined-required"
                                            label={t('auth.form.email')}
                                            size="small"
                                        />
                                    )}
                                    name="email"
                                    control={control}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 300,
                                        color: 'red',
                                        marginBottom: '14px',
                                    }}
                                >
                                    {errors?.email?.message}
                                </Typography>

                                <Controller
                                    name="password"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...register('password')}
                                            id="outlined-required"
                                            label={t('auth.form.password')}
                                            type="password"
                                            size="small"
                                        />
                                    )}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 300,
                                        color: 'red',
                                        marginTop: '20px',
                                    }}
                                >
                                    {errors?.password?.message}
                                </Typography>
                                {errors && (
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 300,
                                            color: 'red',
                                        }}
                                    >
                                        {error.message}
                                    </Typography>
                                )}
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 400,
                                        color: '#00ACFF',
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        textAlign: 'right',
                                        marginTop: '10px',
                                    }}
                                    onClick={() => {
                                        window.location.href = 'auth/forgotpassword';
                                    }}
                                >
                                    {t('auth.form.forget_password')}
                                </Typography>
                                <ColorButton disabled={isLoading}>
                                    {isLoading ? (
                                        <CircularProgress size={23} color="secondary" />
                                    ) : (
                                        t('auth.login')
                                    )}{' '}
                                </ColorButton>
                                <ButtonAlternative onClick={goToProviderView}>
                                    {t('auth.form.want_provider')}
                                </ButtonAlternative>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontFamily: 'Poppins',
                                        fontWeight: 600,
                                        margin: '15px 0 -10px 0',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('auth.form.continue')}
                                </Typography>
                                <ColorButton
                                    onClick={() => {
                                        window.location.href = `${process.env.REACT_APP_BASE_URL}/v2/auth/sso/saml/login/`;
                                    }}
                                >
                                    {t('auth.form.sso')}
                                </ColorButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            minHeight: '70px',
                            background: '#000000',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontFamily: 'Helvetica',
                                fontWeight: 700,
                                color: '#FFF',
                                marginRight: '15px',
                            }}
                        >
                            powered by{' '}
                        </Typography>
                        <img src={logoYaydoo} />
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        height: '100vh',
                        background: 'linear-gradient(to bottom, #D3A239, #F4DE05)',
                        padding: '30% 50px 0 50px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            background: '#FFF',
                            borderRadius: '30px',
                            padding: '90px 50px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {' '}
                            <img src={logoModelo} width="400" /> <img src={logoAbiQr} width="400" />{' '}
                        </Box>
                        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                            <Typography
                                sx={{
                                    fontSize: '45px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 600,
                                    margin: '80px 0 60px 0',
                                }}
                            >
                                {t('auth.login')}{' '}
                            </Typography>
                            <Controller
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        id="outlined-required"
                                        label={t('auth.form.email')}
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                )}
                                name="email"
                                control={control}
                            />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 300,
                                    color: 'red',
                                    marginBottom: '14px',
                                }}
                            >
                                {errors?.email?.message}
                            </Typography>

                            <Controller
                                name="password"
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...register('password')}
                                        id="outlined-required"
                                        label={t('auth.form.password')}
                                        type="password"
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                )}
                            />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 300,
                                    color: 'red',
                                }}
                            >
                                {errors?.password?.message}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '29px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 400,
                                    color: '#00ACFF',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    textAlign: 'right',
                                    marginTop: '24px',
                                }}
                                onClick={() => {
                                    window.location.href = 'auth/forgotpassword';
                                }}
                            >
                                {t('auth.form.forget_password')}
                            </Typography>
                            <ColorButtonMobile disabled={isLoading}>
                                {isLoading ? (
                                    <CircularProgress size={23} color="secondary" />
                                ) : (
                                    t('auth.login')
                                )}
                            </ColorButtonMobile>

                            <ButtonAlternativeMobile
                                onClick={() => {
                                    window.location.href = 'auth/enterprise';
                                }}
                            >
                                {t('auth.form.want_provider')}
                            </ButtonAlternativeMobile>
                            <Typography
                                sx={{
                                    fontSize: '33px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 600,
                                    color: '#C7C7C7',
                                    margin: '35px 0',
                                    textAlign: 'center',
                                }}
                            >
                                {t('auth.form.continue')}
                            </Typography>
                            <ButtonMicrosoftMobile
                                onClick={() => {
                                    window.location.href = `${process.env.REACT_APP_BASE_URL}/v2/auth/sso/saml/login/`;
                                }}
                            >
                                <img src={logoMicrosoft} width="320px" />
                            </ButtonMicrosoftMobile>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Login;
