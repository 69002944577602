import {
    GET_ACCOUNT_STATUS,
    GET_ACCOUNT_STATUS_ERROR,
    GET_ACCOUNT_STATUS_SUCCESS,
    GET_HOME_BANNERS,
    GET_HOME_BANNERS_ERROR,
    GET_HOME_BANNERS_SUCCESS,
    GET_ORDER,
    GET_ORDER_ERROR,
    GET_ORDER_SUCCESS,
    GET_TICKET_ERROR_HOME,
    GET_TICKET_HOME,
    GET_TICKET_SUCCESS_HOME,
} from '@/components/home/redux/types/HomeTypes';
import StringUtils from '@/utils/StringUtils';
import axios from 'axios';

export const getOrders = () => {
    return {
        type: GET_ORDER,
    };
};

export const getOrdersSuccess = (result) => {
    return {
        type: GET_ORDER_SUCCESS,
        value: result.data,
    };
};

export const getOrdersError = () => {
    return {
        type: GET_ORDER_ERROR,
    };
};

export const getTickets = () => {
    return {
        type: GET_TICKET_HOME,
    };
};

export const getTicketsSuccess = (result) => {
    return {
        type: GET_TICKET_SUCCESS_HOME,
        value: result,
    };
};

export const getTicketsError = () => {
    return {
        type: GET_TICKET_ERROR_HOME,
    };
};

export const getAccountStatus = () => {
    return {
        type: GET_ACCOUNT_STATUS,
    };
};

export const getAccountStatusSuccess = (result) => {
    return {
        type: GET_ACCOUNT_STATUS_SUCCESS,
        value: result,
    };
};

export const getAccountStatusError = () => {
    return {
        type: GET_ACCOUNT_STATUS_ERROR,
    };
};

export function fetchGetInfoHome(token, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getAccountStatus());
        try {
            const params = {
                supplierId: filters.supplierId,
                page: filters.page,
                limit: filters.limit,
            };
            const queryString = StringUtils.jsonToQueryString(params);
            const {data} = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/account-statement/details${queryString}`,
                {
                    headers,
                }
            );
            dispatch(getAccountStatusSuccess(data.data));
        } catch (error) {
            dispatch(getAccountStatusError());
        }
    };
}

export function fetchGetInfoPO(token, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = {...filters, sortOrder: 'DESC', status: 'CREATED'};

        dispatch(getOrders());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/list/all`,
                {
                    headers: headers,
                    params: params,
                }
            );
            dispatch(getOrdersSuccess(response.data));
        } catch (error) {
            dispatch(getOrdersError());
        }
    };
}

export function fetchGetInfoTickets(token, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = {...filters, sortOrder: 'DESC'};
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getTickets());

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/v2/ticket${queryString}`, {
                headers: headers,
            })
            .then(({data}) => {
                dispatch(getTicketsSuccess(data.data));
            })
            .catch((error) => {
                dispatch(getTicketsError());
            });
    };
}

export const getBanners = () => {
    return {
        type: GET_HOME_BANNERS,
    };
};

export const getBannersSuccess = (result) => {
    return {
        type: GET_HOME_BANNERS_SUCCESS,
        value: result,
    };
};

export const getBannersError = (error, result) => {
    return {
        type: GET_HOME_BANNERS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetHomeBanners(token) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getBanners());

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/v2/banners/home-list`, {
                headers: headers,
            })
            .then(({data}) => {
                dispatch(getBannersSuccess(data.data));
            })
            .catch((error) => {
                dispatch(getBannersError());
            });
    };
}
