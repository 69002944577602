import {routers} from '@/appRoute';
import AvatarWithMenu from '@/components/UI/molecules/avatar/Avatar';
import AclGuard from '@/components/auth/guard/AclGuard';
import {setOrderAlert} from '@/components/auth/redux/actions/LoginActions';
import Candidates from '@/components/candidates';
import CSFTabs from '@/components/csf/index';
import FAQs from '@/components/faqs';
import LegalAgreements from '@/components/legalAgreements/index';
import Mdweb from '@/components/mdweb';
import Modelorama from '@/components/modelorama';
import ModeloramaPositions from '@/components/modelorama/ModeloramaPositions';
import TinkerBell from '@/components/notifications/components/TinkerBell';
import OrderIncomplete from '@/components/order/orderIncomplete';
import OrderConfirmAction from '@/components/orderSimply/OrderConfirmAction';
import FullConfirmationPOSuccess from '@/components/purchase-order/confirm/FullConfirmationPOSuccess';
import PurchaseOrderConfirmPartial from '@/components/purchase-order/confirmPartial';
import PurchaseOrderConfirmTotal from '@/components/purchase-order/confirmTotal';
import FullRejectPO from '@/components/purchase-order/reject/FullRejectPO';
//import ResponseComponent from '@/components/search/ResponseComponent';
//import SearchComponent from '@/components/search/SearchComponent';
import TaxRequirements from '@/components/taxRequirements';
import Urls from '@/components/urls';
import {Can} from '@/context/AbilityContext';
import {
    AccountStatusEnum,
    CandidatesEnum,
    CheckStatusOrdersEnum,
    ConfirmDeliveryScheduleInvoiceEnum,
    CustomsAgentsEnum,
    FeatureCodeEnum,
    FiscalRequirementsEnum,
    FrequentQuestionsEnum,
    HomeEnum,
    LegalAgreementsEnum,
    ManualsEnum,
    MerchandiseReceptionEnum,
    PendingBillsEnum,
    SupplierEnum,
    SupportStatusEnum,
    URLenum,
} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import ModalAlertOrder from '@/routers/ModalAlertOrder';
import Configuration from '@components/configuration';
import Binnacles from '@components/log';
import BinnaclesDetails from '@components/log/BinnaclesDetails';
import OrderSuccess from '@components/order/OrderSuccess';
import {AppDispatch, RootState} from '@config/store';
import iconBillingStatement from '@images/iconBillingStatement.svg';
import iconBox from '@images/iconBox.svg';
import iconCSFABI from '@images/iconCSFABI.svg';
import iconConfiguration from '@images/iconConfiguration.svg';
import iconConfirmOrder from '@images/iconConfirmOrder.svg';
import iconFaqs from '@images/iconFaqs.svg';
import iconHome from '@images/iconHome.svg';
import iconLegalAgreements from '@images/iconLegalAgreements.svg';
import iconLinkUrl from '@images/iconLinkUrl.svg';
import iconManuals from '@images/iconManuals.svg';
import iconOrdersSimply from '@images/iconOrdersSimply.svg';
import iconPoAgents from '@images/iconPoAgents.svg';
import iconSupport from '@images/iconSupport.svg';
import iconTaxRequirements from '@images/iconTaxRequirements.svg';
import logoMin from '@images/logoMin.svg';
import logoYaydoo from '@images/logoNavbar.svg';
import iconModelorama from '@images/modelo.svg';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import {Box, Typography, Container, useTheme} from '@mui/material';
import {MenuItem, MenuItemBorder} from '@routers/menu';
import {routesList} from '@routers/routesList';
import React, {startTransition, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import './styles.css'; // Include your CSS file for styling

// interface Data {
//     type: string;
//     referenceId: string;
//     vendor: string;
// }

export const DashboardRoutes = () => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const [isOrder, setIsOrder] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const orderAlert = useSelector((state: RootState) => state.logins.orderAlert);
    const menu = useSelector((state: RootState) => state.perfil.menu);
    const logo = useSelector((state: RootState) => state.perfil.logo);
    const user = useSelector((state: RootState) => state.logins.user);
    const enterpriseStatus = useSelector(
        (state: RootState) => state.logins.user.enterprise?.status
    );
    const dispatch: AppDispatch = useDispatch();
    const [srcLocal, setSrcLocal] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    //const [filteredData, setFilteredData] = useState<Data[]>([]);
    //const [searchQuery, setSearchQuery] = useState<string>('');

    // const mockData: Data[] = [
    //     {
    //         type: t('search.purchase_order'),
    //         referenceId: '4509602293',
    //         vendor: 'CERVECERIA MODELO DE MEXICO S DE RL DE CV',
    //     },
    //     {
    //         type: t('search.purchase_order'),
    //         referenceId: '4509602314',
    //         vendor: 'CERVECERIA MODELO DE MEXICO S DE RL DE CV',
    //     },
    //     {
    //         type: t('search.purchase_order'),
    //         referenceId: '4509602313',
    //         vendor: 'CERVECERIA MODELO DE MEXICO S DE RL DE CV',
    //     },
    //     {
    //         type: t('search.purchase_order'),
    //         referenceId: '4509602308',
    //         vendor: 'SERVICIOS LOGISTICOS MODELORAMA S DE RL DE CV',
    //     },
    //     {
    //         type: t('search.purchase_order'),
    //         referenceId: '4509602320',
    //         vendor: 'CERVECERIA MODELO DE MEXICO S DE RL DE CV',
    //     },
    //     {
    //         type: t('search.purchase_order'),
    //         referenceId: '4509602318',
    //         vendor: 'CERVECERIA MODELO DE MEXICO S DE RL DE CV',
    //     },
    //     {
    //         type: t('search.purchase_order'),
    //         referenceId: '4509602319',
    //         vendor: 'CERVECERIA MODELO DE TORREON S DE RL DE CV',
    //     },
    //     {
    //         type: t('search.purchase_order'),
    //         referenceId: '4509602316',
    //         vendor: 'CERVECERIA MODELO DE TORREON S DE RL DE CV',
    //     },
    //     {
    //         type: t('search.purchase_order'),
    //         referenceId: '4509602317',
    //         vendor: 'CERVECERIA MODELO DE TORREON S DE RL DE CV',
    //     },
    //     {
    //         type: t('search.purchase_order'),
    //         referenceId: '4509602289',
    //         vendor: 'LAS CERVEZAS MODELO EN SONORA S DE RL DE CV',
    //     },
    // ];

    const routes: Record<string, any> = {};
    menu.forEach((item: any) => {
        routes[item.value] = i18n.language === 'en' ? item.nameEN : item.name;
    });

    const {
        atLeastOnePermission,
        atLeastOnePermissionAbiTax,
        atLeastOnePermissionMerchandiseReceptionQR,
        atLeastOneSupplierTaxStatusRecord,
    } = useCheckAbilities();

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    // const handleSearch = (query: string) => {
    //     setSearchQuery(query);
    //     if (query) {
    //         setTimeout(() => {
    //             setFilteredData(
    //                 mockData.filter((item) =>
    //                     item.referenceId.toLowerCase().includes(query.toLowerCase())
    //                 )
    //             );
    //         }, 1000); // Simulate backend request with 1 second delay
    //     } else {
    //         setFilteredData([]);
    //     }
    // };

    // const handleClear = () => {
    //     setSearchQuery('');
    //     setFilteredData([]);
    // };

    useEffect(() => {
        const containsOrder = window.location.pathname.includes('order');
        if (containsOrder) {
            // Realizar alguna acción si la URL contiene 'order'
            setIsOrder(true);
        } else {
            setIsOrder(false);
        }
    }, []);

    useEffect(() => {
        if (orderAlert && user.type === 'SUPPLIER') {
            dispatch(setOrderAlert(false));
            setIsModalOpen(true);
        }
    }, [orderAlert]);

    useEffect(() => {
        const userLang = localStorage.getItem('user-lang') || 'es';
        startTransition(() => {
            i18n.changeLanguage(userLang);
        });
    }, [i18n]);

    const changeLanguage = (lng: string) => {
        startTransition(() => {
            i18n.changeLanguage(lng);
            localStorage.setItem('user-lang', lng);
        });
    };
    const handleError = () => {
        setSrcLocal(isHovered ? logoYaydoo : logoMin);
    };
    useEffect(() => {
        if (logo) {
            setTimeout(() => {
                setSrcLocal(logo.value);
            }, 500);
        }
    }, [logo]);

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100vh',
                width: '100vw',
            }}
        >
            {!isOrder && (
                <Box
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        width: isHovered ? '246px' : '50px',
                        background: '#2D313F',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        {isHovered ? (
                            <img className="img" src={srcLocal} onError={handleError} />
                        ) : (
                            <img
                                className="imghov"
                                src={srcLocal}
                                width="30px"
                                height="30px"
                                onError={handleError}
                            />
                        )}
                        <Can I={HomeEnum.SEE_VIEW} a={FeatureCodeEnum.HOME}>
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/home';
                                }}
                            >
                                <img src={iconHome} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '20px',
                                    }}
                                >
                                    {routes['/home']}
                                </Typography>
                            </MenuItem>
                        </Can>
                        <Can I={SupplierEnum.SEE_LIST} a={FeatureCodeEnum.SUPPLIER}>
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/modelorama';
                                }}
                            >
                                <img src={iconModelorama} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/modelorama']}
                                </Typography>
                            </MenuItem>
                        </Can>
                        <Can
                            I={CheckStatusOrdersEnum.VIEW_LIST}
                            a={FeatureCodeEnum.CHECK_STATUS_ORDERS}
                        >
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/consultpo';
                                }}
                            >
                                <img src={iconOrdersSimply} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '20px',
                                    }}
                                >
                                    {routes['/consultpo']}
                                </Typography>
                            </MenuItem>
                        </Can>
                        {atLeastOnePermissionMerchandiseReceptionQR() && (
                            <Can
                                I={MerchandiseReceptionEnum.LIST_RECEIPTS_QR}
                                a={FeatureCodeEnum.MERCHANDISE_RECEIPT_QR}
                            >
                                <MenuItem
                                    onClick={() => {
                                        window.location.href = '/receptpo';
                                    }}
                                >
                                    <img src={iconBox} />
                                    <Typography
                                        sx={{
                                            color: 'white',
                                            fontSize: '14px',
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: 500,
                                            marginLeft: '20px',
                                        }}
                                    >
                                        {routes['/receptpo']}
                                    </Typography>
                                </MenuItem>
                            </Can>
                        )}

                        <Can
                            I={ConfirmDeliveryScheduleInvoiceEnum.VIEW_LIST}
                            a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                        >
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/confirmposimply';
                                }}
                            >
                                <img src={iconConfirmOrder} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '20px',
                                    }}
                                >
                                    {routes['/confirmposimply']}
                                </Typography>
                            </MenuItem>
                        </Can>
                        <Can I={PendingBillsEnum.SEE_LIST} a={FeatureCodeEnum.PENDING_BILLS}>
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/external-invoice-confirm';
                                }}
                            >
                                <img src={iconConfirmOrder} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '20px',
                                    }}
                                >
                                    {routes['/external-invoice-confirm']}
                                </Typography>
                            </MenuItem>
                        </Can>
                        <Can I={CustomsAgentsEnum.VIEW_LIST} a={FeatureCodeEnum.CUSTOMS_AGENTS}>
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/poagents';
                                }}
                            >
                                <img src={iconPoAgents} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/poagents']}
                                </Typography>
                            </MenuItem>
                        </Can>
                        <Can I={URLenum.SEE_LIST} a={FeatureCodeEnum.URL}>
                            <MenuItemBorder
                                onClick={() => {
                                    window.location.href = '/urls';
                                }}
                            >
                                <img src={iconLinkUrl} width="30px" height="30px" />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/urls']}
                                </Typography>
                            </MenuItemBorder>
                        </Can>
                        <Can I={ManualsEnum.SEE_LIST} a={FeatureCodeEnum.MANUALS}>
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/manuals';
                                }}
                            >
                                <img src={iconManuals} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/manuals']}
                                </Typography>
                            </MenuItem>
                        </Can>
                        <Can
                            I={FrequentQuestionsEnum.SEE_LIST}
                            a={FeatureCodeEnum.FREQUENT_QUESTIONS}
                        >
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/faqs';
                                }}
                            >
                                <img src={iconFaqs} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/faqs']}
                                </Typography>
                            </MenuItem>
                        </Can>
                        <Can I={LegalAgreementsEnum.VISUALIZE} a={FeatureCodeEnum.LEGAL_AGREEMENTS}>
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/legalagreementsAdmin';
                                }}
                            >
                                <img src={iconLegalAgreements} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/legalagreementsAdmin']}
                                </Typography>
                            </MenuItem>
                        </Can>
                        <Can
                            I={FiscalRequirementsEnum.VISUALIZE}
                            a={FeatureCodeEnum.FISCAL_REQUIREMENTS}
                        >
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/taxrequirements';
                                }}
                            >
                                <img src={iconTaxRequirements} width="24px" height="24px" />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/taxrequirements']}
                                </Typography>
                            </MenuItem>
                        </Can>
                        {(atLeastOnePermissionAbiTax() || atLeastOneSupplierTaxStatusRecord()) && (
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/csf';
                                }}
                            >
                                <img src={iconCSFABI} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/csf']}
                                </Typography>
                            </MenuItem>
                        )}
                        <Can I={AccountStatusEnum.SEE_LIST} a={FeatureCodeEnum.ACCOUNT_STATUS}>
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/billingStatement';
                                }}
                            >
                                <img src={iconBillingStatement} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/billingStatement'] || 'Estado de Cuenta'}
                                </Typography>
                            </MenuItem>
                        </Can>
                        <Can I={CandidatesEnum.VIEW_CANDIDATES} a={FeatureCodeEnum.CANDIDATES}>
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/candidates';
                                }}
                            >
                                <PeopleRoundedIcon sx={{color: 'white'}} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/candidates']}
                                </Typography>
                            </MenuItem>
                        </Can>
                        {enterpriseStatus === 'APPROVED' && (
                            <MenuItem
                                onClick={() => {
                                    window.open('https://mdwebqa.ab-inbev.com/login', '_blank');
                                }}
                            >
                                <span style={{color: 'white', fontWeight: 'bold'}}>MD</span>
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/mdweb']}
                                </Typography>
                            </MenuItem>
                        )}
                        <Can I={SupportStatusEnum.SEE_LIST} a={FeatureCodeEnum.SUPPORT}>
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/support';
                                }}
                            >
                                <img src={iconSupport} />
                                <Typography
                                    sx={{
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        marginLeft: '15px',
                                    }}
                                >
                                    {routes['/support']}
                                </Typography>
                            </MenuItem>
                        </Can>
                    </div>
                    {atLeastOnePermission() && (
                        <MenuItem
                            onClick={() => {
                                window.location.href = '/configuration';
                            }}
                        >
                            <img src={iconConfiguration} />
                            <Typography
                                sx={{
                                    color: 'white',
                                    fontSize: '14px',
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: 500,
                                    marginLeft: '15px',
                                }}
                            >
                                {routes['/configuration']}
                            </Typography>
                        </MenuItem>
                    )}
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    width: 'calc(100vw )',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        background: 'white',
                        justifyContent: 'space-between',
                        height: '61px',
                        alignItems: 'center',
                        position: 'relative',
                        width: isOrder
                            ? '100vw'
                            : isHovered
                            ? 'calc(100vw - 253px)'
                            : 'calc(100vw - 50px)',
                    }}
                >
                    {/* TODO: Move this Container to a Component */}
                    <Container>
                        <Box />
                    </Container>
                    <TinkerBell />
                    <Typography
                        sx={{
                            fontSize: '12px',
                            marginRight: '10px',
                        }}
                    >
                        {t('language')}
                    </Typography>
                    <Typography
                        sx={{
                            marginRight: '5px',
                            cursor: 'pointer',
                            fontWeight: '500',
                            color: i18n.language === 'es' ? 'red' : 'black',
                        }}
                        onClick={() => changeLanguage('es')}
                    >
                        ESP
                    </Typography>
                    <Typography
                        sx={{
                            marginRight: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        |
                    </Typography>
                    <Typography
                        sx={{
                            marginRight: '5px',
                            cursor: 'pointer',
                            fontWeight: '500',
                            color: i18n.language === 'en' ? 'red' : 'black',
                        }}
                        onClick={() => changeLanguage('en')}
                    >
                        ENG
                    </Typography>
                    <AvatarWithMenu />
                </Box>
                <Box
                    sx={{
                        background: '#F1F1F1',
                        height: '100%',
                        width: isOrder
                            ? '100vw'
                            : isHovered
                            ? 'calc(100vw - 253px)'
                            : 'calc(100vw - 50px)',
                        overflowX: 'hidden',
                    }}
                >
                    <Routes>
                        <Route path={routers.urls} element={<Urls />} />
                        <Route path={routers.faqs} element={<FAQs />} />
                        <Route path={routers.legalagreements} element={<LegalAgreements />} />

                        <Route path={routers.taxrequirements} element={<TaxRequirements />} />
                        <Route path={routers.csf} element={<CSFTabs />} />

                        <Route path="/ordersuccess" element={<OrderSuccess />} />
                        <Route path="/orderincomplete/:orderId" element={<OrderIncomplete />} />
                        <Route path="/binnacles" element={<Binnacles />} />
                        <Route path="/binnacles/user/:userId" element={<BinnaclesDetails />} />
                        <Route path="/configuration" element={<Configuration />} />
                        <Route path={routers.modelorama} element={<Modelorama />} />
                        <Route path={routers.candidates} element={<Candidates />} />
                        <Route path="/mdweb" element={<Mdweb />} />
                        <Route
                            path={'/modelorama/positions/:bussinesId'}
                            element={<ModeloramaPositions />}
                        />

                        <Route
                            path="/confirmpoaction/:orderId/:group"
                            element={<OrderConfirmAction />}
                        />

                        {routesList.map(({path, component: Component}) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <AclGuard aclAbilities={Component.acl}>
                                        <Component />
                                    </AclGuard>
                                }
                            />
                        ))}
                        <Route
                            path="/purchase-order/:orderId/confirm/:group"
                            element={<PurchaseOrderConfirmTotal />}
                        />
                        <Route
                            path="/purchase-order/:orderId/partial/:group"
                            element={<PurchaseOrderConfirmPartial />}
                        />
                        <Route
                            path="/purchase-order/:internalId/confirm/success"
                            element={<FullConfirmationPOSuccess />}
                        />
                        <Route
                            path="/purchase-order/:internalId/reject/:group"
                            element={<FullRejectPO source="internal" />}
                        />
                    </Routes>
                </Box>
            </Box>
            <ModalAlertOrder isModalOpen={isModalOpen} closeModal={setIsModalOpen} />
        </Box>
    );
};
