import {routers} from '@/appRoute';
import BillingStatement from '@/components/billingStatement';
import Home from '@/components/home';
import LegalAgreementsAdmin from '@/components/legalAgreements/indexAdmin';
import Manuals from '@/components/manuals';
import Modelorama from '@/components/modelorama';
import ModeloramaPositions from '@/components/modelorama/ModeloramaPositions';
import Order from '@/components/order';
import {default as ExternalInvoiceConfirm} from '@/components/orderConfirm';
import OrderRecept from '@/components/orderRecept';
import OrdersSimply from '@/components/orderSimply';
import DetailExpendAccount from '@/components/orderSimply/DetailExpendAccount';
import OrderAgents from '@/components/orderSimply/OrderAgents';
import OrderConfirm from '@/components/orderSimply/OrderConfirm';
import OrderConfirmSimply from '@/components/orderSimply/OrderConfirmSimply';
import Tickets from '@/components/tickets';
import {AllPermissions} from '@/enums/permissions.enum';

interface RouteWithAclProps {
    path: string;
    exact?: boolean;
    component: React.FC & {acl?: {action: AllPermissions; subject: string}};
}

// Crear un array de rutas con ACL
export const routesList: RouteWithAclProps[] = [
    {
        path: routers.home,
        exact: true,
        component: Home,
    },
    {
        path: '/billingStatement',
        exact: true,
        component: BillingStatement,
    },
    {
        path: '/confirmpo',
        exact: true,
        component: OrderConfirm,
    },
    {
        path: '/confirmposimply',
        exact: true,
        component: OrderConfirmSimply,
    },
    {
        path: '/consultpo',
        exact: true,
        component: OrdersSimply,
    },
    {
        path: '/receptpo',
        exact: true,
        component: OrderRecept,
    },
    {
        path: '/order/:orderId',
        exact: true,
        component: Order,
    },
    {
        path: '/poagents',
        exact: true,
        component: OrderAgents,
    },
    {
        path: routers.manuals,
        exact: true,
        component: Manuals,
    },
    {
        path: routers.legalagreementsAdmin,
        exact: true,
        component: LegalAgreementsAdmin,
    },
    {
        path: '/support',
        exact: true,
        component: Tickets,
    },
    {
        path: routers.modelorama,
        exact: true,
        component: Modelorama,
    },
    {
        path: '/modelorama/positions/:bussinesId',
        exact: true,
        component: ModeloramaPositions,
    },
    {
        path: '/poagents/:customsAgentCode/custom-request/:customRequest',
        exact: true,
        component: DetailExpendAccount,
    },
    {
        path: '/external-invoice-confirm',
        exact: true,
        component: ExternalInvoiceConfirm,
    },
];
