import {Navigate, useLocation} from 'react-router-dom';
import React from 'react';
import {useSelector} from 'react-redux';

export const PrivateRoute = ({children}) => {
    const tokenflag = useSelector((state) => state.logins.tokenflag);
    const location = useLocation();
    const urllogin = '/auth/login';
    const isSAMLUrl = location.pathname === '/v2/auth/sso/saml/login';

    return isSAMLUrl ? (
        children
    ) : tokenflag ? (
        children
    ) : (
        <Navigate to={urllogin} state={{from: location}} />
    );
};
