import {
    DELETE_USER,
    DELETE_USER_ERROR,
    DELETE_USER_SUCCESS,
    GET_USER,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    POST_USER,
    POST_USER_ERROR,
    POST_USER_SUCCESS,
    SET_FILTERS_SUPPLIER,
    SET_FILTERS_USER,
    UPDATE_USER,
    UPDATE_USER_ERROR,
    UPDATE_USER_STATUS,
    UPDATE_USER_STATUS_ERROR,
    UPDATE_USER_STATUS_SUCCESS,
    UPDATE_USER_SUCCESS,
} from '@/components/user/redux/types/UserTypes';
import axios from 'axios';

import {IFilter, IUser, UserType} from '@/components/user/interface';
import StringUtils from '@/utils/StringUtils';

export const getUsers = () => {
    return {
        type: GET_USERS,
    };
};
export const getUsersSuccess = (result: any) => {
    return {
        type: GET_USERS_SUCCESS,
        value: result,
    };
};
export const getUsersError = (error: boolean, result?: any) => {
    return {
        type: GET_USERS_ERROR,
        value: error,
        payload: result,
    };
};

export const postFilters = (filters: IFilter) => {
    const type = filters.type === UserType.INTERNAL_USER ? SET_FILTERS_USER : SET_FILTERS_SUPPLIER;
    return {
        type,
        value: filters,
    };
};

export function fetchGetUsers(
    token: string,
    userType: UserType,
    page: number,
    limit: number,
    filters = {}
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {
        ...filters,
        type: userType,
        page,
        limit,
        sortOrder: 'DESC',
    };

    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: any) => {
        dispatch(getUsers());
        dispatch(postFilters(params));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/users${queryString}`,
                {headers}
            );
            dispatch(getUsersSuccess(response.data));
        } catch (error) {
            dispatch(getUsersError(true, error));
        }
    };
}

export const postUser = () => {
    return {
        type: POST_USER,
    };
};
export const postUserSuccess = (result: boolean) => {
    return {
        type: POST_USER_SUCCESS,
        value: result,
    };
};
export const postUserError = (error: boolean, result?: any) => {
    return {
        type: POST_USER_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostUser(token: string, data: IUser) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(postUser());
        const userData = {
            email: data.email,
            role: data.type,
            firstLastName: data.firstLastName,
            secondLastName: data.secondLastName,
            type: data.type,
            roles: data.roles,
            externalId: data.externalId,
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/users/signup`,
                userData,
                {
                    headers,
                }
            );
            dispatch(postUserSuccess(response.data.data));
        } catch (error) {
            dispatch(postUserError(true));
        }
    };
}

export const putUser = () => {
    return {
        type: UPDATE_USER,
    };
};
export const putUserSuccess = (result: boolean) => {
    return {
        type: UPDATE_USER_SUCCESS,
        value: result,
    };
};
export const putUserError = (error: boolean, result?: any) => {
    return {
        type: UPDATE_USER_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutUser(token: string, user: IUser) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(putUser());
        const {id, ...data} = user;
        const userData = {
            email: data.email,
            firstLastName: data.firstLastName,
            secondLastName: data.secondLastName,
            roles: data.roles,
            externalId: data.externalId,
        };
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/users/${id}`,
                userData,
                {headers}
            );
            dispatch(putUserSuccess(response.data.data));
        } catch (error) {
            dispatch(putUserError(true));
        }
    };
}

export const putUserStatus = () => {
    return {
        type: UPDATE_USER_STATUS,
    };
};
export const putUserStatusSuccess = (result: boolean) => {
    return {
        type: UPDATE_USER_STATUS_SUCCESS,
        value: result,
    };
};
export const putUserStatusError = (error: boolean, result?: any) => {
    return {
        type: UPDATE_USER_STATUS_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPutStatusUser(token: string, user: IUser) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(putUserStatus());
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/v2/users/${user.id}/status`,
                {status: user.status},
                {headers}
            );
            dispatch(putUserStatusSuccess(response.data.data));
        } catch (error) {
            dispatch(putUserStatusError(true));
        }
    };
}

export const deleteUser = () => {
    return {
        type: DELETE_USER,
    };
};
export const deleteUserSuccess = (result: boolean) => {
    return {
        type: DELETE_USER_SUCCESS,
        value: result,
    };
};
export const deleteUserError = (error: boolean, result?: any) => {
    return {
        type: DELETE_USER_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchDeleteUser(token: string, rolId: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(deleteUser());
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/v2/users/${rolId}`,
                {headers}
            );
            dispatch(deleteUserSuccess(response.data.data));
        } catch (error) {
            dispatch(deleteUserError(true));
        }
    };
}

export const getUser = () => {
    return {
        type: GET_USER,
    };
};
export const getUserSuccess = (result: any) => {
    return {
        type: GET_USER_SUCCESS,
        value: result,
    };
};
export const getUserError = (error: boolean, result?: any) => {
    return {
        type: GET_USER_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetUser(token: string, userId: string) {
    const headers = {
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: any) => {
        dispatch(getUser());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/users/${userId}`,
                {headers}
            );
            dispatch(getUserSuccess(response.data));
        } catch (error) {
            dispatch(getUserError(true));
        }
    };
}
